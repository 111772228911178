import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Stack,
  Typography,
} from "@mui/material"
import { Fragment, useEffect } from "react"
import PropSpec from "./PropSpec"
import { useState } from "react"
import ArchiMateElementSelect from "./controls/ArchiMateElementSelect"
import { useMemo } from "react"
import { useSnackbar } from "notistack"
import {
  GPT_4o_LATEST,
  GPT_4o_MINI_LATEST,
} from "../pages/services/chatGenerationServices"
import { getSuggestedTags } from "../pages/services/chatGenerationServices"
import * as Roles from "../pages/services/roleServices"

const ModelEditAddPropertiesDialog = ({
  open,
  onClose,
  accountId,
  handleAddProps,
  handleUpdateSuggestedProps,
  assistants,
  scope,
  overview,
  purpose,
  view,
  roles,
}) => {
  const [elementType, setElementType] = useState("")

  const [selectedGPTModel, setSelectedGPTModel] = useState(GPT_4o_LATEST)

  const [elementProps, setElementProps] = useState([
    { name: "", description: "", auto: false, seq: 1 },
  ])

  const [override, setOverride] = useState(false)

  const { enqueueSnackbar } = useSnackbar()

  // Get unique 'type' value from view.elements
  const uniqueElementTypes = useMemo(() => {
    if (view) {
      const uniqueTypes = [
        ...new Set(view?.elements.map((element) => element.type)),
      ]
      //console.log("uniqueElementTypes", { uniqueTypes })
      return uniqueTypes
    }
  }, [view])

  const handleGetSuggestedProps = async () => {
    const suggestedTags = await getSuggestedTags({
      assistants,
      scope,
      overview,
      view,
      purpose,
      roles,
    })

    //console.log("received suggested tags", { view, suggestedTags })

    handleUpdateSuggestedProps(suggestedTags)

    return suggestedTags
  }

  useEffect(() => {
    if (!roles.includes(Roles.AIM_AI)) {
      setSelectedGPTModel(GPT_4o_MINI_LATEST)
    }
  }, [roles])

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Add Properties</DialogTitle>
      <DialogContent sx={{ maxWidth: "400px" }}>
        <DialogContentText>
          Generate property values against the diagram
        </DialogContentText>

        <Stack gap={2} sx={{ marginTop: "10px", marginBottom: "10px" }}>
          <ArchiMateElementSelect
            type={elementType}
            setType={(newType) => {
              if (newType !== "-1") setElementType(newType)
            }}
            label={"Element type to tag"}
            filterTypes={uniqueElementTypes}
          />

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              flexWrap: "wrap",
              gap: 2,
            }}
          >
            {elementProps.map((prop, index) => (
              <Fragment key={prop.seq}>
                {index > 0 && (
                  <Box sx={{ marginTop: "20px" }}>
                    <Divider />
                  </Box>
                )}
                <Typography variant="caption" sx={{ fontWeight: "bold" }}>
                  Property {elementProps.length === 1 ? "" : index + 1}
                </Typography>
                <PropSpec
                  accountId={accountId}
                  prop={prop}
                  setProp={(newProp) => {
                    const newProps = [...elementProps]
                    newProps[index] = newProp
                    setElementProps(newProps)
                  }}
                  handleDeleteProp={(seq) => {
                    const newProps = elementProps.filter(
                      (prop) => prop.seq !== seq
                    )
                    setElementProps(newProps)
                  }}
                  handleGetSuggestedTags={handleGetSuggestedProps}
                  setElementType={(elementType) => setElementType(elementType)}
                  suggestedTags={view.suggested_props || []}
                />
              </Fragment>
            ))}
          </Box>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          sx={{ textTransform: "none" }}
          onClick={onClose}
          size="small"
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          size="small"
          sx={{ textTransform: "none" }}
          onClick={() => {
            if (elementType !== "") {
              handleAddProps({
                elementProps,
                elementType,
                override,
                gptModel: selectedGPTModel,
              })
            } else {
              enqueueSnackbar("Please select an element type", {
                variant: "warning",
              })
            }
          }}
        >
          Generate
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ModelEditAddPropertiesDialog
