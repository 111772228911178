import * as actions from "./actionTypes"

export const setProjectGridPagination = (pagination) => ({
  type: actions.SET_PROJECT_GRID_PAGINATION,
  payload: {
    value: pagination,
  },
})

export const setProjectGridPageDocs = (pageDocs) => ({
  type: actions.SET_PROJECT_GRID_PAGE_DOCS,
  payload: {
    value: pageDocs,
  },
})

export const setProjectGridSelectedItems = (projectGridSelectedItems) => ({
  type: actions.SET_PROJECT_GRID_SELECTED_ITEMS,
  payload: {
    value: projectGridSelectedItems,
  },
})

export const setViewSetGridPagination = (pagination) => ({
  type: actions.SET_VIEW_SET_GRID_PAGINATION,
  payload: {
    value: pagination,
  },
})

export const setViewSetGridPageDocs = (pageDocs) => ({
  type: actions.SET_VIEW_SET_GRID_PAGE_DOCS,
  payload: {
    value: pageDocs,
  },
})

export const setModelEditDashboardTab = (tabIndex) => ({
  type: actions.SET_MODEL_EDIT_DASHBOARD_TAB,
  payload: {
    value: tabIndex,
  },
})

export const setMenuStatus = (status) => ({
  type: actions.SET_MENU_OPEN,
  payload: {
    value: status,
  },
})

export const setConfigMenuOpen = (status) => ({
  type: actions.SET_CONFIG_MENU_OPEN,
  payload: {
    value: status,
  },
})

export const setPromptGridPagination = (pagination) => ({
  type: actions.SET_PROMPT_GRID_PAGINATION,
  payload: {
    value: pagination,
  },
})

export const setPromptGridPageDocs = (pageDocs) => ({
  type: actions.SET_PROMPT_GRID_PAGE_DOCS,
  payload: {
    value: pageDocs,
  },
})

export const setStoryPromptGridPagination = (pagination) => ({
  type: actions.SET_STORY_PROMPT_GRID_PAGINATION,
  payload: {
    value: pagination,
  },
})

export const setStoryPromptGridPageDocs = (pageDocs) => ({
  type: actions.SET_STORY_PROMPT_GRID_PAGE_DOCS,
  payload: {
    value: pageDocs,
  },
})

export const setStoryGridPagination = (pagination) => ({
  type: actions.SET_STORY_GRID_PAGINATION,
  payload: {
    value: pagination,
  },
})

export const setStoryGridPageDocs = (pageDocs) => ({
  type: actions.SET_STORY_GRID_PAGE_DOCS,
  payload: {
    value: pageDocs,
  },
})

export const setElementGridPagination = (pagination) => ({
  type: actions.SET_ELEMENT_GRID_PAGINATION,
  payload: {
    value: pagination,
  },
})
export const setElementGridPageDocs = (pageDocs) => ({
  type: actions.SET_ELEMENT_GRID_PAGE_DOCS,
  payload: {
    value: pageDocs,
  },
})

export const setElementDefinitions = (elementDefinitions) => {
  return {
    type: actions.SET_ELEMENT_DEFINITIONS,
    payload: {
      value: elementDefinitions,
    },
  }
}

export const setSelectedEnterpriseView = (viewId) => {
  return {
    type: actions.SET_SELECTED_ENTERPRISE_VIEW,
    payload: {
      value: viewId,
    },
  }
}

export const setStoryGridSelectedItems = (storyGridSelectedItems) => ({
  type: actions.SET_STORY_GRID_SELECTED_ITEMS,
  payload: {
    value: storyGridSelectedItems,
  },
})

export const setComponentGridPagination = (pagination) => ({
  type: actions.SET_COMPONENT_GRID_PAGINATION,
  payload: {
    value: pagination,
  },
})

export const setComponentGridPageDocs = (pageDocs) => ({
  type: actions.SET_COMPONENT_GRID_PAGE_DOCS,
  payload: {
    value: pageDocs,
  },
})

export const setComponentGridSelectedItems = (componentGridSelectedItems) => ({
  type: actions.SET_COMPONENT_GRID_SELECTED_ITEMS,
  payload: {
    value: componentGridSelectedItems,
  },
})

export const setShowDiagrams = (showDiagrams) => ({
  type: actions.SET_SHOW_DIAGRAMS,
  payload: {
    value: showDiagrams,
  },
})

export const setShowDashboardPrompts = (showDashboardPrompts) => ({
  type: actions.SET_SHOW_DASHBOARD_PROMPTS,
  payload: {
    value: showDashboardPrompts,
  },
})

export const setDashboardViewToggles = (dashboardViewToggles) => ({
  type: actions.SET_DASHBOARD_VIEW_TOGGLES,
  payload: {
    value: dashboardViewToggles,
  },
})

export const setShowSuggestedViews = (showSuggestedViews) => ({
  type: actions.SET_SHOW_SUGGESTED_VIEWS,
  payload: {
    value: showSuggestedViews,
  },
})

export const setEditMode = (editMode) => ({
  type: actions.SET_EDIT_MODE,
  payload: {
    value: editMode,
  },
})

export const setShowRuleDetail = (showRuleDetail) => ({
  type: actions.SET_SHOW_RULE_DETAIL,
  payload: {
    value: showRuleDetail,
  },
})

export const setDiagramScale = (diagramScale) => ({
  type: actions.SET_DIAGRAM_SCALE,
  payload: {
    value: diagramScale,
  },
})

export const setSelectedRules = (selectedRules) => ({
  type: actions.SET_SELECTED_RULES,
  payload: {
    value: selectedRules,
  },
})

export const setShowDocumentationIndicator = (showDocumentationIndicator) => ({
  type: actions.SET_SHOW_DOCUMENTATION_INDICATOR,
  payload: {
    value: showDocumentationIndicator,
  },
})

export const setShowTooltipsIndicator = (showTooltipsIndicator) => ({
  type: actions.SET_SHOW_TOOLTIPS_INDICATOR,
  payload: {
    value: showTooltipsIndicator,
  },
})

export const setAutoLegend = (autoLegend) => ({
  type: actions.SET_AUTO_LEGEND,
  payload: {
    value: autoLegend,
  },
})

export const setMlModels = (mlModels) => ({
  type: actions.SET_ML_MODELS,
  payload: {
    value: mlModels,
  },
})

export const setAutofitDiagram = (autofitDiagram) => ({
  type: actions.SET_AUTOFIT_DIAGRAM,
  payload: {
    value: autofitDiagram,
  },
})

export const setAccountGridPagination = (pagination) => ({
  type: actions.SET_ACCOUNT_GRID_PAGINATION,
  payload: {
    value: pagination,
  },
})

export const setAccountGridPageDocs = (pageDocs) => ({
  type: actions.SET_ACCOUNT_GRID_PAGE_DOCS,
  payload: {
    value: pageDocs,
  },
})

export const setAccountGridSelectedItems = (accountGridSelectedItems) => ({
  type: actions.SET_ACCOUNT_GRID_SELECTED_ITEMS,
  payload: {
    value: accountGridSelectedItems,
  },
})

export const setShowRules = (showRules) => ({
  type: actions.SET_SHOW_RULES,
  payload: {
    value: showRules,
  },
})

export const setShowMoreDashboard = (showMoreDashboard) => ({
  type: actions.SET_SHOW_MORE_DASHBOARD,
  payload: {
    value: showMoreDashboard,
  },
})

export const setModelState = (modelInfo) => ({
  type: actions.SET_MODEL,
  payload: { value: modelInfo },
})

export const removeModel = (modelId) => ({
  type: actions.REMOVE_MODEL,
  payload: { value: { modelId } },
})

// Set data from retrieving projects data from BigQuery
export const setChartProjectsByTag = (chartProjectsByTag) => ({
  type: actions.SET_CHART_PROJECTS_BY_TAG,
  payload: {
    value: chartProjectsByTag,
  },
})

export const updateModelMessages = (modelId, messages) => ({
  type: actions.UPDATE_MODEL_MESSAGES,
  payload: { value: { modelId, messages } },
})

// contains the parent (project id or component id) that and the selected file name for that parent
export const setSelectedModelFileName = (fileNameInfo) => {
  return {
    type: actions.SET_SELECTED_MODEL_FILE_NAME,
    payload: { value: fileNameInfo },
  }
}

export const setSelectedTabIndex = (tabIndex) => {
  //console.log("%csetter", "color:lightgreen", tabIndex)
  return {
    type: actions.SET_SELECTED_TAB_INDEX,
    payload: { value: tabIndex },
  }
}

export const setProjects = (projectCacheUpdate) => {
  // console.log("%c[redux] setting projects cache", "color:lightgreen", {
  //     projectCacheUpdate,
  // })
  return {
    type: actions.SET_PROJECTS,
    payload: {
      value: projectCacheUpdate,
    },
  }
}

export const setComponents = (componentCacheUpdate) => {
  //console.log("%c[redux] setting components cache", "color:lightgreen", {
  //    componentCacheUpdate,
  //})
  return {
    type: actions.SET_COMPONENTS,
    payload: {
      value: componentCacheUpdate,
    },
  }
}

export const setProjectModelIndex = (modelIndexCacheUpdate) => {
  //console.log("%c[redux] setting model index cache", "color:lightgreen", {
  //    modelIndexCacheUpdate,
  //})
  return {
    type: actions.SET_PROJECT_MODEL_INDEX,
    payload: {
      value: modelIndexCacheUpdate,
    },
  }
}

export const setComponentModelIndex = (modelIndexCacheUpdate) => {
  //console.log("%c[redux] setting model index cache", "color:lightgreen", {
  //    modelIndexCacheUpdate,
  //})
  return {
    type: actions.SET_COMPONENT_MODEL_INDEX,
    payload: {
      value: modelIndexCacheUpdate,
    },
  }
}

export const setProjectExplorerSettings = (settings) => ({
  type: actions.SET_PROJECT_EXPLORER_SETTINGS,
  payload: {
    value: settings,
  },
})

export const setComponentExplorerSettings = (settings) => ({
  type: actions.SET_COMPONENT_EXPLORER_SETTINGS,
  payload: {
    value: settings,
  },
})
