import { useEffect, useState } from "react"
import firebase from "firebase/compat/app"

const useAccountStatus = (props) => {
    const [accountStatus, setAccountStatus] = useState("inactive")

    const [plan, setPlan] = useState()

    const [billingSetup, setBillingSetup] = useState()

    const PLAN_BASIC = "basic"
    const PLAN_BUSINESS = "business"

    useEffect(() => {
        const unsub = firebase.auth().onAuthStateChanged((user) => {
            if (user !== null) {
                user.getIdTokenResult(true).then((token) => {
                    console.log("%cupdate account status", "color:yellow", { claims: token.claims })
                    setAccountStatus(token.claims.account_status)
                    setPlan(token.claims.plan)
                    setBillingSetup(token.claims.billing_setup)
                })
            }
        })

        return unsub
    }, [])

    const isActive = () => {
        switch (plan) {
            case PLAN_BASIC:
                return true

            case PLAN_BUSINESS:
                return accountStatus === "active"

            default:
                return false
        }
    }

    /**
     * @param fileCount {number} - number of files already uploaded to parent (project/component)
     * @returns
     */
    const isUploadModelActive = (fileCount) => {
        return isActive() ? true : fileCount === 0
    }

    const isNewProjectActive = (projectCount) => {
        return isActive() ? true : projectCount < 5
    }

    const isNewComponentActive = (componentCount) => {
        return isActive() ? true : componentCount < 5
    }

    return { isActive, isUploadModelActive, isNewProjectActive, isNewComponentActive }
}

export default useAccountStatus
