import {
  Box,
  CircularProgress,
  Divider,
  Button,
  FormControlLabel,
  IconButton,
  Paper,
  Stack,
  Switch,
  Tooltip,
  Typography,
  Alert,
} from "@mui/material"
import Controls from "./controls/Controls"
import DeleteIcon from "@mui/icons-material/Delete"
import ArchiMateElementSelect from "./controls/ArchiMateElementSelect"
import AssistantIcon from "@mui/icons-material/Assistant"
import { Fragment, useEffect } from "react"
import PropSpec from "./PropSpec"
import { findMaxSeq } from "../pages/services/modelEditServices"
import { useState } from "react"
import {
  MAX_DESCRIPTION_WORDS,
  MAX_ELEMENTS,
  FUNCTION_GET_AUTO_COUNT_OF_NEXT_LEVEL,
  getAIUsageParam,
  getModel,
} from "../pages/services/chatGenerationServices"
import {
  getAutoCountOfNextLevel,
  getPromptsForReferencedViews,
  createViewPrompt,
  loadDesignViewRefs,
} from "../pages/services/createContentServices"
import YoutubeSearchedForIcon from "@mui/icons-material/YoutubeSearchedFor"
import HelpLink from "./HelpLink"
import { useMemo } from "react"
import { aiIcon } from "../pages/services/colorServices"
import * as Roles from "../pages/services/roleServices"

const LevelSpec = (props) => {
  const {
    modelCache,
    levelSpec,
    setLevelSpec,
    mode = "basic",
    handleDeleteLevelSpec,
    handleEnsureModelIsLoaded,
    handleSuggestElementType,
    accountId,
    index,
    currentElement,
    viewSet,
    views,
    roles,
  } = props

  const [isGettingCount, setGettingCount] = useState(false)

  const [elementTypeSuggestionReason, setElementTypeSuggestionReason] =
    useState("")

  const [
    isGeneratingElementTypeSuggestion,
    setGeneratingElementTypeSuggestion,
  ] = useState(false)

  const autoCountProps = useMemo(() => {
    if (!levelSpec.type) {
      return {}
    }

    return aiIcon
  }, [levelSpec.type])

  // Max words for paid accounts
  const DEFAULT_MAX_WORDS = 50

  // Max elements is defined in the paid account policy, so the DEFAULT_MAX_ELEMENTS is used as a fallback, but never be used.
  const DEFAULT_MAX_ELEMENTS = 30

  const maxWords = useMemo(() => {
    return (
      getAIUsageParam({ roles, paramName: MAX_DESCRIPTION_WORDS }) ||
      DEFAULT_MAX_WORDS
    )
  }, [roles])

  const maxElements = useMemo(() => {
    return (
      getAIUsageParam({ roles, paramName: MAX_ELEMENTS }) ||
      DEFAULT_MAX_ELEMENTS
    )
  }, [roles])

  const isPaid = roles.includes(Roles.AIM_AI)

  // Limit max_words to maxWords, where maxWords is lower if not a paid account
  useEffect(() => {
    const newLevelSpec = { ...levelSpec }
    let changed = false
    if (levelSpec.max_words > maxWords) {
      newLevelSpec.max_words = maxWords
    }
    if (levelSpec.qty > maxElements) {
      newLevelSpec.qty = maxElements
      changed = true
    }
    if (changed) {
      setLevelSpec(newLevelSpec)
    }
  }, [maxWords, levelSpec])

  const promptHeading = useMemo(() => {
    if (mode === "basic" && index === 0) {
      return "Prompt"
    }
    return `Prompt - Level ${index + 1}`
  }, [mode, index])

  return (
    <Paper
      sx={{ marginTop: "10px", padding: "5px", width: "400px" }}
      elevation={2}
    >
      <Box sx={{ display: "flex", flexDirection: "row" }}>
        <Typography variant="body1" sx={{ fontWeight: "bold" }}>
          {promptHeading}
        </Typography>

        <Box sx={{ display: "flex", marginLeft: "auto" }}>
          <IconButton onClick={() => handleDeleteLevelSpec(levelSpec.seq)}>
            <DeleteIcon />
          </IconButton>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 1,
          padding: "10px",
        }}
      >
        <Controls.TextInput
          label="Prompt"
          value={levelSpec.info}
          multiline={true}
          onChange={(e) => {
            setLevelSpec({ ...levelSpec, info: e.target.value })
            handleEnsureModelIsLoaded &&
              handleEnsureModelIsLoaded(e.target.value)
          }}
        />

        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            marginTop: "15px",
            marginBottom: "10px",
          }}
        >
          <ArchiMateElementSelect
            type={levelSpec.type}
            setType={(newType) => setLevelSpec({ ...levelSpec, type: newType })}
          />
          <Box sx={{ marginLeft: "auto", marginTop: "10px" }}>
            <HelpLink
              tooltip="View ArchiMate Element Reference"
              pageName="_archimate_elements"
              url="https://architectureinmotion.com.au/archimate-elements/"
            />
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: 1,
          }}
        >
          <Tooltip
            title={`Suggest an element type based on your prompt${
              !isPaid ? " - paid feature" : ""
            }`}
          >
            <span>
              <Button
                disabled={
                  levelSpec.info.trim() === "" ||
                  isGeneratingElementTypeSuggestion
                }
                onClick={() => {
                  setGeneratingElementTypeSuggestion(true)
                  handleSuggestElementType(levelSpec).then((result) => {
                    //console.log("result", result)
                    setLevelSpec({ ...levelSpec, type: result.id })
                    setElementTypeSuggestionReason(result.reason)
                    setGeneratingElementTypeSuggestion(false)
                  })
                }}
                variant="outlined"
                sx={{ textTransform: "none" }}
                endIcon={
                  <AssistantIcon
                    sx={levelSpec.info.trim() === "" ? {} : aiIcon}
                  />
                }
              >
                Suggest Element Type
              </Button>
            </span>
          </Tooltip>
          {isGeneratingElementTypeSuggestion && (
            <CircularProgress size={20} sx={aiIcon} />
          )}
        </Box>
        {elementTypeSuggestionReason && (
          <Box sx={{ marginTop: "10px" }}>
            <Typography variant="caption" color="text.secondary">
              {elementTypeSuggestionReason}
            </Typography>
          </Box>
        )}

        <Stack direction="row" sx={{ alignItems: "center" }} gap={1}>
          <GenerateDocumentationSwitch
            levelSpec={levelSpec}
            setLevelSpec={setLevelSpec}
          />

          <Box sx={{ maxWidth: "90px", mb: "10px", ml: "auto" }}>
            <Controls.TextInput
              label="Max Words"
              disabled={!levelSpec.attrs.includes("description")}
              value={levelSpec.max_words}
              onChange={(e) => {
                // Check value is a number

                if (e.target.value === "") {
                  setLevelSpec({ ...levelSpec, max_words: "" })
                  return
                }
                let value = Math.min(parseInt(e.target.value), maxWords)
                if (isNaN(value)) {
                  value = 0
                }

                setLevelSpec({ ...levelSpec, max_words: value })
              }}
              autoComplete="off"
            />
          </Box>
        </Stack>

        <FormControlLabel
          control={
            <Tooltip
              title={`Automatically determine the best number of elements to generate`}
            >
              <span>
                <Switch
                  checked={levelSpec.auto_qty}
                  onChange={(e) => {
                    const newLevelSpec = {
                      ...levelSpec,
                      auto_qty: e.target.checked,
                    }
                    setLevelSpec(newLevelSpec)
                  }}
                />
              </span>
            </Tooltip>
          }
          label={
            <Typography variant="caption">
              Auto-determine element count
            </Typography>
          }
          labelPlacement="end"
        />
        {levelSpec.auto_qty && (
          <Stack direction={"column"} gap={1}>
            <Box sx={{ width: "120px" }}>
              <Controls.TextInput
                label="Auto up to"
                value={levelSpec.qty}
                onChange={(e) => {
                  // Check value is a number
                  if (isNaN(e.target.value)) {
                    return
                  }

                  setLevelSpec({ ...levelSpec, qty: e.target.value })
                }}
                autoComplete="off"
              />
            </Box>
          </Stack>
        )}
        {!levelSpec.auto_qty && (
          <Stack direction="row" spacing={1} sx={{ alignItems: "center" }}>
            <Box sx={{ maxWidth: "120px" }}>
              <Controls.TextInput
                label="Fixed count"
                value={levelSpec.qty}
                onChange={(e) => {
                  // Check value is a number
                  if (isNaN(e.target.value)) {
                    return
                  }

                  const value = Math.min(
                    parseInt(e.target.value),
                    DEFAULT_MAX_ELEMENTS
                  )

                  if (isNaN(value)) {
                    setLevelSpec({ ...levelSpec, qty: "" })
                  } else {
                    setLevelSpec({ ...levelSpec, qty: value })
                  }
                }}
                autoComplete="off"
              />
            </Box>

            <Box>
              <Tooltip
                title={`Suggest how many elements${
                  levelSpec.type ? "" : " - Choose element type"
                }`}
              >
                <span>
                  {!isGettingCount && (
                    <IconButton
                      onClick={async () => {
                        setGettingCount(true)

                        const { viewPrompts, missingViews } =
                          await getPromptsForReferencedViews({
                            accountId,
                            views,
                            modelCache,
                            prompt: levelSpec.info,
                          })

                        const loadedViewRefs = await loadDesignViewRefs({
                          viewRefs: missingViews,
                          accountId,
                        })

                        const designViewPrompts = loadedViewRefs.map((v) => ({
                          src: v.src,
                          prompt: createViewPrompt({
                            view: v.view,
                          }),
                        }))

                        const allViewPrompts = [
                          ...viewPrompts,
                          ...designViewPrompts,
                        ]

                        getAutoCountOfNextLevel({
                          referencedViewPrompts: allViewPrompts,
                          accountId,
                          modelCache,
                          viewSet,
                          views,
                          levelSpec,
                          parentElement: currentElement,
                          gptModel: getModel({
                            roles,
                            funcName: FUNCTION_GET_AUTO_COUNT_OF_NEXT_LEVEL,
                          }),
                        }).then((result) => {
                          setLevelSpec({
                            ...levelSpec,
                            qty: result.count,
                          })
                          setGettingCount(false)
                        })
                      }}
                      disabled={!levelSpec.type}
                    >
                      <YoutubeSearchedForIcon sx={autoCountProps} />
                    </IconButton>
                  )}
                  {isGettingCount && <CircularProgress size={20} sx={aiIcon} />}
                </span>
              </Tooltip>
            </Box>
          </Stack>
        )}

        {mode === "extended" && (
          <>
            <Divider />

            <Box sx={{ marginTop: "10px" }}>
              <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                Properties
              </Typography>
              <Typography variant="caption" color="text.secondary">
                Properties added to each element
              </Typography>
            </Box>
            <RightJustifyBox>
              <Controls.Button
                onClick={() => {
                  const newLevelSpec = {
                    ...levelSpec,
                    props: [
                      ...levelSpec.props,
                      {
                        name: "",
                        description: "",
                        auto: false,
                        seq: findMaxSeq(levelSpec.props) + 1,
                      },
                    ],
                  }
                  setLevelSpec(newLevelSpec)
                }}
                label="Add Property"
              />
            </RightJustifyBox>
            {levelSpec.props.length > 0 && <Divider />}
            {levelSpec.props.map((prop, index) => (
              <Fragment key={prop.seq}>
                {index > 0 && <Divider />}
                <Typography variant="caption" sx={{ fontWeight: "bold" }}>
                  Property {index + 1}
                </Typography>
                <PropSpec
                  accountId={accountId}
                  prop={prop}
                  setProp={(newProp) => {
                    const newProps = [...levelSpec.props]
                    newProps[index] = newProp
                    setLevelSpec({ ...levelSpec, props: newProps })
                  }}
                  handleDeleteProp={(seq) => {
                    const newLevelSpec = {
                      ...levelSpec,
                      props: levelSpec.props.filter((prop) => prop.seq !== seq),
                    }
                    setLevelSpec(newLevelSpec)
                  }}
                />
              </Fragment>
            ))}
          </>
        )}
        {!isPaid && (
          <Alert severity="info">
            Demo accounts max up to {maxElements} elements, and {maxWords} word
            descriptions
          </Alert>
        )}
      </Box>
    </Paper>
  )
}

const GenerateDocumentationSwitch = ({ levelSpec, setLevelSpec }) => {
  return (
    <FormControlLabel
      control={
        <Switch
          checked={levelSpec.attrs.includes("description")}
          onChange={(e) => {
            // Toggle 'description' entry being part of attrs array
            if (e.target.checked) {
              setLevelSpec({
                ...levelSpec,
                attrs: ["name", "description"],
              })
            } else {
              setLevelSpec({
                ...levelSpec,
                attrs: ["name"],
              })
            }
          }}
        />
      }
      label={<Typography variant="caption">Element Descriptions</Typography>}
      labelPlacement="end"
    />
  )
}

const RightJustifyBox = (props) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        marginLeft: "auto",
        justifyContent: "flex-end",
      }}
    >
      {props.children}
    </Box>
  )
}

export default LevelSpec
