import React, { useState, useEffect, createRef, useMemo } from "react"
import { useSnackbar } from "notistack"
import {
  selectAutofitDiagram,
  selectDiagramScale,
  selectModelState,
  selectEditMode,
} from "../redux/selectors"
import { setModelState, setEditMode } from "../redux/actions"
import { useDispatch, useSelector } from "react-redux"
import Controls from "./controls/Controls"
import firebase from "firebase/compat/app"
import db from "../Firestore"
import * as dataServices from "../pages/services/dataServices"
import * as modelServices from "../pages/services/modelServices"
import * as chatPromptServices from "../pages/services/chatPromptServices"
import NextIcon from "@mui/icons-material/NavigateNext"
import PrevIcon from "@mui/icons-material/NavigateBefore"
import * as Roles from "../pages/services/roleServices"
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome"
import EditIcon from "@mui/icons-material/Edit"
import SlideshowIcon from "@mui/icons-material/Slideshow"
import PanoramaIcon from "@mui/icons-material/Panorama"
import SaveIcon from "@mui/icons-material/Save"
import AddIcon from "@mui/icons-material/Add"
import DeleteIcon from "@mui/icons-material/Delete"
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward"
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward"
import { useWindowDimensions } from "../pages/services/useWindowDimensions"
import * as chatGenerationServices from "../pages/services/chatGenerationServices"
import * as diagramPromptServices from "../pages/services/diagramPromptServices"
import { aiIcon } from "../pages/services/colorServices"
import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  CardHeader,
  IconButton,
  Paper,
  Tooltip,
  Switch,
  Typography,
  Alert,
  Divider,
  ToggleButtonGroup,
  ToggleButton,
  Badge,
  Stack,
  Select,
  MenuItem,
  FormControl,
} from "@mui/material"
import ToggleEditing from "./ToggleEditing"
import Diagram from "./Diagram"
import _ from "lodash"
import { classProps } from "./useDiagramTooltip"
import { useForm } from "./useForm"
import * as colors from "@mui/material/colors"
import YesNo from "./YesNo"
import ModelBreadcrumbs from "./ModelBreadcrumbs"
import { Skeleton } from "@mui/material"
import DiagramResize from "./DiagramResize"
import TooltipHover from "./TooltipHover"
import TagPanel from "./TagPanel"
import * as tagServices from "../pages/services/tagServices"
import * as colorServices from "../pages/services/colorServices"
import TagSummary from "./TagSummary"
import { spacing } from "../pages/services/styleServices"
import { getShaderOptions } from "../pages/services/modelEditServices"
import CreatePromptDialog from "./CreatePromptDialog"
import GetAIMAIDialog from "./GetAIMAIDialog"
import AIProgress from "./AIProgress"
import DiagramPromptDialog from "./DiagramPromptDialog"

const styles = {
  buttons: {
    marginTop: spacing(3),
  },
  menu: {
    marginTop: spacing(0),
    marginLeft: spacing(8),
  },
  diagramAndStory: {
    display: "flex",
    flexDirection: "row",
  },
  diagram: {
    marginRight: spacing(2),
  },
  items: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    gap: spacing(2),
  },
  tooltip: classProps(),
  editFields: {
    maxWidth: 400,
    display: "flex",
    flexDirection: "column",
    gap: spacing(3),
    marginBottom: spacing(2),
    paddingTop: spacing(2),
  },
  selected: {
    backgroundColor: colors.blue[200],
  },
  notSelected: {
    backgroundColor: colors.grey[200],
  },
  fields: {
    display: "flex",
    flexDirection: "column",
  },
  additiveSwitch: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    "& > *": {
      marginRight: spacing(1),
    },
  },
  diagramStoryText: {
    margin: spacing(1),
    padding: spacing(1),
    maxWidth: "100%",
  },
  widget: {
    minWidth: 300,
  },
  itemHeader: {
    padding: spacing(0),
    margin: spacing(0),
    gap: spacing(1),
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  shader: {
    maxWidth: "250px",
    padding: spacing(1),
    margin: spacing(1),
  },
}

const initialValues = {
  name: "",
  description: "",
  tags: [],
}

const SLIDE_TEXT_WIDTH = 300

const StoryEditForm = (props) => {
  const storyWidgets = {
    text: TextStoryWidget,
    highlight: HighlightStoryWidget,
  }

  const dispatch = useDispatch()

  const { setTitle } = props

  const { enqueueSnackbar } = useSnackbar()

  const { width } = useWindowDimensions()

  const [widgetsFlexWrap, setWidgetsFlexWrap] = useState("wrap")

  const [storyId, setStoryId] = useState(props.computedMatch.params.id)

  const { values, setValues, handleInputChange } = useForm(initialValues)

  const [showPromptDialog, setShowPromptDialog] = useState(false)

  const [showBillingDialog, setShowBillingDialog] = useState(false)

  const [generationPanelCount, setGenerationPanelCount] = useState(0)

  const [aiRole, setAiRole] = useState(false)

  const modelCache = useSelector(selectModelState)

  const storyEditInitialState = useSelector(selectEditMode)

  const [storyCreateId, setStoryCreateId] = useState("")

  const [showChatPrompt, setShowChatPrompt] = useState(false)

  const [chatPromptData, setChatPromptData] = useState()

  const [diagramPromptMessages, setDiagramPromptMessages] = useState([])

  const [storyPrompts, setStoryPrompts] = useState([])

  const [diagramDescription, setDiagramDescription] = useState("")

  const [isStoryEditing, setStoryEditing] = useState(storyEditInitialState)

  // Controls how the story items are displayed
  const [storyItemEditMode, setStoryItemEditMode] = useState("view")

  const [view, setView] = useState()

  const [shaders, setShaders] = useState([])

  const [modelEditViews, setModelEditViews] = useState([])

  const [params, setParams] = useState()

  const [accountId, setAccountId] = useState()

  // Are we hovering over a story item? -- if so, we only show this one in the diagram
  const [hoveringStoryItemId, setHoveringStoryItemId] = useState()

  // The model from which this view is taken
  const [model, setModel] = useState()

  const autoFitToWindow = useSelector(selectAutofitDiagram)

  // This will be value 10-100, which we need to divide by 100 to get the scale to pass to the <Diagram> component
  const diagramScale = useSelector(selectDiagramScale)

  const [resizeWidth, setResizeWidth] = useState()

  const [selectedColor, setSelectedColor] = useState()

  const [selectedStoryItemId, setSelectedStoryItemId] = useState()

  const isEditing = useMemo(() => {
    return storyItemEditMode === "edit"
  }, [storyItemEditMode])

  const isHighlightDiagram = useMemo(() => {
    return storyItemEditMode !== "slide"
  }, [storyItemEditMode])

  const [selectedElementIds, setSelectedElementIds] = useState([])

  const [selectedElementColors, setSelectedElementColors] = useState([])

  const [diagramWidth, setDiagramWidth] = useState()

  const [selectedStoryItemText, setSelectedStoryItemText] = useState("")

  const diagramMaxWidth = useMemo(() => {
    const widthToRemoveIfInSlideMode =
      storyItemEditMode === "slide" ? SLIDE_TEXT_WIDTH : 0
    return autoFitToWindow
      ? resizeWidth - 120 - widthToRemoveIfInSlideMode
      : 2000
  }, [autoFitToWindow, resizeWidth, storyItemEditMode])

  const isShowSelectedStoryItemText = useMemo(() => {
    return storyItemEditMode === "view" && selectedStoryItemText
  }, [storyItemEditMode, selectedStoryItemText])

  const [selectedShader, setSelectedShader] = useState()

  const [yesNoConfig, setYesNoConfig] = useState({
    title: "Delete?",
    description: "Delete story item?",
    openPrompt: false,

    // this method is set when we prompt for deletion
    handleConfirm: null,
  })

  const shaderOptions = useMemo(() => {
    return getShaderOptions({ shaders })
  }, [shaders])

  useEffect(() => {
    if (view && modelEditViews) {
      console.log("%cuseEffect:view", "color:lightgreen", {
        view,
        modelEditViews,
      })
    }
  }, [view, modelEditViews])

  useEffect(() => {
    if (accountId) {
      db.collection("story_prompts")
        .where("account_id", "==", accountId)
        .get()
        .then((querySnapshot) => {
          const data = querySnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }))
          console.log("%cstory prompts", "color:lightgreen", {
            data,
            accountId,
          })
          setStoryPrompts(data)
        })
    }
  }, [accountId])

  // Shift position of story widgets to the right or below
  // depending on if there's space to the right.
  useEffect(() => {
    if (width && diagramWidth) {
      const positionRight = width - diagramWidth > 400
      setWidgetsFlexWrap(positionRight ? "nowrap" : "wrap")
    }
  }, [width, diagramWidth])

  useEffect(() => {
    const unsub = firebase.auth().onAuthStateChanged((user) => {
      if (user !== null) {
        user.getIdTokenResult(true).then((token) => {
          setAccountId(token.claims.account_id)
          setAiRole(token.claims.roles.includes(Roles.AIM_AI))
        })
      }
    })

    return unsub
  }, [])

  // useEffect(() => {
  //     if (!accountId) return

  //     db.collection("views")
  //         .where("account_id", "==", accountId)
  //         .get()
  //         .then((querySnapshot) => {
  //             const data = querySnapshot.docs.map((doc) => doc.data())
  //             console.log("%cdata", "color:lightgreen", data)
  //             setModelEditViews(data)
  //         })
  // }, [accountId])

  useEffect(() => {
    if (accountId) {
      const unsubscribe = db
        .collection("shaders")
        .where("account_id", "==", accountId)
        .onSnapshot((snapshot) => {
          const newShaders = snapshot.docs.map((doc) => {
            return { id: doc.id, ...doc.data() }
          })
          setShaders(newShaders)
        })

      return unsubscribe
    }
  }, [accountId])

  const storyElementLabels = useMemo(() => {
    // We need to flip the structure of story elements and selected items so that its by element id

    const result = _.flatten(
      values.items?.map((item) =>
        item.element_colors?.map((element) => ({
          seq: item.seq,
          id: element.id,
        }))
      )
    )

    const elementIds = Array.from(new Set(result.map((item) => item.id)))

    const idsAndSeq = elementIds.map((id) => ({
      id,
      seq: result.filter((item) => item.id === id).map((item) => item.seq),
    }))

    return idsAndSeq
  }, [values])

  const storyElementLabelsToDisplay = useMemo(() => {
    if (hoveringStoryItemId) {
      return storyElementLabels.filter((item) =>
        item.seq.includes(hoveringStoryItemId)
      )
    }

    return storyElementLabels
  }, [storyElementLabels, hoveringStoryItemId])

  useEffect(() => {
    if (storyId && accountId) {
      console.log("retrieve stories", { accountId, storyId })
      dataServices
        .getStoriesById({ accountId, storyIds: [storyId] })
        .then((stories) => {
          const storyItems = stories[0].items.map((item) => ({
            title: "",
            ...item,
          }))

          setTitle(stories[0].name)

          const story = {
            // Init tags if they don't exist
            tags: [],
            ...stories[0],
            items: storyItems.map((item) => ({
              // Provide default value for legacy stories
              shading: true,

              // Provide default value for legacy stories
              element_colors: [],
              ...item,
            })),
          }
          delete story.id
          setValues(story)

          if (storyItems.length > 0) {
            setStoryItemEditMode("view")
            setSelectedStoryItemId(storyItems[0].id)
          }

          const newParams = {
            modelId: story.parent_id,
            viewId: story.view_id,
            fileName: story.file_name,
          }
          setParams(newParams)

          const modelCacheKey = modelServices.createModelCacheKey(
            story.file_name,
            story.parent_id,
            story.type
          )

          // const cachedModel = Object.values(modelCache).find(
          //     (cacheEntry) =>
          //         cacheEntry.parent_id === modelCacheKey.parentId &&
          //         cacheEntry.model.file === modelCacheKey.fileName
          // )

          const cachedModel = modelServices.searchModelCache({
            modelCacheKey: modelCacheKey,
            modelCache: modelCache,
          })

          if (cachedModel === undefined) {
            // Load the model

            const subFolder = { project: "projects", component: "components" }[
              story.type
            ]

            const filePath = `accounts/${story.account_id}/${subFolder}/${story.parent_id}/`

            // Get parent for story so we can use the right cache name

            dataServices.getStoryParent(storyId).then((parent) => {
              modelServices.loadFile(
                filePath,
                story.file_name,
                loadModelIntoCache,
                {
                  story,
                  storyParent: parent,
                  viewId: newParams.viewId,
                }
              )
            })
          } else {
            const view = getView(cachedModel, newParams.viewId)
            setView(view)
            setModel(cachedModel)
          }
        })
    }
  }, [storyId, accountId])

  const addGeneratedStoryWidgets = (storyGenResult) => {
    const maxId = getMaxItemId()
    const newStoryWidgets = storyGenResult.map((storyItem, index) => {
      // Add 5 to skip the purple colors, just because...
      const nextColor =
        colorServices.baseColors[
          (index * 2 + 5) % colorServices.baseColors.length
        ]
      console.log("next color", { index, nextColor })
      return {
        id: maxId + index + 1,
        seq: values.items.length + index + 1,
        type: "text",
        title: storyItem.name,
        text: storyItem.description,
        selected_elements: storyItem.elementids,
        element_colors: storyItem.elementids.map((id) => ({
          id: id,
          color: nextColor[100],
        })),
        additive: false,
        shading: false,
      }
    })

    setValues({
      ...values,
      items: [...values.items, ...newStoryWidgets],
    })
  }

  const handleCreateChatPrompt = () => {
    console.log("create prompt", { modelCache, params })

    const modelCacheItem = modelServices.getModelFromCache({
      modelCache: modelCache,
      parentId: params.modelId,
      fileName: params.fileName,
    })

    const promptData = chatPromptServices.createPromptDataFromModelCache(
      modelCacheItem,
      view
    )

    setChatPromptData(promptData)
    setShowChatPrompt(true)
  }

  const getView = (modelCacheItem, viewId) => {
    return modelCacheItem.model.views.find((view) => view.id === viewId)
  }

  const toggleEdit = () => {
    const newVal = !isStoryEditing
    setStoryEditing(newVal)
    dispatch(setEditMode(newVal))
  }

  useEffect(() => {
    if (props.computedMatch.params.id) {
      setStoryId(props.computedMatch.params.id)
    }
  }, [props])

  useEffect(() => {
    if (!selectedStoryItemId) {
      setSelectedElementIds([])
      return
    }

    const storyItem = values.items.find(
      (item) => item.id === selectedStoryItemId
    )

    if (!storyItem) {
      return
    }

    const elementIds = []
    const elementColors = []

    if (storyItem.selected_elements) {
      elementIds.push(...storyItem.selected_elements)
      elementColors.push(...storyItem.element_colors)
    }

    if (storyItem.additive) {
      let startSeq = storyItem.seq - 1

      while (startSeq > 0) {
        const prevStoryItem = values.items.find((item) => item.seq === startSeq)

        if (prevStoryItem) {
          elementIds.push(...prevStoryItem.selected_elements)

          prevStoryItem.element_colors.forEach((item) => {
            if (
              elementColors.find((existing) => existing.id === item.id) ===
              undefined
            ) {
              elementColors.push(item)
            }
          })

          if (!prevStoryItem.additive) {
            break
          }
        }

        startSeq--
      }
    } else {
      elementColors.push(...storyItem.element_colors)
    }

    console.groupEnd()
    // We might have deleted the story item, so 'storyItem' could be undefined
    setSelectedElementIds(Array.from(new Set(elementIds)))

    const deduped = _.uniqBy(
      elementColors,
      (item) => `${item.id}-${item.color}`
    )

    setSelectedElementColors(deduped)
    setSelectedStoryItemText(storyItem?.text || "")
  }, [values, selectedStoryItemId])

  const selectedStoryItem = useMemo(() => {
    if (!selectedStoryItemId) {
      return null
    }

    const result = values.items.find((item) => item.id === selectedStoryItemId)

    return result
  }, [selectedStoryItemId, values.items])

  const handleUpdateTags = (newTags) => {
    const newValues = {
      ...values,
      tags: newTags,
    }
    setValues(newValues)
  }

  const loadModelIntoCache = (model, fileName, text, props) => {
    const { story, storyParent, viewId } = props

    const modelState = modelServices.createModelCacheItem(
      model,
      fileName,
      // storyParent will either be a project or component, both of which have a 'name' property
      storyParent.name,
      story.parent_id,
      story.type
    )

    dispatch(setModelState(modelState))
    setModel(modelState)

    const view = getView(modelState, viewId)
    setView(view)
  }

  const getMaxItemId = () => {
    let max = 0

    values.items.forEach((item) => {
      if (item.id > max) {
        max = item.id
      }
    })

    return max
  }

  const handleAddStoryWidget = (type, positionAfterItemId) => {
    const afterItem = values.items.find(
      (item) => item.id === positionAfterItemId
    )

    const resequencedItems = values.items.map((item) => ({
      ...item,
      seq: item.seq <= afterItem.seq ? item.seq : item.seq + 1,
    }))

    const newStoryItem = {
      // seq can change, e.g. when we swap the order of story items
      seq: afterItem ? afterItem.seq + 1 : 1,

      // story item id never changes, and is used for the react key
      id: getMaxItemId() + 1,
      type,
      title: "",
      text: "",
      selected_elements: [],
      // Are the selected elements for a story widget additive to the previous story widget?
      additive: false,
      // Are unselected diagram elements shaded normally (true) or dimmed (false)?
      // Default to the previous items value, otherwise set to true
      shading: afterItem ? afterItem.shading : true,
      element_colors: [],
    }

    const sortedItems = [...resequencedItems, newStoryItem].sort(
      (a, b) => a.seq - b.seq
    )

    const newValues = {
      ...values,
      items: sortedItems,
    }
    setValues(newValues)

    setSelectedStoryItemId(newStoryItem.id)
    setStoryItemEditMode("edit")
  }

  const handleClickItem = ({ elementId }) => {
    if (!isEditing) {
      enqueueSnackbar("Please start editing the story first", {
        variant: "info",
      })
      return
    }

    if (values.items.length === 0) {
      enqueueSnackbar("Please add a story item first", { variant: "info" })
      return
    }

    if (!selectedStoryItemId) {
      enqueueSnackbar("Please select a story item first", { variant: "info" })
      return
    }

    const selectedStoryItem = values.items.find(
      (item) => item.id === selectedStoryItemId
    )
    const selectedElements = selectedStoryItem.selected_elements || []

    const isSelectElement = !selectedElements.includes(elementId)

    const storyItemColors = selectedStoryItem?.element_colors || []

    let newStoryItemColors

    if (isSelectElement) {
      // Add element color
      const newElementIdSelection = { id: elementId }
      if (selectedColor) {
        newElementIdSelection.color = selectedColor
      }
      newStoryItemColors = [newElementIdSelection, ...storyItemColors]
    } else {
      // Remove element color
      newStoryItemColors = [
        ...storyItemColors.filter((element) => element.id !== elementId),
      ]
    }

    // toggle the selection of the element
    const newSelectedElements = isSelectElement
      ? [...selectedElements, elementId]
      : selectedElements.filter((element) => element !== elementId)

    const newStoryItem = {
      ...selectedStoryItem,
      selected_elements: newSelectedElements,
      element_colors: newStoryItemColors,
    }

    console.log("%cupdate new story item", "color: lightgreen", {
      newStoryItem,
    })

    const newValues = {
      ...values,
      items: values.items.map((item) => {
        if (item.seq === newStoryItem.seq) {
          return newStoryItem
        }
        return item
      }),
    }

    setValues(newValues)
  }

  const handleClickStoryWidget = (storyItemId) => {
    setSelectedStoryItemId(storyItemId)
  }

  const handleSave = () => {
    // Remove element colors entries for story items that are no longer in the story

    const mergeValues = {
      name: values.name,
      description: values.description,
      items: values.items,
      tags: values.tags,
      modified: dataServices.localTimestamp(),
      tags_index: tagServices.toStringArray(values.tags),
    }

    console.log("%cupdate story", "color: lightgreen", { mergeValues })
    db.collection("stories")
      .doc(storyId)
      .update(mergeValues, { merge: true })
      .then(() => {
        const collection = { project: "projects", component: "components" }[
          values.type
        ]

        if (!collection) {
          console.error(
            `Story type is [${values.type}]. Expecting 'project' or 'component'. Unable to update story summary for story ${storyId}`
          )
        } else {
          db.collection(collection)
            .doc(values.parent_id)
            .get()
            .then((doc) => {
              const existingStorySummary = doc.data().stories || []
              const existingSummaryItem =
                existingStorySummary.find((item) => item.id === storyId) || {}

              const newSummaryItem = { id: storyId, name: values.name }

              if (!_.isEqual(existingSummaryItem, newSummaryItem)) {
                const newStoriesSummary = [
                  ...existingStorySummary.filter((item) => item.id !== storyId),
                  newSummaryItem,
                ]
                const mergeStorySummary = {
                  stories: newStoriesSummary,
                  modified: dataServices.localTimestamp(),
                }

                db.collection(collection)
                  .doc(values.parent_id)
                  .update(mergeStorySummary, { merge: true })
              }
            })
        }
      })
      .then(() => {
        enqueueSnackbar("Story saved", { variant: "success" })
      })
  }

  const handleApplyShader = (item, shaderId) => {
    console.log("%chandleApplyShader", "color:lightgreen", { item, shaderId })

    const shader = shaders.find((shader) => shader.id === shaderId)

    const elementsInView = model.model.elements
      .filter((element) =>
        view.elements.find(
          (ve) => ve.diagramObject.archimateElement === element.id
        )
      )
      .map((element) => element.id)

    // See which elements have a property with the same key as the shader

    const elements = model.model.elements.filter((element) =>
      elementsInView.includes(element.id)
    )

    const elementsWithShaderProperty = elements.filter((element) =>
      element.properties.find((property) => property.key === shader.property)
    )

    const colorsToApply = elementsWithShaderProperty.flatMap((element) =>
      element.properties
        .filter((p) => p.key === shader.property)
        .map((p) => ({
          id: element.id,
          color: shader.config.colors.find((c) => c.value === p.value)?.color,
        }))
    )

    console.log("%chandleApplyShader", "color: lightgreen", {
      colorsToApply,
      item,
    })

    const newStoryItem = {
      ...item,
      shader_id: shaderId,
      element_colors: colorsToApply,
      selected_elements: colorsToApply.map((color) => color.id),
    }

    setValues((curr) => ({
      ...curr,
      items: curr.items.map((item) => {
        if (item.seq === newStoryItem.seq) {
          return newStoryItem
        }
        return item
      }),
    }))
  }

  const handleItemChange = (item, event) => {
    const newItem = {
      ...values.items.find((currItem) => currItem.seq === item.seq),
      [event.target.name]: event.target.value,
    }

    setValues((curr) => ({
      ...curr,
      items: curr.items.map((currItem) => {
        if (currItem.seq === item.seq) {
          return newItem
        }
        return currItem
      }),
    }))

    if (event.target.name === "shader_id") {
      handleApplyShader(item, event.target.value)
    }
  }

  //TODO: provide a palette of colours so that you can manually heatmap a diagram, or use any number of other colours.
  //TODO: make story development multi-user, so that editing the story details is a transaction, and updating
  // story items incl. color tagging is merged into the story, and that the story edit page has a listener so
  // that other viewers receive immediate updates.

  const handleMoveItemUp = (itemId) => {
    const item = values.items.find((item) => item.id === itemId)

    setValues((curr) => ({
      ...curr,
      items: curr.items
        .map((currItem) => {
          if (currItem.seq === item.seq) {
            return {
              ...currItem,
              seq: currItem.seq - 1,
            }
          } else if (currItem.seq === item.seq - 1) {
            return {
              ...currItem,
              seq: currItem.seq + 1,
            }
          }
          return currItem
        })
        .sort((a, b) => a.seq - b.seq),
    }))
  }

  const handleMoveItemDown = (itemId) => {
    const item = values.items.find((item) => item.id === itemId)

    setValues((curr) => ({
      ...curr,
      items: curr.items
        .map((currItem) => {
          if (currItem.seq === item.seq) {
            return {
              ...currItem,
              seq: currItem.seq + 1,
            }
          } else if (currItem.seq === item.seq + 1) {
            return {
              ...currItem,
              seq: currItem.seq - 1,
            }
          }
          return currItem
        })
        .sort((a, b) => a.seq - b.seq),
    }))
  }

  const handleMoveNext = (storyItemId) => {
    if (storyItemId) {
      const storyItem = values.items.find((item) => item.id === storyItemId)
      const nextStoryItem = values.items.find(
        (item) => item.seq === storyItem.seq + 1
      )
      if (nextStoryItem) {
        handleClickStoryWidget(nextStoryItem.id)
      }
    }
  }

  const handleMovePrev = (storyItemId) => {
    if (storyItemId) {
      const storyItem = values.items.find((item) => item.id === storyItemId)
      const previousStoryItem = values.items.find(
        (item) => item.seq === storyItem.seq - 1
      )
      if (previousStoryItem) {
        handleClickStoryWidget(previousStoryItem.id)
      }
    }
  }

  const handleDeleteItem = (itemId) => {
    const newItems = values.items.filter((item) => item.id !== itemId)

    const selectedIndex = newItems.findIndex(
      (item) => item.id === selectedStoryItemId
    )
    if (selectedIndex === -1) {
      setSelectedStoryItemId(
        newItems.length === 0 ? null : newItems[newItems.length - 1].id
      )
    }

    const newValues = {
      ...values,
      // Resequence the items, so they're sequenced 1, 2, ..., but leave ids as they are
      items: newItems.map((item, index) => ({ ...item, seq: index + 1 })),
    }

    setValues(newValues)
  }

  const getDiagramPrompt = ({ model, view }) => {
    const promptData = chatPromptServices.createPromptDataFromModelCache(
      model,
      view
    )

    const promptLayers = chatPromptServices.getPromptLayers(promptData)

    const prompt = chatPromptServices.createChatPrompt({
      promptData,
      promptLayers,
      includeIds: true,
    })

    return prompt
  }

  const handleShowCreateStory = () => {
    if (!aiRole) {
      setShowBillingDialog(true)
    } else {
      console.log("%chandleShowCreateStory", "color:lightgreen", {
        model,
        view,
      })

      const prompt = getDiagramPrompt({ model, view })

      const diagramPrompt = storyPrompts.find(
        (item) => item.name === storyCreateId
      )

      if (!diagramPrompt) {
        enqueueSnackbar(
          `Please select a diagram prompt, or create one in the Story Prompts left menu`,
          { variant: "info" }
        )
        return
      }

      const messages = [{ role: "user", content: diagramPrompt.prompt }]

      console.log("set dialog prompt inputs", { messages, prompt })

      setDiagramPromptMessages(messages)
      setDiagramDescription(prompt)
      setShowPromptDialog(true)
    }
  }

  const handleCreateStory = async (prompt) => {
    // Check prompt contains '${diagram}'. If not ask the user to add it.

    // eslint-disable-next-line no-template-curly-in-string
    if (!prompt.includes("${diagram}")) {
      // eslint-disable-next-line no-template-curly-in-string
      enqueueSnackbar(
        "Please add the text ${diagram} to the prompt, which gets replaced by a description of your diagram when you click OK",
        { variant: "info" }
      )
      return
    }

    const diagramDescriptionLines = getDiagramPrompt({ model, view })
    const diagramDescription = diagramDescriptionLines.join("\n")

    const newPrompt = diagramPromptServices.replaceDiagramPromptPlaceholder(
      prompt,
      diagramDescription
    )

    const messages = [
      { role: "user", content: newPrompt },
      {
        role: "user",
        content: diagramPromptServices.outputFormatSpecificationPrompt.content,
      },
    ]
    console.log("replaced prompt", {
      prompt,
      newPrompt,
      diagramDescription,
      messages,
    })

    setShowPromptDialog(false)

    const gptFunctions = [
      {
        name: "get_story_items",
        description:
          "Get 1 or more story items that are mapped to diagram elements",
        parameters: {
          type: "object",
          properties: {
            storyitems: {
              type: "array",
              items: {
                type: "object",
                properties: {
                  name: {
                    type: "string",
                    description:
                      "The heading that summarises this part of the story",
                  },
                  description: {
                    type: "string",
                    description: "The narrative for this part of the story",
                  },
                  elementids: {
                    type: "array",
                    items: {
                      type: "string",
                    },
                  },
                },
                required: ["name", "description", "elementids"],
              },
            },
          },
          required: ["storyitems"],
        },
      },
    ]

    console.log("messages", messages)

    setGenerationPanelCount(1)

    chatGenerationServices
      .getChatCompletionResult({
        messages: messages,
        functions: gptFunctions,
        function_call: { name: "get_story_items" },
        model: chatGenerationServices.GPT_4o_LATEST,
      })
      .then((result) => {
        console.log("result", result)

        setGenerationPanelCount(0)

        if (result.error) {
          enqueueSnackbar(result.error, { variant: "error" })
          return
        }

        const funcCallResultStr = result.jsonStr
        const json = JSON.parse(funcCallResultStr)
        const storyItemJson = json["storyitems"]

        // Check if element ids in the result actually exist in the diagram

        const elementIds = model.model.elements.map((element) => element.id)

        const invalidElementIds = storyItemJson.flatMap((item) =>
          item.elementids.filter((id) => !elementIds.includes(id))
        )

        if (invalidElementIds.length > 0) {
          enqueueSnackbar(
            `The following element ids are not in the diagram: ${invalidElementIds.join(
              ", "
            )}`,
            { variant: "error" }
          )
        }

        addGeneratedStoryWidgets(storyItemJson)
      })
  }

  const handleStoryNavigation = (event) => {
    if (!isEditing) {
      switch (event.key) {
        case "ArrowRight":
          handleMoveNext(selectedStoryItemId)
          break

        case "ArrowLeft":
          handleMovePrev(selectedStoryItemId)
          break

        default:
          // no op
          break
      }
    }
  }

  const handlePromptDeleteStoryItem = (itemId) => {
    const newYesNoConfig = {
      ...yesNoConfig,
      openPrompt: true,
      handleConfirm: () => {
        handleDeleteItem(itemId)
      },
    }

    setYesNoConfig(newYesNoConfig)
  }

  return (
    <>
      <TooltipHover />

      {showBillingDialog && (
        <GetAIMAIDialog
          open={showBillingDialog}
          onClose={() => setShowBillingDialog(false)}
        />
      )}

      {showChatPrompt && (
        <CreatePromptDialog
          open={showChatPrompt}
          onClose={() => setShowChatPrompt(false)}
          promptData={chatPromptData}
        />
      )}

      {showPromptDialog && (
        <DiagramPromptDialog
          open={showPromptDialog}
          onClose={() => setShowPromptDialog(false)}
          diagramPromptMessages={diagramPromptMessages}
          diagramDescription={diagramDescription}
          handleCreateStory={handleCreateStory}
        />
      )}

      <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
        <ModelBreadcrumbs modelState={model} suffix="story">
          {view && view.name}
        </ModelBreadcrumbs>

        {/* <Box sx={{ display: "flex", marginLeft: "auto" }}>
                    <ToggleEditing toggleEdit={toggleEdit} />
                </Box> */}
      </Box>

      <YesNo config={yesNoConfig} />

      <Stack
        direction="column"
        onKeyDown={(event) => handleStoryNavigation(event)}
        gap={2}
      >
        {!isStoryEditing && (
          <>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography variant="h5">
                <b>{values.name}</b>
              </Typography>
              <Typography variant="body2">{values.description}</Typography>
            </Box>
            <Divider />
          </>
        )}
        {isStoryEditing && (
          <Box sx={styles.editFields}>
            <Controls.TextInput
              name="name"
              label="Name"
              value={values.name}
              onChange={handleInputChange}
            />

            <Controls.TextInput
              name="description"
              label="Description and purpose of story"
              multiline
              value={values.description}
              onChange={handleInputChange}
            />
          </Box>
        )}
        {!isStoryEditing && (
          <TagSummary isEditing={isStoryEditing} tags={values.tags} />
        )}
        {isStoryEditing && (
          <Box>
            <TagPanel
              tags={values.tags}
              handleUpdateTags={handleUpdateTags}
              heading={"Story Tags"}
              isEditing={isStoryEditing}
            />
          </Box>
        )}

        <Stack direction="row" gap={2} sx={{ alignItems: "center" }}>
          <Controls.Button
            onClick={toggleEdit}
            text="Edit"
            endIcon={<EditIcon />}
          />
          <Controls.Button
            onClick={handleSave}
            text="Save"
            variant="contained"
            endIcon={<SaveIcon />}
          />
          <Tooltip title="Create auto-story using GPT-4">
            <span>
              <IconButton onClick={handleShowCreateStory}>
                <AutoAwesomeIcon sx={aiIcon} />
              </IconButton>
            </span>
          </Tooltip>

          <FormControl>
            <Select
              variant="outlined"
              size="small"
              sx={{ width: "200px" }}
              value={storyCreateId}
              onChange={(e) => {
                setStoryCreateId(e.target.value)
              }}
            >
              {storyPrompts.map((item) => (
                <MenuItem key={item.id} value={item.name}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Stack>

        {generationPanelCount > 0 && (
          <AIProgress
            generationPanelCount={generationPanelCount}
            // This is a 1-shot so cannot stop it.
            handleRequestStop={undefined}
            message={
              "Creating story...(can take 90 seconds, please be patient)"
            }
          />
        )}

        <DiagramResize setWidth={setResizeWidth} />

        <Box sx={styles.diagramAndStory} style={{ flexWrap: widgetsFlexWrap }}>
          <Box>
            {isShowSelectedStoryItemText && (
              <Box>
                <Paper
                  sx={styles.diagramStoryText}
                  style={{ maxWidth: diagramWidth }}
                >
                  <Typography
                    variant="h6"
                    color="textSecondary"
                    component={"span"}
                  >
                    {selectedStoryItemText}
                  </Typography>
                </Paper>
              </Box>
            )}
            {isEditing && (
              <Shaders
                selectedShader={selectedShader}
                setSelectedShader={setSelectedShader}
                selectedColor={selectedColor}
                setSelectedColor={setSelectedColor}
              />
            )}
            <Box
              sx={{ display: "flex", flexDirection: "row", flexWrap: "nowrap" }}
            >
              {storyItemEditMode === "slide" && values.items && (
                <StoryItemsSummary
                  storyItems={values.items}
                  setHoveringStoryItemId={setHoveringStoryItemId}
                />
              )}

              <Box sx={styles.diagram}>
                {model && (
                  <Diagram
                    params={params}
                    model={model}
                    maxWidth={diagramMaxWidth}
                    showCopyButton={true}
                    showCreateStoryButton={false}
                    maxScale={diagramScale / 100}
                    handleClickItem={handleClickItem}
                    // highlightElementIds may be redundant, and we likely only require the elementColors property
                    highlightElementIds={
                      (isHighlightDiagram && selectedElementIds) || []
                    }
                    dimElementIfNotHighlighted={!selectedStoryItem?.shading}
                    elementColors={
                      (isHighlightDiagram && selectedElementColors) || []
                    }
                    setDiagramWidth={setDiagramWidth}
                    storyElementLabels={storyElementLabelsToDisplay}
                    handleCreateChatPrompt={handleCreateChatPrompt}
                  />
                )}
                {!model && (
                  <Skeleton variant="rectangular" width={300} height={300} />
                )}
              </Box>
            </Box>
          </Box>

          <Box>
            {storyItemEditMode !== "slide" && (
              <>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "10px",
                  }}
                >
                  <IconButton
                    color="primary"
                    onClick={() => {
                      handleMovePrev(selectedStoryItemId)
                    }}
                    size="large"
                  >
                    <Tooltip title="Move previous (left-arrow key)">
                      <PrevIcon />
                    </Tooltip>
                  </IconButton>
                  <IconButton
                    color="primary"
                    onClick={() => {
                      handleMoveNext(selectedStoryItemId)
                    }}
                    size="large"
                  >
                    <Tooltip title="Move next (right-arrow key)">
                      <NextIcon />
                    </Tooltip>
                  </IconButton>
                  <IconButton
                    onClick={() =>
                      handlePromptDeleteStoryItem(selectedStoryItemId)
                    }
                    size="large"
                    disabled={!selectedStoryItemId}
                  >
                    <DeleteIcon />
                  </IconButton>
                  <IconButton
                    onClick={() => handleMoveItemUp(selectedStoryItemId)}
                    size="large"
                    disabled={!selectedStoryItemId}
                  >
                    <Tooltip title="Move story item up">
                      <ArrowUpwardIcon />
                    </Tooltip>
                  </IconButton>
                  <IconButton
                    onClick={() => handleMoveItemDown(selectedStoryItemId)}
                    size="large"
                    disabled={!selectedStoryItemId}
                  >
                    <Tooltip title="Move story item down">
                      <ArrowDownwardIcon />
                    </Tooltip>
                  </IconButton>
                  <IconButton
                    onClick={() =>
                      handleAddStoryWidget("text", selectedStoryItemId)
                    }
                    size="large"
                  >
                    <Tooltip title="Add a new story item">
                      <AddIcon />
                    </Tooltip>
                  </IconButton>

                  <StoryItemEditMode
                    storyItemEditMode={storyItemEditMode}
                    setStoryItemEditMode={setStoryItemEditMode}
                  />
                </Box>

                <Box sx={styles.items}>
                  {values.items &&
                    values.items.map((item, index) => {
                      const Widget = storyWidgets[item.type]

                      return (
                        <Widget
                          key={item.id}
                          handleClick={() => handleClickStoryWidget(item.id)}
                          selected={selectedStoryItemId === item.id}
                          item={item}
                          handleItemChange={handleItemChange}
                          isEditing={isEditing}
                          shaderOptions={shaderOptions}
                        />
                      )
                    })}
                </Box>
              </>
            )}
            {values.items && values.items.length === 0 && (
              <Box>
                <Alert severity="info">
                  No story items defined. Click + to add a story item
                </Alert>
              </Box>
            )}
          </Box>
        </Box>
        {storyItemEditMode === "slide" && (
          <StoryItemEditMode
            storyItemEditMode={storyItemEditMode}
            setStoryItemEditMode={setStoryItemEditMode}
          />
        )}
      </Stack>
    </>
  )
}

const Shaders = (props) => {
  const { selectedColor, setSelectedColor } = props

  const primaryColors = [
    colors.red,
    colors.pink,
    colors.purple,
    colors.deepPurple,
    colors.indigo,
    colors.blue,
    colors.lightBlue,
    colors.cyan,
    colors.teal,
    colors.green,
    colors.lightGreen,
    colors.lime,
    colors.yellow,
    colors.amber,
    colors.orange,
    colors.deepOrange,
  ]

  return (
    <Box>
      <Box>
        <Paper sx={styles.shader}>
          <Box>
            {selectedColor && (
              <Box sx={{ display: "flex", flexDirection: "row", gap: 1 }}>
                <Typography variant="caption">
                  selected color for shading elements
                </Typography>
                <ColorBox color={selectedColor} />
              </Box>
            )}
            {!selectedColor && (
              <Typography variant="caption">
                No color selected. Will use default color for shading elements
              </Typography>
            )}
          </Box>
        </Paper>
      </Box>
      <Box
        sx={{
          marginTop: "15px",
          display: "flex",
          flexDirection: "row",
        }}
      >
        <Box>
          <Shader
            type="static"
            options={[colors.teal["A200"]]}
            setSelectedColor={setSelectedColor}
          />
        </Box>
        <Box>
          <Shader
            type="static"
            options={[
              colors.red[200],
              colors.amber[200],
              colors.green[200],
              colors.blue[200],
            ]}
            setSelectedColor={setSelectedColor}
          />
        </Box>
        <Box>
          <Shader
            type="static"
            options={primaryColors.map((color) => color["A400"])}
            setSelectedColor={setSelectedColor}
          />
        </Box>
        {/* <Box>
                    <Shader
                        type="property"
                        property="type"
                        colorMap={[
                            { value: "good", color: colors.green[300] },
                            { value: "bad", color: colors.red[300] },
                        ]}
                    />
                </Box>
                <Box>
                    <Shader
                        type="gradient"
                        property="amount"
                        startColor={colors.green["A400"]}
                        endColor={colors.red["A400"]}
                    />
                </Box> */}
      </Box>
    </Box>
  )
}

const Shader = (props) => {
  const {
    type,
    property,
    options,
    colorMap,
    startColor,
    endColor,
    setSelectedColor,
  } = props

  const interpolatedColors = useMemo(() => {
    if (startColor && endColor) {
      const col = colorServices.interpolate(startColor, endColor)
      return col
    }
  }, [startColor, endColor])

  const [selectedShaderColor, setSelectedShaderColor] = useState()

  useEffect(() => {
    if (type === "static" && options.length > 0) {
      setSelectedShaderColor(options[0])
    }
  }, [type, options, setSelectedColor, setSelectedShaderColor])

  const handleSelectColor = (color) => {
    if (type === "static") {
      setSelectedColor(color)
      setSelectedShaderColor(color)
    }
  }

  return (
    <Paper sx={styles.shader}>
      {options && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            gap: "5px",
            flexWrap: "wrap",
          }}
        >
          {options.map((option, index) => (
            <ColorBox
              key={index}
              color={option}
              setSelectedColor={handleSelectColor}
            />
          ))}
        </Box>
      )}
      {type === "property" && colorMap && (
        <Box sx={{ display: "flex", flexDirection: "column", gap: "5px" }}>
          {colorMap.map((item) => (
            <Box
              key={item.value}
              sx={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
                gap: "5px",
              }}
            >
              <ColorBox
                color={item.color}
                setSelectedColor={handleSelectColor}
              />
              <Typography variant="caption">{item.value}</Typography>
            </Box>
          ))}
        </Box>
      )}
      {type === "gradient" && (
        <>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              gap: "5px",
            }}
          >
            {interpolatedColors &&
              interpolatedColors.map((color, index) => (
                <ColorBox key={color} color={color} />
              ))}
          </Box>
        </>
      )}
      {(type === "gradient" || type === "property") && (
        <>
          <Box>
            <Controls.Button
              text={<Typography variant="caption">Apply</Typography>}
              size="small"
            />
          </Box>
          <Box>{property}</Box>
        </>
      )}
    </Paper>
  )
}

const ColorBox = (props) => {
  const { color, setSelectedColor } = props

  const handleClickColor = () => {
    if (setSelectedColor) {
      setSelectedColor(color)
    }
  }

  return (
    <Box
      sx={{ width: "20px", height: "20px", backgroundColor: color }}
      onClick={handleClickColor}
    ></Box>
  )
}

const TextStoryWidget = (props) => {
  const {
    handleClick,
    selected,
    item,
    handleItemChange,
    isEditing,
    shaderOptions,
  } = props

  const cardRef = createRef()

  return (
    <Card raised={selected} ref={cardRef} sx={styles.widget}>
      <CardActionArea onClick={handleClick}>
        <CardHeader
          style={{ padding: "6px" }}
          title={
            <Box sx={styles.itemHeader}>
              <Typography variant="caption" component={"span"}>
                {item.title || `<Title>`}
              </Typography>
              <Typography variant="caption" component={"span"}>
                {item.seq}
              </Typography>
            </Box>
          }
          sx={selected ? styles.selected : styles.notSelected}
        />
      </CardActionArea>
      <CardContent sx={{ margin: "10px", padding: 0 }}>
        <Box sx={styles.fields}>
          {isEditing && (
            <Controls.TextInput
              name="title"
              label={`Story Item Title`}
              value={item.title}
              disabled={!isEditing}
              onChange={(event) => handleItemChange(item, event)}
              multiline
            />
          )}
          {!isEditing && (
            <Box sx={{ maxWidth: 350 }}>
              <Typography variant="body2">{item.text}</Typography>
            </Box>
          )}
          {isEditing && (
            <Controls.TextInput
              name="text"
              label={`Story Item Narrative`}
              value={item.text}
              disabled={!isEditing}
              onChange={(event) => handleItemChange(item, event)}
              multiline
            />
          )}
          {isEditing && (
            <>
              <Typography
                style={{ marginTop: "10px" }}
                variant="caption"
                color="textSecondary"
                component={"span"}
              >
                Shade unselected elements
              </Typography>
              <Box sx={styles.additiveSwitch}>
                <Typography variant="caption" component={"span"}>
                  Dimmed
                </Typography>

                <Tooltip title="Should unselected elements by grey, or use their normal diagram color?">
                  <Switch
                    size="small"
                    name="shading"
                    color="primary"
                    checked={item.shading}
                    onChange={(event) =>
                      handleItemChange(item, {
                        target: {
                          name: "shading",
                          value: event.target.checked,
                        },
                      })
                    }
                  />
                </Tooltip>
                <Typography variant="caption" component={"span"}>
                  Normal
                </Typography>
              </Box>
            </>
          )}
          {isEditing && (
            <>
              <Typography
                style={{ marginTop: "10px" }}
                variant="caption"
                color="textSecondary"
                component={"span"}
              >
                Selected Elements
              </Typography>

              <Box sx={styles.additiveSwitch}>
                <Typography variant="caption" component={"span"}>
                  Reset
                </Typography>

                <Tooltip title="Should selected elements by additive to selected elements in the previous story item?">
                  <Switch
                    size="small"
                    name="additive"
                    color="primary"
                    checked={item.additive}
                    onChange={(event) =>
                      handleItemChange(item, {
                        target: {
                          name: "additive",
                          value: event.target.checked,
                        },
                      })
                    }
                  />
                </Tooltip>
                <Typography variant="caption" component={"span"}>
                  Additive
                </Typography>
              </Box>
            </>
          )}
        </Box>
      </CardContent>
    </Card>
  )
}

const StoryItemEditMode = (props) => {
  const { storyItemEditMode, setStoryItemEditMode } = props

  return (
    <ToggleButtonGroup
      color="primary"
      size="small"
      value={storyItemEditMode}
      exclusive
      onChange={(e, newValue) => {
        setStoryItemEditMode(newValue)
      }}
    >
      <ToggleButton value="view" sx={{ textTransform: "none" }}>
        <Tooltip title="Slideshow - Select each story element to highlight the diagram.">
          <SlideshowIcon />
        </Tooltip>
      </ToggleButton>
      <ToggleButton value="edit" sx={{ textTransform: "none" }}>
        <Tooltip title="Edit - Add, remove, and reorder story items.">
          <EditIcon />
        </Tooltip>
      </ToggleButton>
      <ToggleButton value="slide" sx={{ textTransform: "none" }}>
        <Tooltip title="Panorama - Single simple view of all story items.">
          <PanoramaIcon />
        </Tooltip>
      </ToggleButton>
    </ToggleButtonGroup>
  )
}

const StoryItemsSummary = (props) => {
  const { storyItems, setHoveringStoryItemId } = props

  return (
    <Box>
      {storyItems.map((item, index) => (
        <Box
          sx={{
            width: SLIDE_TEXT_WIDTH,
            padding: "2px",
            margin: "2px",
            cursor: "hand",
          }}
          key={item.id}
          onMouseEnter={() => setHoveringStoryItemId(item.id)}
          onMouseLeave={() => setHoveringStoryItemId(undefined)}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Badge
              sx={{
                width: "10px",
                marginRight: "20px",
              }}
              badgeContent={item.seq}
              color="primary"
            />
            <Typography variant="body2" color="textSecondary">
              {item.title}
            </Typography>
          </Box>
          <Box sx={{ marginLeft: "30px" }}>
            <Typography variant="caption">{item.text}</Typography>
          </Box>
        </Box>
      ))}
    </Box>
  )
}

const HighlightStoryWidget = (props) => {
  const { handleClick, selected, item, handleItemChange } = props
  return (
    <Card raised={selected}>
      <CardActionArea onClick={handleClick}>
        <CardHeader title="Highlight" />
      </CardActionArea>
      <CardContent></CardContent>
    </Card>
  )
}

export default StoryEditForm
