import { combineReducers } from "redux"

import projectGridPagination from "./projectGridPagination"
import projectGridPageDocs from "./projectGridPageDocs"
import projectGridSelectedItems from "./projectGridSelectedItems"

import viewSetGridPagination from "./viewSetGridPagination"
import viewSetGridPageDocs from "./viewSetGridPageDocs"

import promptGridPagination from "./promptGridPagination"
import promptGridPageDocs from "./promptGridPageDocs"

import storyPromptGridPagination from "./storyPromptGridPagination"
import storyPromptGridPageDocs from "./storyPromptGridPageDocs"

import storyGridPagination from "./storyGridPagination"
import storyGridPageDocs from "./storyGridPageDocs"
import storyGridSelectedItems from "./storyGridSelectedItems"

import elementGridPagination from "./elementGridPagination"
import elementGridPageDocs from "./elementGridPageDocs"

import accountGridPagination from "./accountGridPagination"
import accountGridPageDocs from "./accountGridPageDocs"
import accountGridSelectedItems from "./accountGridSelectedItems"

import componentGridPagination from "./componentGridPagination"
import componentGridPageDocs from "./componentGridPageDocs"
import componentGridSelectedItems from "./componentGridSelectedItems"

import models from "./models"

import menu from "./menu"

import configMenuOpen from "./configMenuOpen"

import selectedModelFileName from "./selectedModelFileName"

import selectedEnterpriseView from "./selectedEnterpriseView"

import editMode from "./editMode"

import showDiagrams from "./showDiagrams"

import showDashboardPrompts from "./showDashboardPrompts"

import dashboardViewToggles from "./dashboardViewToggles"

import showRules from "./showRules"

import showSuggestedViews from "./showSuggestedViews"

import showMoreDashboard from "./showMoreDashboard"

import modelEditDashboardTab from "./modelEditDashboardTab"

import selectedRules from "./selectedRules"

import selectedTabIndex from "./selectedTabIndex"

import projects from "./projects"
import components from "./components"
import projectModelIndex from "./projectModelIndex"
import componentModelIndex from "./componentModelIndex"

import projectExplorerSettings from "./projectExplorerSettings"
import componentExplorerSettings from "./componentExplorerSettings"

import showDocumentationIndicator from "./showDocumentationIndicator"

import showTooltipsIndicator from "./showTooltipsIndicator"

import autoLegend from "./autoLegend"

import autofitDiagram from "./autofitDiagram"

import diagramScale from "./diagramScale"

import showRuleDetail from "./showRuleDetail"

import chartProjectsByTag from "./chartProjectsByTag"

import mlModels from "./mlModels"
import elementDefinitions from "./elementDefinitions"

export default combineReducers({
  projectGridPagination,
  projectGridPageDocs,
  projectGridSelectedItems,

  viewSetGridPagination,
  viewSetGridPageDocs,

  promptGridPagination,
  promptGridPageDocs,

  storyPromptGridPagination,
  storyPromptGridPageDocs,

  elementGridPagination,
  elementGridPageDocs,

  storyGridPagination,
  storyGridPageDocs,
  storyGridSelectedItems,

  componentGridPagination,
  componentGridPageDocs,
  componentGridSelectedItems,

  accountGridPagination,
  accountGridPageDocs,
  accountGridSelectedItems,

  modelEditDashboardTab,

  models,

  chartProjectsByTag,

  selectedModelFileName,

  selectedEnterpriseView,

  selectedRules,

  showDiagrams,

  showSuggestedViews,

  showDashboardPrompts,

  dashboardViewToggles,

  menu,

  configMenuOpen,

  mlModels,

  showRuleDetail,

  showRules,

  editMode,

  showDocumentationIndicator,

  showTooltipsIndicator,

  showMoreDashboard,

  autoLegend,

  autofitDiagram,

  diagramScale,

  selectedTabIndex,

  // used in the projects and components explorers
  projects,
  components,

  elementDefinitions,

  projectModelIndex,
  componentModelIndex,
  projectExplorerSettings,
  componentExplorerSettings,
})
