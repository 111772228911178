import {
  Alert,
  Box,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  IconButton,
  LinearProgress,
  Rating,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material"
import { useEffect, useMemo, useState } from "react"
import InsightsIcon from "@mui/icons-material/Insights"
import { aiColor } from "../pages/services/colorServices"
import { getRootElements } from "../pages/services/modelEditServices"
import * as palette from "./symbols/palette"
import ModelEditElementTypes from "./ModelEditElementTypes"
import * as modelServices from "../pages/services/modelServices"

const ModelEditDashboardCard = ({
  handleSelectView,
  view,
  viewElementToggles,
  handleGenerateInsightsForSingleView,
  setGeneratingContentMessage,
  generatingMessage,
  aiRole,
  setShowBillingDialog,
}) => {
  const [viewToGenerateInsights, setViewToGenerateInsights] = useState()

  useEffect(() => {
    if (viewToGenerateInsights) {
      console.log(
        "%cgenerate view insights",
        "color:lightGreen",
        viewToGenerateInsights
      )

      if (!aiRole) {
        setShowBillingDialog(true)
        return
      }

      handleGenerateInsightsForSingleView({
        view: viewToGenerateInsights,
      }).then(() => {
        setViewToGenerateInsights(undefined)
        setGeneratingContentMessage(undefined)
      })
    }
  }, [viewToGenerateInsights])

  const isShowProgress = useMemo(() => {
    const result =
      viewToGenerateInsights && viewToGenerateInsights.id === view.id

    if (result) {
      console.log("%cshow progress", "color:yellow", view.name)
    }
    return result
  }, [viewToGenerateInsights, view])

  const viewTags = useMemo(() => {
    const tags = view.elements
      .flatMap((el) => el.props.map((p) => ({ name: p.name, value: p.value })))
      .reduce((acc, curr) => {
        // get a unique list by name + value
        if (!acc.find((a) => a.name === curr.name && a.value === curr.value)) {
          acc.push(curr)
        }
        return acc
      }, [])

    // Array of elements, with a name, and array of values
    const groupedTags = tags.reduce((acc, curr) => {
      const existing = acc.find((a) => a.name === curr.name)
      if (existing) {
        existing.values.push(curr.value)
      } else {
        acc.push({ name: curr.name, values: [curr.value] })
      }
      return acc
    }, [])

    return groupedTags
  })

  return (
    <Card sx={{ width: "400px" }}>
      {isShowProgress && <LinearProgress />}
      <CardHeader
        title={
          <Typography variant="body1" sx={{ fontWeight: "bold", mb: "5px" }}>
            {view.description || view.name}
          </Typography>
        }
        subheader={
          <Stack direction="column" gap={1}>
            <Typography variant="body2" color="text.secondary">
              {view.direct_statement}
            </Typography>
            <Rating value={view.rating || 0} readOnly />
          </Stack>
        }
        sx={{ padding: "10px" }}
      />

      <CardActionArea onClick={(e) => handleSelectView({ viewId: view.id })}>
        <CardContent sx={{ padding: "10px" }}>
          <ViewElementSummary view={view} />
        </CardContent>
      </CardActionArea>
      <Typography
        sx={{ marginLeft: "10px", marginBotton: "10px" }}
        variant="caption"
      >
        Name: {view.name}
      </Typography>

      {viewElementToggles.includes("prompts") && (
        <Stack direction="column" sx={{ p: "10px", mt: "10px" }} gap={1}>
          <Typography variant="body2" fontWeight={"bold"}>
            Prompts
          </Typography>
          {view.prompt_history?.map((prompt) => (
            <Typography color="text.primary" variant="body2" key={prompt}>
              {prompt}
            </Typography>
          ))}
        </Stack>
      )}
      {view.question && (
        <Stack direction="column" sx={{ p: "10px", mt: "10px" }} gap={1}>
          <Typography variant="body2" fontWeight={"bold"}>
            Question
          </Typography>
          <Typography variant="body2">{view.question}</Typography>
        </Stack>
      )}

      {viewElementToggles.includes("properties") && viewTags.length > 0 && (
        <Stack direction="column" sx={{ p: "10px", mt: "10px" }} gap={1}>
          <Typography variant="body2" fontWeight={"bold"}>
            Tags
          </Typography>
          {viewTags.map((tag) => (
            <Box key={tag.name}>
              <Typography variant="body2" color="text.primary">
                {tag.name}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {tag.values.join(", ")}
              </Typography>
            </Box>
          ))}
        </Stack>
      )}

      {!aiRole && view.actions.length > 0 && (
        <Alert severity="info">
          More insights and actions are generated with a full subscription. Go
          to Config | Subscription to upgrade.
        </Alert>
      )}

      {viewElementToggles.includes("insights") &&
        view.actions &&
        view.actions.length > 0 && (
          <Stack direction="column" sx={{ p: "10px", mt: "10px" }} gap={1}>
            <Typography variant="body2" fontWeight={"bold"}>
              Actions
            </Typography>
            {view.actions.map((action, index) => (
              <Box key={action}>
                {index > 0 && <Divider />}
                <Box sx={{ mt: "5px" }}>
                  <Typography variant="body2">{action}</Typography>
                </Box>
              </Box>
            ))}
          </Stack>
        )}

      {viewElementToggles.includes("insights") &&
        view.insights &&
        view.insights.length > 0 && (
          <Stack direction="column" sx={{ p: "10px", mt: "10px" }} gap={1}>
            <Typography variant="body2" fontWeight={"bold"}>
              Insights
            </Typography>
            {view.insights.map((insight, index) => (
              <Box key={insight}>
                <Box sx={{ mb: "5px" }}>
                  <Typography variant="body2">{insight}</Typography>
                </Box>
                <Divider />
              </Box>
            ))}
          </Stack>
        )}

      {viewElementToggles.includes("insights") && view.importance && (
        <Stack direction="column" sx={{ p: "10px", mt: "10px" }} gap={1}>
          <Typography variant="body2" fontWeight={"bold"}>
            Importance
          </Typography>
          <Box sx={{ mb: "5px" }}>
            <Typography variant="body2">{view.importance}</Typography>
          </Box>
        </Stack>
      )}

      {viewElementToggles.includes("how_to_read") && view.how_to_read && (
        <Stack direction="column" sx={{ p: "10px", mt: "10px" }} gap={1}>
          <Typography variant="body2" fontWeight={"bold"}>
            How To Read
          </Typography>
          <Box sx={{ mb: "5px" }}>
            <Typography variant="body2">{view.how_to_read}</Typography>
          </Box>
        </Stack>
      )}

      <CardActions sx={{ justifyContent: "flex-end" }}>
        <Tooltip title="Generate insights and actions">
          <span>
            <IconButton
              onClick={() => {
                setGeneratingContentMessage(
                  "Generating insights and actions..."
                )
                setViewToGenerateInsights(view)
              }}
              disabled={
                Boolean(generatingMessage) || view.elements.length === 0
              }
              sx={{ color: aiColor }}
            >
              <InsightsIcon />
            </IconButton>
          </span>
        </Tooltip>
      </CardActions>
    </Card>
  )
}

const ViewElementSummary = ({ view }) => {
  // If the view has a single top element return its name otherwise undefined
  const singleTopElement = useMemo(() => {
    // Find elements that have >0 children, but are not a child themselves
    const parents = getRootElements({ elements: view.elements })

    if (parents.length === 1) {
      return { name: parents[0].name, type: parents[0].type }
    }
    return undefined
  }, [view])

  // An array of elements, where each element has a type + count attribute
  const elementTypes = useMemo(() => {
    const elementTypes = []
    view.elements.forEach((element) => {
      const elementType = elementTypes.find(
        (elementType) => elementType.type === element.type
      )
      if (elementType) {
        elementType.count++
      } else {
        const elementName = palette.getElementNameByIndex(element.type)
        elementTypes.push({
          type: element.type,
          count: 1,
          symbol: modelServices.getPaletteItem(
            elementName,
            "model edit dashboard"
          ),
          label:
            singleTopElement && singleTopElement.type === element.type
              ? singleTopElement.name
              : palette.formatLabel(elementName),
        })
      }
    })
    return elementTypes
  }, [view.elements, singleTopElement])

  return (
    <Box>
      <ModelEditElementTypes elementTypes={elementTypes} />
    </Box>
  )
}

export default ModelEditDashboardCard
