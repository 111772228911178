import * as actions from "../actionTypes"

const initialState = {
  // 'prompts' not included as default
  value: ["insights", "properties", "how_to_read"],
}

export default function dashboardViewToggles(state = initialState, action) {
  switch (action.type) {
    case actions.SET_DASHBOARD_VIEW_TOGGLES: {
      return {
        ...state,
        value: action.payload.value,
      }
    }

    default:
      return state
  }
}
