// These select names, e.g. componentGridPageDocs, etc,
// are those as defined in index.js for redux\reducers\index.js

export const selectProjectGridPagination = (data) =>
  data.projectGridPagination.value
export const selectProjectGridPageDocs = (data) =>
  data.projectGridPageDocs.value
export const selectProjectGridSelectedItems = (data) =>
  data.projectGridSelectedItems.value

export const selectViewSetGridPagination = (data) =>
  data.viewSetGridPagination.value
export const selectViewSetGridPageDocs = (data) =>
  data.viewSetGridPageDocs.value

export const selectPromptGridPagination = (data) =>
  data.promptGridPagination.value
export const selectPromptGridPageDocs = (data) => data.promptGridPageDocs.value

export const selectStoryPromptGridPagination = (data) =>
  data.storyPromptGridPagination.value
export const selectStoryPromptGridPageDocs = (data) =>
  data.storyPromptGridPageDocs.value

export const selectElementGridPagination = (data) =>
  data.elementGridPagination.value
export const selectElementGridPageDocs = (data) =>
  data.elementGridPageDocs.value

export const selectStoryGridPagination = (data) =>
  data.storyGridPagination.value
export const selectStoryGridPageDocs = (data) => data.storyGridPageDocs.value
export const selectStoryGridSelectedItems = (data) =>
  data.storyGridSelectedItems.value

export const selectComponentGridPagination = (data) =>
  data.componentGridPagination.value

export const selectComponentGridPageDocs = (data) =>
  data.componentGridPageDocs.value

export const selectMenuStatus = (data) => data.menu.value

export const selectConfigMenuOpen = (data) => data.configMenuOpen.value

export const selectShowDiagrams = (data) => data.showDiagrams.value

export const selectShowDashboardPrompts = (data) =>
  data.showDashboardPrompts.value

export const selectDashboardViewToggles = (data) =>
  data.dashboardViewToggles.value

export const selectModelEditDashboardTab = (data) =>
  data.modelEditDashboardTab.value

export const selectShowRuleDetail = (data) => data.showRuleDetail.value

export const selectSelectedRules = (data) => data.selectedRules.value

export const selectShowDocumentationIndicator = (data) =>
  data.showDocumentationIndicator.value

export const selectSelectedEnterpriseView = (data) =>
  data.selectedEnterpriseView.value

export const selectShowTooltipsIndicator = (data) =>
  data.showTooltipsIndicator.value

export const selectShowMoreDashboard = (data) => data.showMoreDashboard.value

export const selectAutoLegend = (data) => data.autoLegend.value

export const selectEditMode = (data) => data.editMode.value

export const selectAutofitDiagram = (data) => data.autofitDiagram.value

export const selectDiagramScale = (data) => data.diagramScale.value

export const selectShowSuggestedViews = (data) => data.showSuggestedViews.value

// Data for the projects chart on the dashboard
export const selectChartProjectsByTag = (data) => data.chartProjectsByTag.value

export const selectAccountGridPagination = (data) =>
  data.accountGridPagination.value

export const selectAccountGridPageDocs = (data) =>
  data.accountGridPageDocs.value

export const selectAccountGridSelectedItems = (data) =>
  data.accountGridSelectedItems.value

export const selectShowRules = (data) => {
  //console.log("%cgetting showRules value", "color:yellow", { data })
  return data.showRules.value
}

export const selectComponentGridSelectedItems = (data) =>
  data.componentGridSelectedItems.value

export const selectModelState = (data) => {
  //console.log('redux data', data)
  return data.models
}

export const selectSelectedModelFileName = (data) => {
  //console.log("%cget selected model file name", "color:yellow", data)
  return data.selectedModelFileName
}

export const selectSelectedTabIndex = (data) => {
  //console.log("get selected tab index", data.selectedTabIndex)
  return data.selectedTabIndex
}

export const selectProjects = (data) => {
  //console.log("%c[redux] selectProjects", "color:lightGreen", { projects: data.projects })
  return data.projects
}

export const selectElementDefinitions = (data) => {
  // console.log(
  //   "%cgetting element definitions",
  //   "color:orange",
  //   data.elementDefinitions
  // )
  return data.elementDefinitions
}

export const selectComponents = (data) => {
  //console.log("%c[redux] selectComponents", "color:lightGreen", { data })
  return data.components
}

export const selectMlModels = (data) => {
  return data.mlModels
}

export const selectProjectModelIndex = (data) => {
  //console.log("%c[redux] selectModelIndex", "color:lightGreen", { data })
  return data.projectModelIndex
}

export const selectComponentModelIndex = (data) => {
  //console.log("%c[redux] selectModelIndex", "color:lightGreen", { data })
  return data.componentModelIndex
}

export const selectProjectExplorerSettings = (data) =>
  data.projectExplorerSettings.value

export const selectComponentExplorerSettings = (data) =>
  data.componentExplorerSettings.value
