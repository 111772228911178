import React from "react"
import { Box, Typography } from "@mui/material"
import { spacing } from "../../pages/services/styleServices"

const styles = {
    heading: {
        marginTop: spacing(4),
        marginBottom: spacing(1),
    },
}

const Heading = (props) => {
    return (
        <Box sx={styles.heading}>
            <Typography variant="body2" color="textSecondary" component={"span"}>
                {props.children}
            </Typography>
        </Box>
    )
}

export default Heading
