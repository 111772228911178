import {
  Document,
  Paragraph,
  TextRun,
  HeadingLevel,
  TableOfContents,
  LevelFormat,
  AlignmentType,
  convertInchesToTwip,
  Table,
  TableRow,
  TableCell,
  WidthType,
  BorderStyle,
} from "docx"
import { colors } from "@mui/material"
import * as modelEditServices from "./modelEditServices"
import * as palette from "../../components/symbols/palette"

const generateDocument = ({ viewSet, views }) => {
  const viewContextParas =
    viewSet.view_context?.flatMap((context) => {
      return [
        new Paragraph({
          children: [
            new TextRun({
              text: `${context.title}`,
              bold: true,
            }),
          ],
          numbering: {
            reference: "my-bullet-points",
            level: 0,
          },
        }),
        new Paragraph({
          text: context.facts || context.rationale,
          indent: {
            left: convertInchesToTwip(0.25),
          },
          spacing: {
            after: 200,
          },
        }),
      ]
    }) || []

  console.log("generateDocument", { viewSet, views })
  const doc = new Document({
    features: {
      updateFields: true,
    },
    numbering: {
      config: [
        {
          reference: "my-bullet-points",
          levels: [
            {
              level: 0,
              format: LevelFormat.BULLET,
              text: "•",
              alignment: AlignmentType.LEFT,
              style: {
                paragraph: {
                  indent: {
                    left: convertInchesToTwip(0.25),
                    hanging: convertInchesToTwip(0.25),
                  },
                },
              },
            },
            {
              level: 1,
              format: LevelFormat.BULLET,
              text: "•",
              alignment: AlignmentType.LEFT,
              style: {
                paragraph: {
                  indent: {
                    left: convertInchesToTwip(0.5),
                    hanging: convertInchesToTwip(0.25),
                  },
                },
              },
            },
          ],
        },
      ],
    },
    styles: {
      paragraphStyles: [
        {
          id: "Title",
          name: "Title",
          basedOn: "Title",
          next: "Normal",
          quickFormat: true,
          run: {
            size: "30pt",
            bold: true,
            //italics: true,
            color: colors.blueGrey[300],
            font: "Aptos",
          },
          paragraph: {
            spacing: {
              after: 120,
            },
          },
        },
        {
          id: "Heading1",
          name: "Heading 1",
          basedOn: "Normal",
          next: "Normal",
          quickFormat: true,
          run: {
            size: "28pt",
            bold: true,
            //italics: true,
            color: colors.blueGrey[800],
            font: "Aptos",
          },
          paragraph: {
            spacing: {
              after: 120,
            },
          },
        },
        {
          id: "Heading2",
          name: "Heading 2",
          basedOn: "Normal",
          next: "Normal",
          quickFormat: true,
          run: {
            size: "18pt",
            bold: true,
            //italics: true,
            color: colors.blueGrey[700],
            font: "Aptos",
          },
          paragraph: {
            spacing: {
              after: 120,
            },
          },
        },
        {
          id: "Heading3",
          name: "Heading 3",
          basedOn: "Normal",
          next: "Normal",
          quickFormat: true,
          run: {
            size: "14pt",
            bold: true,
            //italics: true,
            color: colors.grey[700],
            font: "Aptos",
          },
          paragraph: {
            spacing: {
              before: 120,
              after: 80,
            },
          },
        },
        {
          id: "Heading4",
          name: "Heading 4",
          basedOn: "Normal",
          next: "Normal",
          quickFormat: true,
          run: {
            size: "12pt",
            bold: true,
            color: colors.pink[900],
            font: "Aptos",
          },
          paragraph: {
            spacing: {
              after: 20,
              before: 20,
            },
          },
        },
        // Normal paragraph style in Aptos 11 pt
        {
          id: "Normal",
          name: "Normal",
          basedOn: "Normal",
          next: "Normal",
          run: {
            size: 24,
            font: "Aptos",
          },
          paragraph: {
            spacing: {
              after: 30,
            },
          },
        },
        {
          id: "Props",
          name: "Props",
          basedOn: "Normal",
          next: "Normal",
          run: {
            size: 22,
            font: "Aptos",
          },
          paragraph: {
            spacing: {
              after: 30,
            },
          },
        },
      ],
    },
    sections: [
      {
        properties: {},
        children: [
          new Paragraph({
            text: viewSet.name,
            heading: HeadingLevel.TITLE,
          }),
          new Paragraph({
            text: viewSet.description,
          }),
          new Paragraph({
            text: "Summary",
            heading: HeadingLevel.HEADING_1,
          }),
          new Paragraph({
            text: "Purpose",
            heading: HeadingLevel.HEADING_2,
          }),
          new Paragraph({
            text: viewSet.purpose,
          }),
          new Paragraph({
            text: "Context",
            heading: HeadingLevel.HEADING_2,
          }),
          new Paragraph({
            text: viewSet.overview,
          }),
          new Paragraph({
            pageBreakBefore: true,
          }),
          new TableOfContents("Table Of Contents", {
            hyperlink: true,
            headingStyleRange: "1-5",
          }),
          new Paragraph({
            text: "Views",
            heading: HeadingLevel.HEADING_1,
            pageBreakBefore: true,
          }),
          new Paragraph({
            text: "Questions",
            heading: HeadingLevel.HEADING_2,
          }),
          ...viewSet.view_questions.flatMap((q) => {
            const layer = palette.LAYERS.find((l) => l.name === q.layer)
            //console.log("layer", layer)
            return [
              new Paragraph({
                children: [
                  new TextRun({
                    text: `${q.question}`,
                    bold: true,
                  }),
                  new TextRun({
                    text: `${q.layer ? `    (${q.layer})` : ""}`,
                    color: layer ? layer.colorForPrinting : colors.grey[500],
                    size: 20,
                  }),
                ],
                numbering: {
                  reference: "my-bullet-points",
                  level: 0,
                },
              }),
              new Paragraph({
                text: q.rationale,
                indent: {
                  left: convertInchesToTwip(0.25),
                },
                spacing: {
                  after: 200,
                },
              }),
            ]
          }),
          new Paragraph({
            text: "Context",
            heading: HeadingLevel.HEADING_2,
            spacing: {
              before: 400,
            },
          }),
          ...viewContextParas,
          ...views.flatMap((view) => getView({ view })),

          // new Paragraph({
          //   children: [
          //     new TextRun("Hello World"),
          //     new TextRun({
          //       text: "Foo Bar",
          //       bold: true,
          //     }),
          //     new TextRun({
          //       text: "\tGithub is the best",
          //       bold: true,
          //     }),
          //   ],
          // }),
        ],
      },
    ],
  })

  return doc
}

const getView = ({ view }) => {
  const elementParas = modelEditServices
    .getRootElements({ elements: view.elements })
    .flatMap((element, index) => getElement({ element, view, index }))

  const insights = view.insights.map((insight) => {
    return new Paragraph({
      text: insight,
      numbering: {
        reference: "my-bullet-points",
        level: 0,
      },
      spacing: {
        after: 40,
      },
    })
  })

  if (insights.length === 0) {
    insights.push(
      new Paragraph({
        text: "No insights.",
        numbering: {
          reference: "my-bullet-points",
          level: 0,
        },
        spacing: {
          before: 40,
          after: 40,
        },
      })
    )
  }

  const actions = view.actions.map((action) => {
    // Each action should be bulleted
    return new Paragraph({
      text: action,
      numbering: {
        reference: "my-bullet-points",
        level: 0,
      },
      spacing: {
        before: 40,
        after: 40,
      },
    })
  })

  if (actions.length === 0) {
    actions.push(
      new Paragraph({
        text: "No actions.",
        numbering: {
          reference: "my-bullet-points",
          level: 0,
        },
        spacing: {
          before: 40,
          after: 40,
        },
      })
    )
  }

  const prompts = view.prompt_history.map((prompt) => {
    return new Paragraph({
      text: prompt,
      numbering: {
        reference: "my-bullet-points",
        level: 0,
      },
      spacing: {
        before: 40,
        after: 40,
      },
    })
  })

  if (prompts.length === 0) {
    prompts.push(
      new Paragraph({
        text: "No prompts.",
        numbering: {
          reference: "my-bullet-points",
          level: 0,
        },
        spacing: {
          before: 40,
          after: 40,
        },
      })
    )
  }

  const questions = []

  if (view.question) {
    questions.push(
      new Paragraph({
        text: `This view answers the question: ${view.question}`,
        spacing: {
          before: 100,
        },
      })
    )
  }

  const howToRead = []
  // Add heading and text
  if (view.how_to_read) {
    howToRead.push(
      new Paragraph({
        text: "How to read this view",
        heading: HeadingLevel.HEADING_3,
      }),
      new Paragraph({
        text: view.how_to_read,
      })
    )
  }

  return [
    new Paragraph({
      text: view.name,
      heading: HeadingLevel.HEADING_2,
      pageBreakBefore: true,
    }),
    new Paragraph({
      text: view.direct_statement,
    }),
    ...questions,
    ...howToRead,
    new Paragraph({
      text: "Insights",
      heading: HeadingLevel.HEADING_3,
    }),
    ...insights,
    new Paragraph({
      text: "Actions",
      heading: HeadingLevel.HEADING_3,
    }),
    ...actions,
    new Paragraph({
      text: "Prompts",
      heading: HeadingLevel.HEADING_3,
    }),
    ...prompts,
    new Paragraph({
      text: "Elements",
      heading: HeadingLevel.HEADING_3,
      spacing: {
        before: 100,
      },
    }),
    ...elementParas,
  ]
}

const getElement = ({ element, view, level = 0, index }) => {
  const elementType = palette.getElementTypeByIndex(element.type)
  const layerColor = elementType.layer.colorForPrinting

  const result = [
    // Indent by 1cm per level, and spacing 0.5cm at top
    new Paragraph({
      children: [
        new TextRun({
          text: `${element.name}`,
          bold: true,
        }),
        new TextRun({
          text: `    (${palette.formatLabel(elementType.name)})`,
          color: layerColor,
          size: 20,
        }),
      ],
      spacing: { before: 100 },
      numbering: {
        reference: "my-bullet-points",
        level: level,
      },
    }),
  ]

  if (element.description) {
    // Plain text with the description for the element, in Aptos font
    // Indent by 1cm per level
    result.push(
      new Paragraph({
        text: element.description,
        style: "Normal",
        indent: { left: 300 * level },
        spacing: { before: 100 },
        indent: {
          left: convertInchesToTwip(0.25 + 0.25 * level),
          //hanging: convertInchesToTwip(0.25 * level),
        },
      })
    )
  }

  if (element?.props.length > 0) {
    const cellMargins = {
      top: convertInchesToTwip(0.05),
      right: convertInchesToTwip(0.05),
      bottom: convertInchesToTwip(0.05),
      left: convertInchesToTwip(0.05),
    }
    const table = new Table({
      rows: element.props.map(
        (prop) =>
          new TableRow({
            children: [
              new TableCell({
                children: [new Paragraph({ text: prop.name, style: "Props" })],
                width: {
                  size: 20,
                  type: WidthType.PERCENTAGE,
                },
                margins: cellMargins,
              }),
              new TableCell({
                children: [new Paragraph({ text: prop.value, style: "Props" })],
                width: {
                  size: 20,
                  type: WidthType.PERCENTAGE,
                },
                margins: cellMargins,
              }),
              new TableCell({
                children: [
                  new Paragraph({ text: prop.reason, style: "Props" }),
                ],
                margins: cellMargins,
              }),
            ],
          })
      ),
      width: {
        size: 100, // Optional: adjust the table width as necessary
        type: WidthType.PERCENTAGE,
      },
      indent: {
        size: convertInchesToTwip(0.25 + 0.25 * level), // Apply the indent to the table
        type: WidthType.DXA,
      },
      borders: {
        top: { style: BorderStyle.DOTTED, size: 3, color: "808080" },
        bottom: { style: BorderStyle.DOTTED, size: 3, color: "808080" },
        left: { style: BorderStyle.DOTTED, size: 3, color: "808080" },
        right: { style: BorderStyle.DOTTED, size: 3, color: "808080" },
        insideHorizontal: {
          style: BorderStyle.DOTTED,
          size: 3,
          color: "808080",
        },
        insideVertical: { style: BorderStyle.DOTTED, size: 3, color: "808080" },
      },
    })
    result.push(new Paragraph({ text: "", spacing: { before: 50 } }))
    result.push(table)
    result.push(new Paragraph({ text: "", spacing: { after: 50 } }))
  }

  if (element.children.length > 0) {
    const childIds = element.children.map((c) => c.id)
    const nextLevel = view.elements.filter((x) => childIds.includes(x.id))

    result.push(
      ...nextLevel.flatMap((childElement) =>
        getElement({ element: childElement, view, level: level + 1 })
      )
    )
  }

  return result
}

export { generateDocument }
