import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import TableSortLabel from "@mui/material/TableSortLabel"
import Typography from "@mui/material/Typography"
import TruncatedText from "./TruncatedText"
import Paper from "@mui/material/Paper"
import * as dataServices from "../pages/services/dataServices"
import db from "../Firestore"
import { format } from "date-fns"
import { Box, Tooltip } from "@mui/material"
import firebase from "firebase/compat/app"
import { useHistory } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { selectViewSetGridPagination } from "../redux/selectors"
import { setEditMode, setViewSetGridPagination } from "../redux/actions"
import { selectViewSetGridPageDocs } from "../redux/selectors"
import { setViewSetGridPageDocs } from "../redux/actions"
import Controls from "./controls/Controls"
import { spacing } from "../pages/services/styleServices"
import StyledLink from "./controls/StyledLink"
import PageNo from "./PageNo"
import ViewSetDialog from "./ViewSetDialog"

const headCells = [
  {
    id: "name",
    numeric: false,
    disablePadding: true,
    label: "Design",
    sortable: true,
  },
  {
    id: "purpose",
    numeric: false,
    disablePadding: false,
    label: "Purpose",
    sortable: false,
  },
  {
    id: "modified",
    numeric: false,
    disablePadding: false,
    label: "Modified",
    sortable: true,
  },
]

const ViewSetGrid = ({ order, orderBy, onRequestSort }) => {
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property)
  }

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {headCell.sortable ? (
              <TableSortLabel
                active={headCell.sortable && orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                <Typography fontWeight="bold">{headCell.label}</Typography>
              </TableSortLabel>
            ) : (
              <Typography fontWeight="bold">{headCell.label}</Typography>
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

ViewSetGrid.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
}

const styles = {
  root: {
    width: "100%",
    marginTop: spacing(2),
  },
  paper: {
    width: "100%",
    marginBottom: spacing(2),
  },
  table: {
    minWidth: 750,
  },
  navButtons: {
    display: "flex",
    flexDirection: "row",
    gap: 1,
  },
}

export default function EnhancedTable() {
  // 'next', or 'prev'. Used to inform pagination logic
  const [direction, setDirection] = useState("")

  const [dense, setDense] = React.useState(true)

  const pag = useSelector(selectViewSetGridPagination)
  const [pagination, setPagination] = useState(pag)

  const dispatch = useDispatch()

  const history = useHistory()

  const [viewSetDialogOpen, setViewSetDialogOpen] = useState(false)

  const [rowsPerPage, setRowsPerPage] = React.useState(20)

  const [viewSets, setViewSets] = useState([])

  const [accountId, setAccountId] = useState()

  const [accountType, setAccountType] = useState()

  useEffect(() => {
    const unsub = firebase.auth().onAuthStateChanged((user) => {
      if (user !== null) {
        user.getIdTokenResult(false).then((token) => {
          setAccountId(token.claims.account_id)
          setAccountType(token.claims.account_type)
        })
      }
    })

    return unsub
  }, [])

  const pgDocs = useSelector(selectViewSetGridPageDocs)
  const [pageDocs, setPageDocs] = useState(pgDocs)

  const COLLECTION_NAME = "view_sets"

  // Listen for changes

  useEffect(() => {
    if (accountType === undefined || accountId === undefined) {
      return
    }

    const whereClauseLog = []

    let query = db
      .collection(COLLECTION_NAME)
      .where("modified", ">=", dataServices.localTimestamp())
      .orderBy("modified", "desc")

    whereClauseLog.push(
      "modified >= " +
        dataServices.localTimestamp() +
        " [" +
        dataServices.localTimestamp().toDate() +
        "]"
    )

    switch (accountType) {
      case "client":
        query = query.where("account_id", "==", accountId)
        whereClauseLog.push("account_id == " + accountId)
        break

      default:
        throw new Error("Unknown account type " + accountType)
    }

    const unsub = query.onSnapshot((querySnapshot) => {
      querySnapshot.docChanges().forEach((change) => {
        // Add new purpose field in case it doesn't exist
        const newViewSet = {
          id: change.doc.id,
          purpose: "",
          ...change.doc.data(),
        }
        switch (change.type) {
          case "added":
            setViewSets((curr) => {
              const newViewSets = [...curr]
              const index = newViewSets.findIndex(
                (job) => job.id === change.doc.id
              )
              if (index === -1) {
                newViewSets.push(newViewSet)
              } else {
                newViewSets[index] = newViewSet
              }
              return newViewSets
            })

            break

          case "modified":
            // If the view set is not in the list then add it to the list.
            // If it is in the list then replace it with the updated view set.

            setViewSets((curr) => {
              const newViewSets = [...curr]
              const index = newViewSets.findIndex(
                (job) => job.id === change.doc.id
              )
              if (index === -1) {
                newViewSets.push(newViewSet)
              } else {
                newViewSets[index] = newViewSet
              }
              return newViewSets
            })

            break

          case "removed":
            setViewSets((curr) => {
              const newViewSets = [...curr]
              const index = newViewSets.findIndex(
                (vs) => vs.id === change.doc.id
              )
              newViewSets.splice(index, 1)
              return newViewSets
            })
            break

          default:
            throw new Error("Unknown change type " + change.type)
        }
      })
    })

    return unsub
  }, [accountType, accountId])

  const updatePageDocs = () => {
    if (viewSets.length > 0 && direction !== "prev") {
      const newPageDocs = [...pageDocs]

      const newPageDoc = {
        first: viewSets[0].doc,
        last: viewSets[viewSets.length - 1].doc,
      }

      newPageDocs[pagination.page] = newPageDoc
      setPageDocs(newPageDocs)
      dispatch(setViewSetGridPageDocs(newPageDocs))
    }
  }

  useEffect(() => {
    updatePageDocs(viewSets)
  }, [viewSets])

  // Load view sets

  useEffect(() => {
    if (accountType === undefined || accountId === undefined) {
      return
    }

    const queryMods = []

    let query = db.collection(COLLECTION_NAME)

    switch (accountType) {
      case "client":
        query = query.where("account_id", "==", accountId)
        queryMods.push("where account_id == " + accountId)
        break

      default:
        throw new Error("Unknown account type " + accountType)
    }

    // Get current page of view sets

    query = query.orderBy(pagination.orderBy, pagination.order)
    queryMods.push("order by " + pagination.orderBy + " " + pagination.order)
    query = query.orderBy(
      firebase.firestore.FieldPath.documentId(),
      pagination.order
    )
    queryMods.push("order by doc id " + pagination.order)

    if (pagination.page > 0 && direction !== "prev") {
      // Use pageDocs if available, i.e. if we've gone forward, then back, then forward again through collection.
      // But if not found, it just means this is the first time we've clicked Next through the collection
      if (pageDocs[pagination.page - 1]) {
        const lastDoc = pageDocs[pagination.page - 1].last

        query = query.startAfter(lastDoc)
        queryMods.push("start after last doc on previous page " + lastDoc.id)
      }
    } else if (direction === "prev") {
      if (!pageDocs[pagination.page]) {
        console.error("Cant find pagedocs for page", pagination.page)
      }
      query = query.startAt(pageDocs[pagination.page].first)
      queryMods.push(
        "start at 1st doc on page " + pageDocs[pagination.page].first.id
      )
    }

    console.log("queryMods", queryMods)
    console.groupEnd()

    query = query.limit(rowsPerPage)

    dataServices
      .loadData("(Load design grid)", query)
      .then((viewSets) => {
        // Add new purpose field in case it doesn't exist
        const newViewSets = viewSets.map((viewSet) => ({
          purpose: "",
          ...viewSet,
        }))
        setViewSets(newViewSets)
      })
      .then(updatePageDocs())

    console.groupEnd()
  }, [accountId, accountType, pagination])

  const handleRequestSort = (event, property) => {
    const isAsc = pagination.orderBy === property && pagination.order === "asc"

    const updatedPagination = {
      tags: [],
      ...pagination,
      page: 0,
      order: isAsc ? "desc" : "asc",
      orderBy: property,
    }

    setPagination(updatedPagination)
    dispatch(setViewSetGridPagination(updatedPagination))

    setPageDocs([])
    dispatch(setViewSetGridPageDocs([]))

    setDirection("")
  }

  const handleNewViewSet = (event) => {
    event.preventDefault()
    dispatch(setEditMode(true))
    setViewSetDialogOpen(true)
  }

  const handlePageNav = (pageChange) => {
    const newPage = pagination.page + pageChange
    if (newPage >= 0) {
      setDirection(pageChange === 1 ? "next" : "prev")

      const updatedPagination = {
        ...pagination,
        page: newPage,
      }

      setPagination(updatedPagination)
      dispatch(setViewSetGridPagination(updatedPagination))
    }
  }

  const handleViewSetDialogClose = (viewSet) => {
    setViewSetDialogOpen(false)

    if (viewSet) {
      console.log("design", { viewSet, accountId })
      const { values } = viewSet

      if (values?.name) {
        console.log("%chandleViewSetDialogClose", "color:pink", {
          name: values.name,
          accountId,
        })
        db.collection("view_sets")
          .add({
            name: values.name,
            description: values.description || "",
            purpose: values.purpose || "",
            scope: values.scope,
            overview: values.overview || "",
            account_id: accountId,
            created: firebase.firestore.FieldValue.serverTimestamp(),
            modified: firebase.firestore.FieldValue.serverTimestamp(),
          })
          .then((docRef) => {
            console.log("Document written with ID: ", docRef.id)
            setViewSetDialogOpen(false)
            history.push(`/modeledit/${docRef.id}`)
          })
      }
    }
  }

  return (
    <>
      {viewSetDialogOpen && (
        <ViewSetDialog
          open={viewSetDialogOpen}
          onClose={handleViewSetDialogClose}
          viewSet={{ account_id: accountId }}
        />
      )}

      <Box sx={styles.root}>
        <Paper sx={styles.paper}>
          <TableContainer>
            <Table
              sx={styles.table}
              aria-labelledby="tableTitle"
              size={dense ? "small" : "medium"}
              aria-label="Designs"
            >
              <ViewSetGrid
                numSelected={0}
                selected={[]}
                viewSets={viewSets}
                order={pagination.order}
                orderBy={pagination.orderBy}
                onRequestSort={handleRequestSort}
                rowCount={viewSets.length}
              />
              <TableBody>
                {viewSets.map((row, index) => {
                  const labelId = `enhanced-table-checkbox-${index}`

                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                      <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        padding="none"
                        width="250px"
                      >
                        <StyledLink
                          to={"/modeledit/" + row.id}
                          onClick={() => dispatch(setEditMode(false))}
                        >
                          <Box sx={{ width: "240px" }}>
                            <TruncatedText>{row.name}</TruncatedText>
                          </Box>
                        </StyledLink>
                      </TableCell>

                      <TableCell>
                        <Tooltip title={row.purpose}>
                          <Box sx={{ width: "600px" }}>
                            <TruncatedText>{row.purpose}</TruncatedText>
                          </Box>
                        </Tooltip>
                      </TableCell>

                      <TableCell align="left">
                        <Typography
                          variant="body2"
                          noWrap={true}
                          component={"span"}
                        >
                          {format(row.modified.toDate(), "dd-M-yy")}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
        <Box sx={styles.navButtons}>
          <Controls.Button
            size="small"
            disabled={pagination.page === 0}
            onClick={() => handlePageNav(-1)}
            text="Prev"
          />

          <Controls.Button
            size="small"
            disabled={viewSets.length < rowsPerPage}
            onClick={() => handlePageNav(1)}
            text="Next"
          />

          <Controls.Button
            size="small"
            onClick={(event) => handleNewViewSet(event)}
            text="New Design"
            variant="contained"
            tooltip="Create a new view set"
          />
        </Box>
        <PageNo pageNo={pagination.page + 1} />
      </Box>
    </>
  )
}
