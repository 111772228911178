import xml2js from "xml2js"
import * as palette from "../../components/symbols/palette"
import _ from "lodash"
import dateFnsParse from "date-fns/parse"
import { format } from "date-fns"
import { Typography } from "@mui/material"
import * as colors from "@mui/material/colors"
import * as xlsx from "xlsx"
import moment from "moment"

const s4 = () => {
  return Math.floor((1 + Math.random()) * 0x10000)
    .toString(16)
    .substring(1)
}

const guid = () => {
  return s4() + s4() + s4() + s4() + s4() + s4() + s4() + s4()
}

const NAME_COL = "Name"
const TYPE_COL = "Type"
const PARENT_COL = "Parent"
const DESCRIPTION_COL = "Description"

const getShaderOptions = ({ shaders, views, currentView }) => {
  if (shaders === undefined) return []
  if (views === undefined) return []

  //console.log("search views", { views })

  // See which shaders are used in the current view and add them to the list of shaders
  const usedShadersInViewSet = shaders
    .filter((shader) => {
      return views.find((view) =>
        view.elements.some((element) =>
          element.props.find(
            (p) => p.name.toLowerCase() === shader.property.toLowerCase()
          )
        )
      )
    })
    .map((shader) => shader.id)

  const usedShadersInCurrentView = currentView
    ? shaders
        .filter((shader) => {
          return currentView.elements.some((element) =>
            element.props.find(
              (p) => p.name.toLowerCase() === shader.property.toLowerCase()
            )
          )
        })
        .map((shader) => shader.id)
    : []

  const result = shaders
    .sort((a, b) => a.name.localeCompare(b.name))
    .map((shader) => ({
      id: shader.id,
      title: shader.name,
      used: usedShadersInViewSet.includes(shader.id),
      usedInCurrentView: usedShadersInCurrentView.includes(shader.id),
    }))

  return result
}

const getShaderOptionsForCurrentView = ({ shaders, currentView }) => {
  const usedShadersInCurrentView = currentView
    ? shaders
        .filter((shader) => {
          return currentView.elements.some((element) =>
            element.props.find(
              (p) => p.name.toLowerCase() === shader.property.toLowerCase()
            )
          )
        })
        .map((shader) => shader.id)
    : []

  const result = shaders
    .sort((a, b) => a.name.localeCompare(b.name))
    .filter((shader) => usedShadersInCurrentView.includes(shader.id))
    .map((shader) => ({
      id: shader.id,
      title: shader.name,
      used: true,
      usedInCurrentView: true,
    }))
  return result
}

const getAllSiblings = (item, elements) => {
  // Get 1-up of item

  const parent = elements.find((element) =>
    element.children.find((child) => child.id === item.id)
  )

  if (!parent) {
    // We are at the top, so siblings are all root elements
    const siblings = elements.filter((element) => element.children.length === 0)
    return siblings.filter((s) => s.name !== item.name && s.type === item.type)
  } else {
    return parent.children
      .map((child) => elements.find((element) => element.id === child.id))
      .filter((s) => s && s.name !== item.name && s.type === item.type)
  }
}

// Get each 1-up till we reach the root element
const getParentContext = ({ item, elements }) => {
  const parents = [item]

  while (true) {
    // Get current parent at top of list

    const prevParent = parents[0]
    const parent = elements.find((element) =>
      element.children.find((child) => child.id === prevParent.id)
    )
    if (parent) {
      // Add parent to front of parents
      parents.unshift(parent)
    } else {
      break
    }
  }

  // Delete last element, so we only have parents
  parents.pop()

  return parents
}

const getLegendLayout = (shader, width) => {
  const maxLabelLength = shader.config.colors.reduce((acc, cur) => {
    return cur.value.length > acc ? cur.value.length : acc
  }, 0)

  //console.log('maxLabelLength', shader.property, maxLabelLength)

  const labelWidth = Math.max(70, maxLabelLength * 12)

  let lineCount = Math.ceil((labelWidth * shader.config.colors.length) / width)

  let itemsPerLine = Math.ceil(shader.config.colors.length / lineCount)

  if (itemsPerLine < shader.config.colors.length) {
    itemsPerLine--
  }

  if (itemsPerLine * lineCount < shader.config.colors.length) {
    lineCount++
  }

  return {
    property: shader.property.toLowerCase(),
    lineCount: lineCount,
    itemsPerLine: itemsPerLine,
    xOffset: labelWidth,
  }
}

const getUniqueColumnValues = (workbook, sheetName, columnIndex) => {
  const sheet = workbook.Sheets[sheetName]

  const range = xlsx.utils.decode_range(sheet["!ref"])

  const values = []

  for (let R = range.s.r; R <= range.e.r; ++R) {
    const cell = sheet[xlsx.utils.encode_cell({ c: columnIndex, r: R })]

    // Skip the header row
    if (R > 0) {
      // If the cell contains a comma separated list of values, split them and add them to the values array
      if (cell) {
        if (typeof cell.v === "string") {
          const cellValues = cell.v.toString().split(",")
          cellValues.forEach((value) => values.push(value.trim()))
        } else {
          values.push(cell.v)
        }
      }
    }
  }

  return _.uniq(values).sort()
}

const isRoot = (item, elements) =>
  elements.find((x) => x.children.find((y) => y.id === item.id)) === undefined

const handleMoveElementLeft = ({ elements, selectedItemId }) => {
  const parentElement = elements.find((x) =>
    x.children.find((y) => y.id === selectedItemId)
  )

  if (parentElement) {
    // Find the child array position of the current element in the parent element

    const childArrayPosition = parentElement.children.findIndex(
      (x) => x.id === selectedItemId
    )

    // Find the child elements that occur after the child array position
    const childElementsBefore = parentElement.children.slice(
      0,
      childArrayPosition
    )

    const previousChildElement = elements.find((x) =>
      childElementsBefore.find((y) => y.id === x.id)
    )

    if (previousChildElement) {
      const children = parentElement.children
      const selectedChild = children.find((x) => x.id === selectedItemId)
      const swappedChildren = children.filter((x) => x.id !== selectedItemId)
      swappedChildren.splice(childArrayPosition - 1, 0, selectedChild)

      const newElements = elements.map((x) => {
        if (x.id === parentElement.id) {
          return {
            ...x,
            children: swappedChildren,
          }
        } else {
          return x
        }
      })

      return newElements
    }
  } else {
    // Find element with no children (root element) that has an array position before current element

    const pos = elements.findIndex((x) => x.id === selectedItemId)

    let prev = pos - 1
    for (; prev >= 0; prev--) {
      if (isRoot(elements[prev], elements)) {
        break
      }
    }

    if (prev >= 0) {
      const newElements = [...elements]

      const temp = newElements[pos]
      newElements[pos] = newElements[prev]
      newElements[prev] = temp

      return newElements
    }
  }
}

const handleMoveElementRight = ({ elements, selectedItemId }) => {
  const parentElement = elements.find((x) =>
    x.children.find((y) => y.id === selectedItemId)
  )

  if (parentElement) {
    // Find the child array position of the current element in the parent element

    const childArrayPosition = parentElement.children.findIndex(
      (x) => x.id === selectedItemId
    )

    // Find the child elements that occur after the child array position

    const childElementsAfter = parentElement.children.slice(
      childArrayPosition + 1
    )

    const nextChildElement = elements.find((x) =>
      childElementsAfter.find((y) => y.id === x.id)
    )

    if (nextChildElement) {
      const children = parentElement.children

      // Swap the current element with the next child element

      const swappedChildren = [
        ...children.slice(0, childArrayPosition),
        ...children.slice(childArrayPosition + 1, childArrayPosition + 2),
        ...children.slice(childArrayPosition, childArrayPosition + 1),
        ...children.slice(childArrayPosition + 2),
      ]

      const newElements = elements.map((x) => {
        if (x.id === parentElement.id) {
          return {
            ...x,
            children: swappedChildren,
          }
        } else {
          return x
        }
      })

      return newElements
    }
  } else {
    // Find element with no children (root element) that has an array position after current element

    const pos = elements.findIndex((x) => x.id === selectedItemId)

    let next = pos + 1
    for (; next < elements.length; next++) {
      if (isRoot(elements[next], elements)) {
        break
      }
    }

    if (next < elements.length) {
      const newElements = [...elements]

      const temp = newElements[pos]
      newElements[pos] = newElements[next]
      newElements[next] = temp

      return newElements
    }
  }
}

const addMissingIdsToProperties = (properties) => {
  const maxPropId = properties.reduce((max, p) => (p.id > max ? p.id : max), 0)

  const newProps = properties.map((x, i) => {
    if (x.id === undefined) {
      return {
        ...x,
        id: maxPropId + i + 1,
      }
    } else {
      return x
    }
  })

  return newProps
}

const getMaxPropId = (item) => {
  const maxId = item.props.reduce((max, p) => (p.id > max ? p.id : max), 0)

  return maxId
}

// Find all leaf nodes for the element
const getLeafNodesOfElement = (element, view) => {
  const leafNodes = element.children.length === 0 ? [element] : []

  element.children.forEach((child) => {
    const childElement = view.elements.find((x) => x.id === child.id)
    leafNodes.push(...getLeafNodesOfElement(childElement, view))
  })

  return leafNodes
}

const getLeafNodesOfView = (view) => {
  const leafNodes = view.elements.filter(
    (element) => element.children.length === 0
  )

  return leafNodes
}

const addMissingPropIds = (item) => {
  const maxPropId = getMaxPropId(item)

  const newProps = item.props.map((x, i) => {
    if (x.id === undefined) {
      return {
        ...x,
        id: maxPropId + i + 1,
      }
    } else {
      return x
    }
  })

  return {
    ...item,
    props: newProps,
  }
}

const handleDownloadElementsAsXlsx = (elements) => {
  const propKeys = elements
    .filter((element) => element.props.length > 0)
    .flatMap((element) => element.props.map((p) => p.name))

  const uniqueKeys = _.uniq(propKeys).sort()

  const ws = xlsx.utils.json_to_sheet(
    elements.map((element) => {
      const propCols = uniqueKeys.reduce((acc, key, index) => {
        const props = element.props.filter((p) => p.name === key)
        if (props.length > 0) {
          acc[`P:${key}`] = props.map((p) => p.value).join(", ")
        }
        return acc
      }, {})

      return {
        Name: element.name,
        Type: palette.formatLabel(palette.getElementNameByIndex(element.type)),
        Parent: elements.find((x) =>
          x.children.find((y) => y.id === element.id)
        )?.name,
        Description: element.description,
        ...propCols,
      }
    })
  )

  // Add worksheet ws to a workbook
  const wb = xlsx.utils.book_new()
  xlsx.utils.book_append_sheet(wb, ws, "Elements")

  // Download a .xlsx file
  xlsx.writeFile(wb, "model.xlsx")
}

// Replace the 'type' attribute with the type index, and recursively go through the 'children' array
const replaceTypeNameWithTypeIndex = (elements) => {
  const newElements = elements.map((element) => {
    const newElement = {
      ...element,
      type: palette.getIndex(element.type),
      children:
        (element.children && replaceTypeNameWithTypeIndex(element.children)) ||
        [],
    }
    return newElement
  })

  return newElements
}

const setUniqueId = (props, prop) => {
  // Get the max id attribute from the prop with name = prop.name and value = prop.value

  const maxId = props.reduce((acc, curr) => {
    return Math.max(acc, curr.id)
  }, 0)

  // Update the prop with name = propName to have maxId + 1

  const newProps = props.map((x) => {
    if (x.name === prop.name && x.value === prop.value) {
      return { ...x, id: maxId + 1 }
    } else {
      return x
    }
  })

  return newProps
}

const toggleSingleProp = (item, prop) => {
  const hasPropWithSameName =
    item.props.find((p) => p.name === prop.name) !== undefined

  const hasPropWithSameNameAndValue =
    item.props.find((p) => p.name === prop.name && p.value === prop.value) !==
    undefined

  const propsToKeep = item.props.filter((p) => p.name !== prop.name)

  const newProps = hasPropWithSameNameAndValue
    ? item.props.filter(
        (p) => !(p.name === prop.name && p.value === prop.value)
      )
    : hasPropWithSameName
    ? [...item.props, prop]
    : [...propsToKeep, prop]

  return setUniqueId(newProps, prop)
}

const handleToggleProperty = (item, prop, currentView, views) => {
  const duplicates = getDuplicateElementsByView(currentView, item, views)

  const allItemsToUpdate = [{ ...item, viewId: currentView.id }, ...duplicates]

  const newViews = views.map((view) => {
    const newView = {
      ...view,
      elements: view.elements
        .map((element) => {
          const requiresUpdate = allItemsToUpdate.find(
            (x) => x.id === element.id && x.viewId === view.id
          )
          return requiresUpdate
            ? { ...element, props: toggleSingleProp(element, prop) }
            : element
        })
        .filter((element) => element.type !== null),
    }
    return newView
  })

  return newViews
}

/**
 *
 * @param {*} views
 * @param {*} propNames
 * @param {*} elementType The id of the archimate element type
 * @returns
 */
const getUniquePropValues = (views, propNames, elementType) => {
  //FIXME: This method gets called to much. Uncomment and debug why
  //console.log("getting unique prop values", { views, propNames, elementType })
  const propOptions = propNames.map((propName) => {
    const propValues = views
      .map((view) => view.elements)
      .flat()
      .filter((element) => (element.type ? element.type === elementType : true))
      .map((element) => element.props)
      .flat()
      .filter((prop) => prop.name === propName && prop.value !== "")
      .map((prop) => prop.value)
      .sort((a, b) => a.localeCompare(b))
    const uniquePropValues = [...new Set(propValues)]
    return { name: propName, values: uniquePropValues }
  })

  return propOptions
}

const getAvailablePropertyValuesForElement = (element, views) => {
  // Get unique prop names for 'element'
  const propNames = element?.props?.map((prop) => prop.name) || []
  const uniquePropNames = [...new Set(propNames)]

  // Find all distinct values for prop names in all views
  return getUniquePropValues(views, uniquePropNames, element.type)
}

const handleImportDataWithHierarchy = ({
  elements,
  workbook,
  sheetName,
  hierarchyCols,
  hierarchyTypes,
}) => {
  console.log("handleImportDataWithHierarchy", {
    workbook,
    sheetName,
    hierarchyCols,
    hierarchyTypes,
  })

  const hierarchyTypeIds = hierarchyTypes.map((type) => palette.getIndex(type))

  const sheet = workbook.Sheets[sheetName]
  const worksheetInfo = getWorksheetInfo(workbook, sheetName)
  const rows = getWorksheetRows(sheet)

  // Create a hierarchy of elements from the rows using hierarchyCols and hierarchyTypes
  // Start numbering at 1 if no id values used so far, since 0 is equivalent to null or undefined
  let nextId = Math.max(1, getMaxElementId(elements))

  const hierarchyColIndexes = hierarchyCols.map((col) =>
    worksheetInfo.headings.indexOf(col)
  )

  const newElements = []

  rows.forEach((row, index) => {
    hierarchyColIndexes.forEach((colIndex, hierarchyIndex) => {
      const name = row[colIndex]
      const type = hierarchyTypeIds[hierarchyIndex]

      //const description = row[worksheetInfo.descriptionIndex] || ""

      const existingElement = newElements.find(
        (x) => x.name === name && x.type === type
      )

      if (!existingElement) {
        const newElement = {
          id: nextId++,
          name,
          type: type,
          description: "",
          props: [],
          children: [],
        }
        newElements.push(newElement)

        // Check if existing element needs to be added as a child to the previous level in the hierarchy

        if (hierarchyIndex > 0) {
          const parentColIndex = hierarchyColIndexes[hierarchyIndex - 1]
          const parentName = row[parentColIndex]
          const parentType = hierarchyTypeIds[hierarchyIndex - 1]

          const parentElement = newElements.find(
            (x) => x.name === parentName && x.type === parentType
          )

          if (parentElement) {
            parentElement.children.push({
              id: newElement.id,
              connector: palette.AGGREGATION_RELATIONSHIP,
            })
          }
        }
      }
    })
  })

  return newElements
}

/**
 *
 * @param {*} items | Items that have a numeric seq attribute, for which we want to find the max value
 * @returns
 */
const findMaxSeq = (items) => {
  let maxSeq = 0

  items.forEach((item) => {
    if (item.seq > maxSeq) {
      maxSeq = item.seq
    }
  })

  return maxSeq
}

const getColumnTypes = (sheet) => {
  const worksheetRange = getWorksheetRange(sheet)

  const range = xlsx.utils.decode_range(sheet["!ref"])

  const columnTypes = []

  for (let C = range.s.c; C <= worksheetRange.col.to; ++C) {
    const cell = sheet[xlsx.utils.encode_cell({ c: C, r: 1 })]
    if (cell) {
      // See if cell is date

      let type = cell.t

      // Regexp to match a date in the format 01-Jan-23

      const dateRegexps = [
        // 01-Jan-23
        /^[0-9]{2}-[a-zA-Z]{3}-[0-9]{2}$/,
        // 01-Jan-2023
        /^[0-9]{2}-[a-zA-Z]{3}-[0-9]{4}$/,
        // 23-10-2021
        /^[0-9]{2}-[0-9]{2}-[0-9]{4}$/,
        // 2021-10-23
        /^[0-9]{4}-[0-9]{2}-[0-9]{2}$/,
        // 01/Jan/23
        /^[0-9]{2}\/[a-zA-Z]{3}\/[0-9]{2}$/,
        // 01/Jan/2023
        /^[0-9]{2}\/[a-zA-Z]{3}\/[0-9]{4}$/,
      ]

      if (cell.w && dateRegexps.find((regexp) => cell.w.match(regexp))) {
        type = "d"
      }

      columnTypes.push(type)
    } else {
      columnTypes.push("s") // default to string
    }
  }

  // Return AIM codes for column types
  const convertedColumnTypes = columnTypes.map((x) => {
    if (x === "n") {
      return "number"
    } else if (x === "d") {
      return "date"
    } else {
      return "text"
    }
  })

  return convertedColumnTypes
}

const getWorksheetInfo = (workbook, sheetName, includePropValues = false) => {
  const sheet = workbook.Sheets[sheetName]

  const range = xlsx.utils.decode_range(sheet["!ref"])

  console.log("%csheet", "color:lightgreen", { sheet, range })

  const headings = []

  for (let C = range.s.c; C <= range.e.c; ++C) {
    const cell = sheet[xlsx.utils.encode_cell({ c: C, r: range.s.r })]
    headings.push(cell ? cell.v : undefined)
  }

  const nameIndex = headings.findIndex((x) => x === "Name")
  const typeIndex = headings.findIndex((x) => x === "Type")
  const parentIndex = headings.findIndex((x) => x === "Parent")
  const descriptionIndex = headings.findIndex((x) => x === "Description")

  // Get property heading info being the heading name and column index where a property heading is prefixed with "P:"

  const props = headings
    .map((heading, index) => {
      if (heading && heading.startsWith("P:")) {
        const result = { name: heading.substring(2).trim(), index }

        if (includePropValues) {
          const values = getUniqueColumnValues(workbook, sheetName, index)
          result.values = values
        }
        return result
      } else {
        return undefined
      }
    })
    .filter((heading) => heading)

  return {
    nameIndex,
    typeIndex,
    parentIndex,
    descriptionIndex,
    headings,
    props,
  }
}

const getChildElements = (currentView, selectedItemId) => {
  const selectedElement = currentView.elements.find(
    (x) => x.id === selectedItemId
  )
  const promptElements = [selectedElement]
  let prevLevel = [selectedElement]
  while (true) {
    const nextChildIds = prevLevel.flatMap((x) =>
      x.children.flatMap((c) => c.id)
    )
    const nextLevel = currentView.elements.filter((x) =>
      nextChildIds.includes(x.id)
    )
    prevLevel = nextLevel

    promptElements.push(...nextLevel)

    if (nextLevel.length === 0) {
      break
    }
    prevLevel = nextLevel
  }

  return promptElements
}

const getMaxElementId = (elements) => {
  let maxId = 0
  elements.forEach((element) => {
    if (element.id > maxId) {
      maxId = element.id
    }
  })
  return maxId
}

const createGroupings = (props) => {
  const groupings = props.map((p) => ({
    name: `${p.name} | ${p.value}`,
    id: undefined, // to be set later
    type: palette.getIndex(palette.GROUPING),
    children: [], // to be set later
    props: [],

    // Used temporarily so we can place elements under their hierarchy
    propUsed: p,
  }))

  return groupings
}

const getRootElements = ({ elements }) => {
  const rootElements = elements.filter((el) => {
    return !elements.some((el2) => {
      return el2.children.some((c) => {
        return c.id === el.id
      })
    })
  })

  return rootElements
}

/**
 *
 * @param {*} currentView The view to use as the basis for the grouped view
 * @param {*} propName An array of property names to use for grouping
 * @param {*} elementType The element types to include in the grouped view
 * @param {*} useTopLevel If true, the top level of the hierarchy is kept, otherwise it is discarded
 * @param {*} selectedItemId The id of the selected item. If undefined use all elements
 */
const createGroupedView = ({
  currentView,
  propNames,
  sortedPropValues,
  elementTypes,
  useTopLevel,
  selectedItemId,
}) => {
  // Get top level. This is elements that do not appear as children in any other elements

  const selectedItem = currentView.elements.find((x) => x.id === selectedItemId)

  const rootElements = getRootElements({ elements: currentView.elements })

  const topElements = selectedItemId
    ? [selectedItem]
    : rootElements.map((el) => ({ ...el, children: [] }))

  const elementsToUse = topElements
    .flatMap((el) => getChildElements(currentView, el.id))
    .map((el) => ({ ...el, children: [] }))

  let nextId = getMaxElementId(elementsToUse) + 1

  const propNamesLower = propNames.map((p) => p.toLowerCase())
  const uniqueProps = _.uniqWith(
    elementsToUse.flatMap((el) => el.props),
    (a, b) => a.name === b.name && a.value === b.value
  ).filter((p) => propNamesLower.includes(p.name.toLowerCase()))

  const hierarchy = []

  // If useTopLevel, then add top level elements to hierarchy

  if (useTopLevel) {
    hierarchy.push(...topElements.map((el) => ({ ...el, children: [] })))
  }

  // Add prop names to hierarchy

  const propGroups = _.groupBy(uniqueProps, (p) => p.name)

  propNames.forEach((propName) => {
    const props = propGroups[propName] || []

    const leafNodes = hierarchy.filter((el) => el.children.length === 0)

    if (leafNodes.length === 0 && hierarchy.length === 0) {
      const groupings = createGroupings(props).map((el) => ({
        ...el,
        id: nextId++,
      }))

      sortGroupings({ sortedPropValues, propName, groupings })

      hierarchy.push(
        ...groupings.map((el) => ({
          id: el.id,
          name: el.name,
          type: el.type,
          children: [],
          props: [],
          propUsed: el.propUsed,
        }))
      )
    }

    leafNodes.forEach((leaf) => {
      const groupings = createGroupings(props).map((el) => ({
        ...el,
        id: nextId++,
      }))

      sortGroupings({ sortedPropValues, propName, groupings })

      hierarchy.push(
        ...groupings.map((el) => ({
          id: el.id,
          name: el.name,
          type: el.type,
          children: [],
          props: [],

          // Used temporarily so we can place elements under their hierarchy
          propUsed: el.propUsed,
        }))
      )

      leaf.children = groupings.map((el) => ({
        id: el.id,
        connector: palette.AGGREGATION_RELATIONSHIP,
      }))
    })
  })

  const getParents = (hierarchy, id) => {
    const parents = []
    let current = hierarchy.find((el) => el.id === id)
    while (current) {
      parents.push(current)
      current = hierarchy.find((el) =>
        el.children.some((c) => c.id === current.id)
      )
    }
    return parents
  }

  const getTopElementId = (elements, elementId) => {
    let currId = elementId
    let sanity = 0
    while (true) {
      const parent = elements.find((el) =>
        el.children.find((c) => c.id === currId)
      )
      if (!parent) {
        return currId
      }
      currId = parent.id
      if (sanity++ > 10) {
        console.error("Infinite loop detected")
        return undefined
      }
    }
  }

  // Add elementsToUse under the leaf nodes based on prop values

  const leafNodes = hierarchy.filter((el) => el.children.length === 0)

  // Update leaf node children with elementsToUse based on matching props

  const leafNodesWithChildren = leafNodes.map((leaf) => {
    const parents = getParents(hierarchy, leaf.id)
    const parentProps = parents
      .map((parent) => parent.propUsed)
      .filter((x) => x)

    const matchingElements = elementsToUse.filter((el) => {
      const matchingProps = el.props.filter((p) =>
        parentProps.some((pp) => pp.name === p.name && pp.value === p.value)
      )
      return matchingProps.length === parentProps.length
    })

    let uniqueMatchingElements = _.uniqWith(
      matchingElements,
      (a, b) => a.id === b.id
    )

    // If we are using the top level elements to be part of the hierarchy, then
    // remove any elements that don't have the top level element as their top level parent

    if (useTopLevel) {
      const topOfHierarchy = getTopElementId(hierarchy, leaf.id)

      const uniqueMatchingElementsWithParents = uniqueMatchingElements.map(
        (el) => ({
          topParentId: getTopElementId(currentView.elements, el.id),
          ...el,
        })
      )

      const result = uniqueMatchingElementsWithParents.filter(
        (el) => el.topParentId === topOfHierarchy
      )

      // Remove elements from uniqueMatchingElements that don't have the topOfHierarchy as their top parent

      uniqueMatchingElements = result
    }

    hierarchy.push(...uniqueMatchingElements)

    const result = {
      ...leaf,
      children: uniqueMatchingElements.map((el) => ({
        id: el.id,
        type: palette.AGGREGATION_RELATIONSHIP,
      })),
    }

    return result
  })

  const mergedResult = hierarchy.map((el) => {
    const existing = leafNodesWithChildren.find((x) => x.id === el.id)
    return existing || el
  })

  let sanityCheck = 5
  let len = mergedResult.length

  let pruned = deleteEmptyGroups(mergedResult)
  while (true) {
    if (pruned.length === len) {
      break
    }
    len = pruned.length
    pruned = deleteEmptyGroups(pruned)

    if (sanityCheck-- < 0) {
      break
    }
  }

  return pruned
}

const renameProperty = (views, fromName, toName) => {
  const newViews = views.map((view) => {
    const newElements = view.elements.map((element) => {
      const newProps = element.props.map((prop) => {
        if (prop.name === fromName) {
          return { ...prop, name: toName }
        }
        return prop
      })
      return { ...element, props: newProps }
    })
    return { ...view, elements: newElements }
  })

  return newViews
}

const getWorksheetRange = (sheet) => {
  const range = xlsx.utils.decode_range(sheet["!ref"])

  let maxColCount = undefined
  let maxRowCount = undefined

  for (let C = range.s.c; C <= range.e.c; ++C) {
    const cell = sheet[xlsx.utils.encode_cell({ c: C, r: range.s.r })]
    if (cell === undefined) {
      maxColCount = C - 1
      break
    }
  }
  if (maxColCount === undefined) {
    maxColCount = range.e.c
  }

  // Work out max row count based on first row to have an undefined value

  for (let R = range.s.r; R <= range.e.r; ++R) {
    const cell = sheet[xlsx.utils.encode_cell({ c: range.s.c, r: R })]
    if (cell === undefined) {
      maxRowCount = R - 1
      break
    }
  }

  if (maxRowCount === undefined) {
    maxRowCount = range.e.r
  }

  return {
    row: { from: range.s.r, to: maxRowCount },
    col: { from: range.s.c, to: maxColCount },
  }
}

const excelDateValueToDDMMMYYYY = (excelDateValue) => {
  const date = new Date((excelDateValue - (25567 + 2)) * 86400 * 1000)
  // Format as dd-mmm-yyyy
  return formatDateValue(date)
}

const formatDateValue = (date) => {
  // return `${date.getDate()}-${date.toLocaleString("default", {
  //     month: "short",
  // })}-${date.getFullYear()}`
  return moment(date).format("DD-MMM-YYYY")
}

const tryParseDate = (cell) => {
  if (!cell) {
    return null
  }

  if (cell.t === "n") {
    // Try converting the Excel date to JavaScript date
    const date = xlsx.SSF.parse_date_code(cell.v)
    if (date && moment([date.y, date.m - 1, date.d]).isValid()) {
      // The value is a valid date
      return moment([date.y, date.m - 1, date.d]).toDate()
    }
  } else if (cell.t === "s") {
    // Try parsing the string as a date
    const date = moment(cell.v, moment.ISO_8601, true)
    if (date.isValid()) {
      // The value is a valid date string
      return date.toDate()
    }
  }
  // Not a valid date
  return null
}

const getWorksheetRows = (sheet) => {
  const rows = []
  const range = xlsx.utils.decode_range(sheet["!ref"])

  console.log("%cworksheet range", "color:lightgreen", range)

  const worksheetRange = getWorksheetRange(sheet)

  const columnTypes = getColumnTypes(sheet)

  for (let R = range.s.r; R <= worksheetRange.row.to; ++R) {
    const row = []

    for (let C = range.s.c; C <= worksheetRange.col.to; ++C) {
      const cell = sheet[xlsx.utils.encode_cell({ c: C, r: R })]

      const type = columnTypes[C]

      switch (type) {
        case "date":
          row.push(cell ? excelDateValueToDDMMMYYYY(cell.v) : undefined)
          break

        case "text":
          row.push(cell ? cell.w : undefined)
          break

        case "number":
          row.push(cell ? cell.v : undefined)
          break

        default:
          row.push(cell ? cell.w : undefined)
          break
      }
    }

    rows.push(row)
  }

  // Remove 1st row since it's headings

  rows.shift()

  console.log("%crows", "color:orange", rows)

  return rows
}

const sortGroupings = ({ sortedPropValues, propName, groupings }) => {
  const groupingsSort = sortedPropValues?.find((item) => item.name === propName)

  // console.log("%cgroupings", "color:yellow", {
  //     propName,
  //     groupings,
  //     groupingsSort,
  //     sortedPropValues,
  // })

  // Sort groupings by sortedPropValues

  if (groupingsSort) {
    const groupingsSortValues = groupingsSort.values

    //console.log("%cgroupingsSortValues", "color:pink", groupingsSortValues)

    groupings.sort((a, b) => {
      const aIndex = groupingsSortValues.indexOf(a.propUsed.value)
      const bIndex = groupingsSortValues.indexOf(b.propUsed.value)

      // console.log("%cindexes", "color:pink", {
      //     aVal: a.propUsed.value,
      //     bVal: b.propUsed.value,
      //     aIndex,
      //     bIndex,
      // })
      return aIndex - bIndex
    })

    //console.log("%csorted", "color:pink", { groupings })
  }
}

const deleteEmptyGroups = (hierarchy) => {
  const itemsToDelete = hierarchy
    .filter((el) => el.children.length === 0 && el.propUsed !== undefined)
    .map((el) => el.id)

  const itemsDeleted = hierarchy
    .filter((el) => !itemsToDelete.includes(el.id))
    .map((el) => ({
      ...el,
      children: el.children.filter((c) => !itemsToDelete.includes(c.id)),
    }))

  // console.log("%cdelete empty groups", "color:lightgreen", {
  //     before: hierarchy.length,
  //     after: itemsDeleted.length,
  //     hierarchy,
  //     itemsDeleted,
  //     itemsToDelete: itemsToDelete.length,
  // })

  return itemsDeleted
}

const getDuplicateElementsByView = (currentView, item, views) => {
  if (item === undefined) {
    return []
  }

  const duplicates = _.flatten(
    views.map((view) =>
      view.elements
        .filter(
          (element) =>
            element.name.toLowerCase() === item.name.toLowerCase() &&
            element.type === item.type &&
            currentView.id !== view.id
        )
        .map((element) => ({ viewId: view.id, ...element }))
    )
  )

  return duplicates
}

const getDuplicateElementsThisView = (currentView, item, views) => {
  if (item === undefined) {
    return []
  }

  const duplicates = _.flatten(
    views.map((view) =>
      view.elements
        .filter(
          (element) =>
            element.name.toLowerCase() === item.name.toLowerCase() &&
            element.type === item.type &&
            currentView.id === view.id
        )
        .map((element) => ({ viewId: view.id, ...element }))
    )
  )

  return duplicates
}

const createOpenExchangeModel = (views, coordsByViewId) => {
  const docElements = views.flatMap((view) =>
    view.elements.flatMap((element) => {
      const elementType = palette.getElementTypeByIndex(element.type)

      const coords = coordsByViewId[view.id]?.find(
        (item) =>
          item.item.name === element.name && item.item.type === element.type
      )

      if (!coords) {
        console.log("cannot find coords", {
          name: element.name,
          type: element.type,
          viewId: view.id,
          coordsByViewId,
        })
      }
      return {
        name: element.name,
        description: element.description,
        type: elementType.name,
        layer: elementType.layer.name,
        props: element.props,
        id: `id-${guid()}`,
        viewId: view.id,
        coords: {
          x: coords?.x,
          y: coords?.y,
          width: coords?.width,
          height: coords?.height,
        },
      }
    })
  )

  // Deduplicate docElements based on name and type

  const uniqueDocElements = docElements.reduce((acc, current) => {
    const x = acc.find(
      (item) =>
        item.name.toLowerCase() === current.name.toLowerCase() &&
        item.type === current.type
    )
    if (!x) {
      return acc.concat([current])
    } else {
      return acc
    }
  }, [])

  const propertyDefs = uniqueDocElements
    .flatMap((element) => element.props)
    .reduce((acc, current) => {
      const x = acc.find(
        (item) => item.name === current.name && item.type === current.type
      )
      if (!x) {
        return acc.concat([{ name: current.name, type: current.type }])
      } else {
        return acc
      }
    }, [])
    .map((x, index) => ({ ...x, index: index }))

  const elementsAsXml = uniqueDocElements.map((element) => {
    const result = {
      name: { $: { "xml:lang": "en" }, _: element.name },
      //documentation: element.description,
      $: {
        ...element.$,
        identifier: element.id,
        "xsi:type": element.type,
      },
    }

    if (element.description.length > 0) {
      result.documentation = element.description
    }

    if (element.props.length > 0) {
      result.properties = {
        property: element.props.map((prop) => ({
          $: {
            propertyDefinitionRef: `prop-${
              propertyDefs.find((p) => p.name === prop.name).index
            }`,
          },
          value: { $: { "xml:lang": "en" }, _: prop.value },
        })),
      }
    }

    return result
  })

  const elementsByLayerAndType = _.groupBy(
    uniqueDocElements,
    (element) => element.layer + element.type
  )

  const folders = palette.LAYERS.map((layer) => ({
    label: {
      $: { "xml:lang": "en" },
      _: layer.name,
    },
    item: palette.ELEMENT_INDEX.filter(
      (elementType) => elementType.layer.name === layer.name
    ).map((elementType) => {
      const result = {
        label: {
          $: { "xml:lang": "en" },
          _: palette.formatLabel(elementType.name),
        },
      }

      if (elementsByLayerAndType[layer.name + elementType.name]) {
        result.item = elementsByLayerAndType[layer.name + elementType.name].map(
          (element) => ({
            $: {
              identifierRef: element.id,
            },
          })
        )
      }

      return result
    }),
  }))

  const propDefs = propertyDefs.map((def) => ({
    $: {
      identifier: `prop-${def.index}`,
      type: def.type === "text" ? "string" : def.type,
    },
    name: { _: def.name },
  }))

  const viewDefs = Object.keys(coordsByViewId).map((viewId, index) => {
    const view = views.find((view) => view.id === viewId)

    return {
      $: {
        identifier: `id-${guid()}`,
        "xsi:type": "Diagram",
      },
      name: {
        $: { "xml:lang": "en" },
        _: view.name,
      },
      node: uniqueDocElements
        .filter((element) => element.viewId === view.id)
        .map((element) => {
          return {
            $: {
              identifier: `id-${guid()}`,
              elementRef: element.id,
              "xsi:type": "Element",
              x: element.coords?.x,
              y: element.coords?.y,
              w: element.coords?.width,
              h: element.coords?.height,
            },
          }
        }),
    }
  })

  const model = {
    $: {
      xmlns: "http://www.opengroup.org/xsd/archimate/3.0/",
      "xmlns:xsi": "http://www.w3.org/2001/XMLSchema-instance",
      "xsi:schemaLocation":
        "http://www.opengroup.org/xsd/archimate/3.0/ http://www.opengroup.org/xsd/archimate/3.1/archimate3_Diagram.xsd",
      identifier: `id-${guid()}`,
    },
    name: { $: { "xml:lang": "en" }, _: "newfile.xml" },
    elements: {
      element: elementsAsXml,
    },
    organizations: {
      item: folders,
    },
    propertyDefinitions: {
      propertyDefinition: propDefs,
    },
    views: {
      diagrams: {
        view: viewDefs,
      },
    },
  }

  // Delete propertyDefinitions if there are none

  if (propDefs.length === 0) {
    delete model.propertyDefinitions
  }

  const doc = { model }

  var builder = new xml2js.Builder()
  var xml = builder.buildObject(doc)

  return xml
}

// Given an item and a shader, return the item property that matches the shader property
const getItemPropForShader = (item, shader) => {
  if (item && shader) {
    const itemProp = item.props.find(
      (prop) => prop.name.toLowerCase() === shader.property.toLowerCase()
    )
    return itemProp
  }
  return undefined
}

const getPropColor = (prop, shaders, defaultColor = colors.grey[200]) => {
  if (prop && shaders) {
    const shader = shaders.find(
      (shader) => shader.property.toLowerCase() === prop.name.toLowerCase()
    )
    if (shader) {
      const propValueStr = prop.value?.toString().toLowerCase()
      const result = shader.config?.colors?.find(
        (items) => items.value.toString().toLowerCase() === propValueStr
      )?.color
      return result
    }
  }

  return defaultColor
}

const getShaderColor = (item, shader) => {
  if (item && shader) {
    const itemProp = getItemPropForShader(item, shader)
    if (itemProp) {
      const propValue = item.props.find(
        (prop) => prop.name.toLowerCase() === itemProp.name.toLowerCase()
      )?.value

      const propValueStr = propValue?.toString().toLowerCase()

      const result =
        shader.config?.colors?.find(
          (items) => items.value.toString().toLowerCase() === propValueStr
        )?.color || colors.grey[200]

      return result
    }
  }

  return colors.grey[200]
}

const getPropTypeCount = (propType, elements) => {
  const propTypeLower = propType.toLowerCase()
  const usage = elements
    .flatMap((element) => element.props)
    .reduce((acc, prop) => {
      const propValueStr = prop.value?.toString().toLowerCase()

      if (propTypeLower === prop.name.toLowerCase()) {
        const newAcc = { ...acc }
        newAcc[prop.value?.toString().toLowerCase()] = acc[propValueStr]
          ? acc[prop.value.toString().toLowerCase()] + 1
          : 1
        return newAcc
      }
      return acc
    }, {})

  return usage
}

const getProjectsDateRange = (workPackages) => {
  const startDates =
    workPackages.map((wp) => wp.startDate || null).filter((d) => d !== null) ||
    {}

  const minStartDate = startDates.length > 0 ? Math.min(...startDates) : null

  // Get a date which is at the end of 3 years
  const endOf3Years = new Date(new Date().getFullYear() + 3, 0, 0).getTime()

  const result = { minStartDate, maxEndDate: endOf3Years }

  return result
}

// Get the unique set of key/value pairs for a given property type
const getUniqueProps = (elementType, views) => {
  const allViewElements = views.reduce((acc, view) => {
    return [...acc, ...view.elements]
  }, [])

  // Get 'name' attribute of 'props' object for each element

  const allViewElementKeys = _.flatten(
    allViewElements
      .filter(
        (element) => element.props.length > 0 && element.type === elementType
      )
      .map((element) => element.props.map((p) => p))
  ).filter(
    (p) => ![START_DATE_LOWER, END_DATE_LOWER].includes(p.name.toLowerCase())
  )

  const uniqueKeyValuePairs = _.uniqBy(
    allViewElementKeys,
    (x) => `${x.name}-${x.value}`
  ).sort((a, b) => {
    const sort = a.name.localeCompare(b.name)
    if (sort === 0) {
      return a.value?.toString().localeCompare(b.value?.toString())
    }
    return sort
  })

  return uniqueKeyValuePairs
}

// Add an 'id' attr to each item in an array.
// Can be called on an element array recursively on its children, or a props array
// Both element arrays, and the props of elements have an id attribute to help manage then in memory
// i.e. even though this checks for 'children' that attribute will not be there if called for an array of element props
const addIds = (itemArr, currentMaxId) => {
  //console.log("next id to use", { currentMaxId, elementArr })

  const addId = (element) => {
    //console.log("set element id", { element })
    if (element.id === undefined) {
      element.id = ++currentMaxId
      console.log("set id", element.name, currentMaxId)
    }
    if (element.children) {
      element.children.forEach((child) => addId(child))
    }
  }

  itemArr.forEach((element) => addId(element))

  return currentMaxId
}

const addProjectChartAttributes = (projects, portfolioDateRange) => {
  return projects.map((project) => {
    const startDate = getDate(project, START_DATE_LOWER)
    const endDate = getDate(project, END_DATE_LOWER)

    const startDateLabel =
      startDate !== null ? format(startDate, "dd-MMM-yyyy") : null
    const endDateLabel =
      endDate !== null ? format(endDate, "dd-MMM-yyyy") : null
    const marks = []
    const values = []
    if (startDate !== null) {
      values.push(startDate.getTime())
    }

    if (endDate !== null) {
      values.push(endDate.getTime())
    }

    // Get a date every 3 months from portfolioDateRange.startDate to portfolioDateRange.endDate

    if (portfolioDateRange) {
      const start = new Date(portfolioDateRange.startDate)
      const end = new Date(portfolioDateRange.endDate)
      const diff = end - start
      const months = diff / (1000 * 60 * 60 * 24 * 30)
      const numMarks = Math.ceil(months / 3)
      for (let i = 0; i < numMarks; i++) {
        const date = new Date(
          start.getTime() + i * 3 * 30 * 24 * 60 * 60 * 1000
        )
        const labelVal = format(date, "MMM yyyy")
        marks.push({
          value: date.getTime(),
          date: date,
          label: (
            <Typography variant="caption" sx={{ color: colors.grey[500] }}>
              {labelVal}
            </Typography>
          ),
          labelVal: labelVal,
        })
      }
    }

    const result = {
      ...project,
      startDate: startDate,
      startDateLabel: startDateLabel,
      endDate: endDate,
      endDateLabel: endDateLabel,
      marks: marks,
      values: values,
    }

    return result
  })
}

const getElapsedMonths = (startDate, endDate) => {
  const months =
    (startDate !== null &&
      endDate !== null &&
      (
        (endDate.getTime() - startDate.getTime()) /
        (1000 * 60 * 60 * 24) /
        30
      ).toFixed(1)) ||
    0

  return months
}

const handleCalcNewViewElementsFromImport = ({
  workbook,
  sheetName,
  currentView,
  views,
}) => {
  const worksheetInfo = getWorksheetInfo(workbook, sheetName)

  const sheet = workbook.Sheets[sheetName]

  // Convert workbook into rows
  const rows = getWorksheetRows(sheet)

  // Create a hierarchy of elements from the rows, where column 1 is the parent, and column 3 is the child
  // Start id numbering at 1, since 0, null, or undefined means no id
  const maxId = Math.max(getMaxElementId(currentView.elements), 1)

  const columnTypes = getColumnTypes(sheet)

  const newElements = rows.map((row, index) => {
    const name = row[worksheetInfo.nameIndex]
    const type = row[worksheetInfo.typeIndex]

    const description = row[worksheetInfo.descriptionIndex] || ""

    const msgs = []

    // Get property values

    const propValues = worksheetInfo.props.map((propInfo) => {
      return {
        name: propInfo.name,
        values: convertValueToString(row[propInfo.index])
          .split(",")
          .map((val) => val.trim()),
        type: columnTypes[propInfo.index],
      }
    })

    const elementProps = propValues
      .flatMap((prop) =>
        prop.values.map((val) => ({
          name: prop.name,
          value: val,
          type: prop.type,
        }))
      )
      .filter((p) => p.value !== "")

    if (type === undefined) {
      msgs.push({
        label: `Missing ArchiMate element type: ${name}, row ${index + 2}`,
        severity: "error",
      })
    }

    console.log(
      "%celementProps",
      "color:lightgreen",
      row[worksheetInfo.nameIndex],
      elementProps
    )

    // Remove spaces from type
    const formattedType = type?.replace(/\s/g, "")
    const elementId = palette.getIndex(formattedType)

    if (elementId === -1) {
      msgs.push({
        label: `Unknown ArchiMate element type: ${type}, row ${index + 2}`,
        severity: "error",
      })
    }

    const duplicateElements = getDuplicateElementsByView(
      currentView,
      {
        name,
        type: elementId,
        id: undefined,
      },
      views
    )

    // Combine all duplicateElements props and the elementProps into an array and dedupe based on name and value

    const allProps = [
      ...elementProps,
      ...duplicateElements.flatMap((element) => element.props),
    ]

    // Find duplicates in allProps

    const allPropsDupes = allProps.filter((prop, index) =>
      allProps.some(
        (p, i) =>
          i !== index &&
          p.name.toLowerCase() === prop.name.toLowerCase() &&
          p.value === prop.value
      )
    )

    // Remove all but 1 of the duplicates from allProps

    const allPropsNoDupes =
      allPropsDupes.length === 0
        ? allProps
        : allProps.filter(
            (prop, index) =>
              !allPropsDupes.some(
                (p, i) =>
                  i !== index &&
                  p.name.toLowerCase() === prop.name.toLowerCase() &&
                  p.value === prop.value
              )
          )

    const element = {
      id: maxId + index,
      name,
      type: elementId,
      description,
      props: allPropsNoDupes,
      children: [],
      msgs: msgs,
      row: index,
      parentName: row[worksheetInfo.parentIndex],
    }

    return element
  })

  return newElements
}

const isElementShaded = (element, shader) => {
  const prop = element.props.find(
    (p) => p.name.toLowerCase() === shader.property.toLowerCase()
  )
  if (!prop) {
    return false
  }
  const shaded = shader.config.colors.find((c) => c.value === prop.value)
  return shaded !== undefined
}

const getDate = (element, name) => {
  const dateStr =
    element.props.find((p) => p.name.toLowerCase() === name.toLowerCase())
      ?.value || null

  if (dateStr === null) {
    return null
  }
  return dateFnsParse(dateStr, "dd-MMM-yyyy", new Date())
}

const findMaxLevels = (elements) => {
  if (elements === undefined) {
    return
  }
  const leafNodes = elements.filter((element) => element.children.length === 0)

  // Find the number of levels to get to a root node
  const levels = leafNodes.map((leafNode) => {
    let thisElement = leafNode
    let sanity = 0
    let level = 0

    while (true) {
      if (sanity++ > 15) {
        break
      }
      const parent = elements.find((element) =>
        element.children.find((child) => child.id === thisElement.id)
      )

      if (parent !== undefined) {
        thisElement = parent
        level++
      } else {
        break
      }
    }
    return level
  })

  const result = Math.max(...levels) + 1

  return result
}

const CONNECTOR_REPLACEMENTS = [
  {
    to: palette.BUSINESS_EVENT,
    // any
    from: undefined,
    type: palette.TRIGGERING_RELATIONSHIP,
  },
]

const getConnector = (to, from) => {
  const toName = palette.getElementNameByIndex(to)
  const fromName = palette.getElementNameByIndex(from)

  const connector = CONNECTOR_REPLACEMENTS.find(
    (c) => c.to === toName && (c?.from === fromName || c.from === undefined)
  )
  //console.log("find connector", { to, from, connector })
  return connector?.type
}

const createChatPromptData = ({
  currentView,
  selectedItemId,
  hiddenProps,
  includeDoco = true,
}) => {
  let promptElements

  if (selectedItemId) {
    promptElements = getChildElements(currentView, selectedItemId)
  } else {
    promptElements = currentView.elements
  }

  const promptData = {
    view: { name: currentView.name },
    notes: [],
    elements: promptElements.map((el) => {
      const type = palette.getElementNameByIndex(el.type)

      //console.log("element", el)

      const connections = el.children.map((c) => {
        const target = currentView.elements.find((x) => x.id === c.id)

        const connectorName =
          getConnector(target.type, el.type) || palette.AGGREGATION_RELATIONSHIP

        // console.log("%cget connector", "color:orange", {
        //   target,
        //   el,
        //   connector: connectorName,
        // })
        return {
          //type: palette.AGGREGATION_RELATIONSHIP,
          type: connectorName,
          source: { name: el.name, type: type },
          target: {
            name: target.name,
            type: palette.getElementNameByIndex(target.type),
          },
        }
      })

      if (type === palette.DELIVERABLE) {
        const parent = currentView.elements.find((x) =>
          x.children.find((y) => y.id === el.id)
        )

        if (parent) {
          const wpCnx = {
            type: palette.REALIZATION_RELATIONSHIP,
            source: { name: el.name, type: type },
            target: {
              name: parent.name,
              type: palette.WORK_PACKAGE,
            },
          }
          connections.push(wpCnx)
        }
      }

      return {
        id: el.id,
        name: el.name,
        type: palette.getElementNameByIndex(el.type),
        documentation: includeDoco
          ? (el.description !== "" && el.description && [el.description]) || []
          : [],
        connections: connections,
        props: el.props
          .filter(
            (p) =>
              hiddenProps.length === 0 ||
              !hiddenProps.includes(p.name.toLowerCase())
          )
          .map((p) => ({ name: p.name, value: p.value })),
      }
    }),
  }

  console.log("%cprompt data", "color:yellow", promptData)

  return promptData
}

const convertValueToString = (value) => {
  if (value === undefined) {
    return ""
  }

  if (typeof value === "string") {
    return value
  }

  if (typeof value === "number") {
    return value.toString()
  }

  if (typeof value === "boolean") {
    return value.toString()
  }

  if (Array.isArray(value)) {
    return value.map((x) => convertValueToString(x)).join(", ")
  }

  if (typeof value === "object") {
    return JSON.stringify(value)
  }

  return ""
}

const getElementsToPasteAdd = ({ jsonElements, parent, viewElements }) => {
  let maxId = getMaxElementId(viewElements)

  //TODO: dedupe this with similar code in chatGenerationServices
  const addId = (element) => {
    if (element.id === undefined) {
      element.id = ++maxId
    }
    if (element.children) {
      element.children = element.children.map(addId)
    }
    return element
  }

  const elementsWithIds = jsonElements.map(addId)

  // Now flatten the elements

  const flatten = (element) => {
    const children = element.children || []
    return [
      {
        ...element,
        children: children.map((c) => ({
          id: c.id,
          connector: palette.AGGREGATION_RELATIONSHIP,
        })),
      },
      ...children.flatMap(flatten),
    ]
  }

  const flattenedElements = elementsWithIds.flatMap(flatten)

  const convertPropValuesToStrings = (props) =>
    props.map((prop) => ({ ...prop, value: prop.value.toString() }))

  const elementsToAdd = flattenedElements.map((element) => {
    return {
      ...element,
      id: element.id,
      name: element.name.trim(),
      type: element.type,
      children: element.children,
      props: convertPropValuesToStrings(element.props || []),
    }
  })

  // If we're adding under the current element then current element will need its children updated

  const newChildrenForCurrentElement =
    (parent &&
      elementsToAdd
        // .filter((newElement) => {
        //     return !viewElements.find(
        //         (element) => element.name.toLowerCase() === newElement.name.toLowerCase()
        //     )
        // })
        .map((element) => ({
          id: element.id,
          connector: palette.ASSOCIATION_RELATIONSHIP,
        }))) ||
    []

  // console.log("new children for current parent", {
  //   children: `${newChildrenForCurrentElement.map((c) => c.id).join(", ")}`,
  // })

  // Replace 'chidren' attribute for current element, if a current element is selected
  const updatedViewElements = viewElements.map((element) => {
    if (element.id === parent?.id) {
      // console.log("adding into children", {
      //   element,
      //   newChildrenForCurrentElement,
      // })

      const deduplicatedChildren = _.uniqBy(
        [...element.children, ...newChildrenForCurrentElement],
        (x) => x.id
      )

      return {
        ...element,
        children: deduplicatedChildren,
      }
    } else {
      return element
    }
  })

  // Filter out newElements if there is already an element with the same name in the current view

  // const elementsToAddFiltered = elementsToAdd.filter((newElement) => {
  //     return !viewElements.find(
  //         (element) => element.name.toLowerCase() === newElement.name.toLowerCase()
  //     )
  // })
  //
  //const combinedElements = [...newElements, ...elementsToAddFiltered]

  const combinedElements = [...updatedViewElements, ...elementsToAdd]

  const combinedUniqByIdElements = _.uniqBy(combinedElements, (x) => x.id)

  // Check if id is duplicated across combinedElements

  // const ids = combinedElements.map((element) => element.id)
  // const uniqueIds = _.uniq(ids)
  // if (ids.length !== uniqueIds.length) {
  //     const duplicateIdValues = ids.filter((id) => ids.filter((x) => x === id).length > 1)
  //     console.error("%cduplicate ids", "color:red", {
  //         ids,
  //         uniqueIds,
  //         combinedElements,
  //         duplicateIdValues,
  //     })
  // }

  //console.log("combinedElements", { newElements, elementsToAddFiltered, combinedElements })

  // elementsToAdd.forEach((element) => {
  //     const existingElement = combinedElements.find(
  //         (x) => x.name.toLowerCase() === element.name.toLowerCase() && x.type === element.type
  //     )

  //     if (existingElement) {
  //         // Merge properties from elementsToAdd into existingElement, first taking props
  //         // from elementsToAdd based on the property name

  //         const existingElementProps = existingElement.props || []
  //         const newElementProps = element.props || []

  //         //console.log("updating props", { existingElementProps, newElementProps })

  //         let newProps

  //         try {
  //             newProps = newElementProps.map((newProp) => {
  //                 const existingProp = existingElementProps.find(
  //                     (x) => x.name.toLowerCase() === newProp.name.toLowerCase()
  //                 )

  //                 if (existingProp) {
  //                     return {
  //                         ...existingProp,
  //                         value: newProp.value,
  //                     }
  //                 } else {
  //                     return newProp
  //                 }
  //             })
  //         } catch (e) {
  //             console.error("error updating props", { existingElementProps, newElementProps })
  //         }

  //         // Now add any props from existingElement that are not in newProps

  //         const existingElementPropsNotInNewProps = existingElementProps.filter(
  //             (x) => !newProps.find((y) => y.name.toLowerCase() === x.name.toLowerCase())
  //         )

  //         const combinedProps = [...newProps, ...existingElementPropsNotInNewProps]

  //         // Now update the existing element

  //         existingElement.props = combinedProps
  //     } else {
  //         console.log("%cno existing element found", "color:orange", {
  //             existingElement,
  //             combinedElements,
  //         })
  //     }
  // })

  //return combinedElements
  return combinedUniqByIdElements
}

const getDuplicatePropertyNames = (views) => {
  //console.trace("get unique property names", { views })
  const names = _.uniq(
    views.flatMap((view) => {
      return view.elements.flatMap((element) => {
        if (element.props === undefined) {
          console.warn("element has no props", { element })
        }
        return element.props.flatMap((property) => property.name)
      })
    })
  )

  // Find entries in 'names' where the lower case version of the name is duplicated
  const duplicates = names.filter((name) => {
    const lowerCaseName = name.toLowerCase()
    return (
      names.filter((name) => name.toLowerCase() === lowerCaseName).length > 1
    )
  })

  const uniqueDuplicates = _.uniq(duplicates.map((name) => name.toLowerCase()))

  return uniqueDuplicates
}

const START_DATE_LOWER = "start date"

const END_DATE_LOWER = "end date"

export {
  createOpenExchangeModel,
  getDate,
  getProjectsDateRange,
  addProjectChartAttributes,
  getUniqueProps,
  getElapsedMonths,
  findMaxLevels,
  getItemPropForShader,
  getShaderOptions,
  getShaderOptionsForCurrentView,
  getChildElements,
  getUniquePropValues,
  handleToggleProperty,
  handleCalcNewViewElementsFromImport,
  getWorksheetRows,
  handleImportDataWithHierarchy,
  getColumnTypes,
  getWorksheetRange,
  handleMoveElementLeft,
  replaceTypeNameWithTypeIndex,
  getLeafNodesOfElement,
  getLeafNodesOfView,
  handleMoveElementRight,
  getAllSiblings,
  getParentContext,
  getShaderColor,
  getPropTypeCount,
  createChatPromptData,
  convertValueToString,
  getRootElements,
  addIds,
  renameProperty,
  findMaxSeq,
  getMaxElementId,
  createGroupedView,
  getLegendLayout,
  isElementShaded,
  getPropColor,
  getWorksheetInfo,
  getUniqueColumnValues,
  getAvailablePropertyValuesForElement,
  getElementsToPasteAdd,
  getDuplicateElementsByView,
  getDuplicateElementsThisView,
  addMissingIdsToProperties,
  addMissingPropIds,
  getMaxPropId,
  handleDownloadElementsAsXlsx,
  getDuplicatePropertyNames,
  START_DATE_LOWER,
  END_DATE_LOWER,
  NAME_COL,
  TYPE_COL,
  PARENT_COL,
  DESCRIPTION_COL,
}
