import React from "react"
import { Button as MuiButton, Chip, Typography, colors } from "@mui/material"
import { Tooltip } from "@mui/material"
import { useTheme } from "@mui/material/styles"

function Button(props) {
  const theme = useTheme()

  const styles = {
    button: {
      margin: theme.spacing(0.5),
      textTransform: "none",
    },
  }

  const {
    text,
    size = "small",
    variant = "outlined",
    onClick,
    tooltip,
    buttonStyle = {},
    ...other
  } = props

  const getButton = () => {
    const iconProps = {}

    const { endIcon, startIcon, ...otherProps } = other

    if (endIcon) {
      iconProps.icon = endIcon
    }

    if (startIcon) {
      iconProps.icon = startIcon
    }
    //console.log("other", other)
    return (
      // <MuiButton
      //     variant={variant || "contained"}
      //     size={size || "large"}
      //     onClick={onClick}
      //     {...other}
      //     sx={styles.button}
      // >
      //     <span>{text}</span>
      // </MuiButton>

      <Chip
        size={size}
        label={<Typography variant="caption">{text}</Typography>}
        onClick={onClick}
        {...otherProps}
        {...iconProps}
        sx={{
          padding: "2px",
          border: "1px solid #ddd",
          backgroundColor: variant === "outlined" ? "#fff" : colors.blue[100],
          ":hover": {
            backgroundColor: variant === "outlined" ? "#ddd" : colors.blue[200],
            borderColor: variant === "outlined" ? "#bbb" : colors.blue[400],
          },
          ...buttonStyle,
        }}
      />
    )
  }

  return tooltip ? (
    <Tooltip title={tooltip}>
      <span>{getButton()}</span>
    </Tooltip>
  ) : (
    getButton()
  )
}

export default Button
