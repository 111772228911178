import firebase from "firebase/compat/app"
import { functions } from "../../Firestore"
import { httpsCallable } from "firebase/functions"
import {
  GPT_3_5_TURBO,
  GPT_4_LATEST,
  GPT_4_TURBO,
  GPT_4o_LATEST,
  TEXT_EMBEDDING_ADA_002,
} from "./chatGenerationServices"

const AUSTRALIA_SOUTHEAST1 = "australia-southeast1"

const obtainCustomClaims = async () => {
  const obtainClaims = httpsCallable(functions, "getClaims")

  if (firebase.auth().currentUser) {
    const user = firebase.auth().currentUser

    console.log(
      "obtain claims for user",
      user.email,
      "email verified?",
      user.emailVerified
    )

    return await firebase
      .auth()
      .currentUser.getIdTokenResult(true)
      .then(async (token) => {
        const payload = { idToken: token }

        try {
          const claimsResult = await obtainClaims(payload)

          console.log("%cobtained claims", "color:pink", { claimsResult })

          return claimsResult
        } catch (err) {
          return console.error("Error calling claims", err)
        }
      })
  } else {
    console.log("obtainCustomClaims", "no current user")
  }
  return {}
}

const testGen2func = async () => {
  const func = httpsCallable(functions, "testGen2bFunc")

  const result = await func({ value: "hello", XXXXXXXXXXXXXXXXX: "12345678" })
  console.log("testGen2func", result)
}

/**
 *
 * @param {*} input | The input to create the embedding for
 * @returns
 */
const createEmbedding = async (input) => {
  const createEmbeddingFunc = httpsCallable(functions, "createEmbedding")

  const payload = { input: input, model: TEXT_EMBEDDING_ADA_002 }

  try {
    const queryResult = await createEmbeddingFunc(payload)

    //console.log("%ccreateEmbedding", "color:green", queryResult)

    return queryResult
  } catch (err) {
    console.error("Error calling createEmbedding", err)
  }
}

// const createCompletion = async ({ prompt, model = "text-davinci-003", maxTokens = 60 }) => {
//     const func = httpsCallable(functions, "createCompletion")

//     try {
//         console.log("Creating completion", { prompt, model, maxTokens })
//         const queryResult = await func({ prompt, model, maxTokens })

//         console.log("%ccreateCompletion", "color:green", queryResult)

//         return queryResult
//     } catch (err) {
//         console.error("Error calling createCompletion", err)
//         throw err
//     }
// }

const createThread = async ({ messages }) => {
  const func = httpsCallable(functions, "createThread", { timeout: 300000 })

  try {
    const result = await func({ messages })

    return result
  } catch (err) {
    console.error("Error calling createThread", err)
    throw err
  }
}

const deleteThread = async ({ threadId }) => {
  const func = httpsCallable(functions, "deleteThread", { timeout: 300000 })

  try {
    const result = await func({ threadId })

    return result
  } catch (err) {
    console.error("Error calling deleteThread", err)
    throw err
  }
}

const createRun = async ({
  threadId,
  assistantId,
  functionToUse,
  tools,
  modelName,
}) => {
  const func = httpsCallable(functions, "createRun", { timeout: 300000 })

  try {
    const result = await func({
      threadId,
      assistantId,
      functionToUse,
      tools,
      modelName,
    })

    return result
  } catch (err) {
    console.error("Error calling createRun", err)
    throw err
  }
}

const submitToolOutputs = async ({
  threadId,
  runId,
  toolCallId,
  toolOutput,
}) => {
  const func = httpsCallable(functions, "submitToolOutputs", {
    timeout: 300000,
  })

  try {
    const result = await func({ threadId, runId, toolCallId, toolOutput })

    return result
  } catch (err) {
    console.error("Error calling submitToolOutputs", err)
    throw err
  }
}

const retrieveRun = async ({ threadId, runId }) => {
  const func = httpsCallable(functions, "retrieveRun", { timeout: 300000 })

  try {
    const result = await func({ threadId, runId })

    return result
  } catch (err) {
    console.error("Error calling retrieveRun", err)
    throw err
  }
}

const createMessage = async ({ threadId, message }) => {
  const func = httpsCallable(functions, "createMessage", { timeout: 300000 })

  try {
    const result = await func({ threadId, message })

    return result
  } catch (err) {
    console.error("Error calling createMessage", err)
    throw err
  }
}

const createMessages = async ({ threadId, content }) => {
  const func = httpsCallable(functions, "createMessages", { timeout: 300000 })

  try {
    // Split the messages into chunks of 10
    const chunks = content.reduce((resultArray, item, index) => {
      const chunkIndex = Math.floor(index / 10)

      if (!resultArray[chunkIndex]) {
        resultArray[chunkIndex] = [] // start a new chunk
      }

      resultArray[chunkIndex].push(item)

      return resultArray
    }, [])

    console.log("%cchunks", "color:dodgerBlue", { chunks })

    const results = []
    for (const chunk of chunks) {
      console.log("%cadding chunk", "color:dodgerBlue", { chunk })
      const result = await func({ threadId, content: chunk })
      results.push(result)
    }

    return results
  } catch (err) {
    console.error("Error calling createMessages", err)
    throw err
  }
}

const listMessages = async ({ threadId }) => {
  const func = httpsCallable(functions, "listMessages", { timeout: 300000 })

  try {
    const result = await func({ threadId })

    return result
  } catch (err) {
    console.error("Error calling listMessages", err)
    throw err
  }
}

const listAssistants = async () => {
  const func = httpsCallable(functions, "listAssistants", { timeout: 300000 })

  try {
    const queryResult = await func({})

    //console.log("%clistAssistants", "color:lightblue", queryResult)

    return queryResult.data.response.data
  } catch (err) {
    console.error("Error calling listAssistants", err)
    throw err
  }
}

const retrieveAssistant = async ({ assistantId }) => {
  const func = httpsCallable(functions, "retrieveAssistant", {
    timeout: 300000,
  })

  try {
    const queryResult = await func({ assistantId })

    console.log("%cretrieveAssistant", "color:lightblue", queryResult)

    return queryResult.data.response
  } catch (err) {
    console.error("Error calling retrieveAssistant", err)
    throw err
  }
}

const createChatCompletion = async ({ messages, model = GPT_4o_LATEST }) => {
  const func = httpsCallable(functions, "createGptChatCompletion", {
    timeout: 300000,
  })

  try {
    // Only print to console if in dev mode
    if (process.env.NODE_ENV === "development") {
      console.log("%ccreating chat completion with model", "color:lightblue", {
        model,
        messages,
      })
    }
    const queryResult = await func({ messages, model })

    //console.log("%ccreateChatCompletion", "color:lightblue", queryResult)

    return queryResult
  } catch (err) {
    console.error("Error calling createChatCompletion", err)
    throw err
  }
}

const createChatCompletionWithFunctions = async ({
  messages,
  model = GPT_4o_LATEST,
  funcs = [],
  function_call,
}) => {
  const func = httpsCallable(
    functions,
    "createGptChatCompletionWithFunctions",
    {
      timeout: 300000,
    }
  )

  try {
    if (process.env.NODE_ENV === "development") {
      console.log("%ccreating chat completion with model", "color:lightblue", {
        model,
        messages,
        funcs,
        function_call,
      })
    }
    const queryResult = await func({ messages, model, funcs, function_call })

    //console.log("%ccreateChatCompletion", "color:lightblue", queryResult)

    return queryResult
  } catch (err) {
    console.error("Error calling createChatCompletion", { err })
    throw err
  }
}

const getOpenAiModels = async (text) => {
  const getModelsFunc = httpsCallable(functions, "getOpenAiModels")

  const payload = {}

  try {
    const queryResult = await getModelsFunc(payload)

    console.log("%cgetOpenAiModels", "color:green", queryResult)

    return queryResult
  } catch (err) {
    console.error("Error calling getOpenAiModels", err)
  }
}

// This is the convention by which a cloud function indicates an error has occurred
const isError = (funcResult) => {
  return funcResult.data?.status?.type === "error"
}

const getMessage = (funcResult) => {
  return funcResult.data?.status?.message
}

const queryProjectsByAccountId = async (accountId) => {
  const queryProjects = httpsCallable(functions, "queryProjectsByAccountId")

  const payload = { accountId }

  const queryResult = await queryProjects(payload)

  console.log("queryProjectsByAccountId", queryResult)

  return queryResult
}

const copyDefaultRules = async () => {
  const copyRules = httpsCallable(functions, "copyDefaultRules")

  const payload = {}

  const result = await copyRules(payload)

  return result
}

/**
 * @param {*} viewName The name of the view to query in BigQuery
 * @returns data from BigQuery
 */
const queryViewByAccountId = async (viewName) => {
  // The account id value is determined bty the function server-side
  const query = httpsCallable(functions, "queryViewByAccountId")

  const payload = { viewName }

  const queryResult = await query(payload)

  console.log("queryViewByAccountId", queryResult)

  return queryResult
}

const processInvite = async () => {
  const processInvite = httpsCallable(functions, "processInvite")

  const payload = {}

  const inviteResult = await processInvite(payload)

  return inviteResult
}

// const createUser = async ({ email, password, otp }) => {
//   const createUserWithPassword = httpsCallable(functions, "createUser")

//   // seeking for result.data.token to be the Firebase token
//   const result = await createUserWithPassword({ email, otp, password })

//   return result
// }

const createUserAndGetToken = async (email, password) => {
  const func = httpsCallable(functions, "createUserAndGetToken")

  const result = await func({ email, password })

  return result.data
}

const validateQRCodeOTPAndGetToken = async ({
  email,
  password,
  usePassword,
  otp,
}) => {
  const validateQRCode = httpsCallable(
    functions,
    "validateQRCodeOTPAndGetToken"
  )

  // seeking for result.data.token to be the Firebase token
  const result = await validateQRCode({ email, otp, password, usePassword })

  return result
}

const validateOTPAndGetToken = async (email, otp, usePassword, password) => {
  const validateOTP = httpsCallable(functions, "validateOTPAndGetToken")

  const validateProps = { email, otp }

  if (usePassword) {
    validateProps.password = password
    validateProps.usePassword = usePassword
  }

  // seeking for result.data.token to be the Firebase token
  const result = await validateOTP(validateProps)

  return result
}

// Handles validation of OTPs sent via email or SMS, even though cloud function name says email only
const isEmailOrSMSOTPCorrect = async (email, otp) => {
  const isOTPCorrect = httpsCallable(functions, "isEmailOTPCorrect")

  const result = await isOTPCorrect({ email, otp })

  return result.data
}

const isQROTPCorrect = async (otp, email) => {
  const func = httpsCallable(functions, "isQROTPCorrect")

  const result = await func({ otp, email })

  return result.data
}

const generateTotpKey = async (email) => {
  const func = httpsCallable(functions, "generateTotpKey")

  const payload = { email }

  const result = await func(payload)

  return result
}

const createAndSendOTPViaEmail = async ({ email }) => {
  const createAndSendOTP = httpsCallable(functions, "createAndSendOTPMessage")

  return await createAndSendOTP({ email: email })
}

const queryAuditEvents = async () => {
  const queryFunc = httpsCallable(functions, "queryAuditEvents")

  const payload = {}

  const result = await queryFunc(payload)

  return result
}

const createAndSendOTPViaSMS = async ({ email, phone }) => {
  const createAndSendOTP = httpsCallable(functions, "sendSMSOTP")

  return await createAndSendOTP({ email: email, phone: phone })
}

const getUserAuthenticationMethods = async ({ email }) => {
  const geAuthenticationMethods = httpsCallable(functions, "getUserAuthMethods")

  console.log("%cget authentication methods", "color:pink", email)

  const result = geAuthenticationMethods({ email })

  return result
}

const createTotpURI = async (email) => {
  const getTotpURI = httpsCallable(functions, "createTOTPURI")

  const payload = { email }

  const result = await getTotpURI(payload)

  return result
}

const verifyTotpToken = async (token) => {
  const verify = httpsCallable(functions, "verifyTotpToken")

  const payload = { token }

  const result = await verify(payload)

  return result
}

//const test01 = httpsCallable(functions, "test01")

//const test02 = httpsCallable(functions, "test02")

const checkUserExists = httpsCallable(functions, "checkIfUserExists")

const sendEmailMessage = async ({ to, subject, text, html }) => {
  const sendMsg = httpsCallable(functions, "sendEmailMessage")

  const payload = { to, subject, text, html }

  const result = await sendMsg(payload)

  return result
}

const refreshUserToken = () => {
  let callback = null
  let metadataRef = null

  console.log("refreshUserToken")

  firebase.auth().onAuthStateChanged((user) => {
    if (callback) {
      metadataRef.off("value", callback)
    }
    if (user) {
      metadataRef = firebase
        .database()
        .ref("metadata/" + user.uid + "/refreshTime")
      callback = (snapshot) => {
        user.getIdToken(true)
      }
      metadataRef.on("value", callback)
    }
  })
}

export {
  obtainCustomClaims,
  refreshUserToken,
  checkUserExists,
  processInvite,
  //test01,
  //test02,
  isError,
  getMessage,
  createTotpURI,
  verifyTotpToken,
  validateQRCodeOTPAndGetToken,
  testGen2func,
  validateOTPAndGetToken,
  generateTotpKey,
  createAndSendOTPViaEmail,
  createAndSendOTPViaSMS,
  queryAuditEvents,
  //createUser,
  createUserAndGetToken,
  sendEmailMessage,
  copyDefaultRules,
  createEmbedding,
  //createCompletion,
  createThread,
  deleteThread,
  createRun,
  retrieveRun,
  submitToolOutputs,
  createMessage,
  createMessages,
  listMessages,
  listAssistants,
  retrieveAssistant,
  createChatCompletion,
  createChatCompletionWithFunctions,
  getOpenAiModels,
  getUserAuthenticationMethods,
  // Reporting methods
  queryProjectsByAccountId,
  queryViewByAccountId,
  isEmailOrSMSOTPCorrect,
  isQROTPCorrect,
  AUSTRALIA_SOUTHEAST1,
}
