import React, { useEffect, useState, useCallback } from "react"
import PropTypes from "prop-types"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import TableSortLabel from "@mui/material/TableSortLabel"
import Typography from "@mui/material/Typography"
import Paper from "@mui/material/Paper"
import * as dataServices from "../pages/services/dataServices"
import db from "../Firestore"
import { format } from "date-fns"
import { Box, Grid, Tooltip } from "@mui/material"
import firebase from "firebase/compat/app"
import { useDispatch, useSelector } from "react-redux"
import { selectElementGridPagination } from "../redux/selectors"
import { setElementGridPagination } from "../redux/actions"
import { selectElementGridPageDocs } from "../redux/selectors"
import { setElementGridPageDocs } from "../redux/actions"
import Controls from "./controls/Controls"
import { spacing } from "../pages/services/styleServices"
import StyledLink from "./controls/StyledLink"
import PageNo from "./PageNo"
import { useHistory } from "react-router-dom"

const headCells = [
  {
    id: "name",
    numeric: false,
    disablePadding: true,
    label: "Prompt Name",
    sortable: true,
  },
  {
    id: "type",
    numeric: false,
    disablePadding: true,
    label: "Type",
    sortable: false,
  },
  {
    id: "modified",
    numeric: false,
    disablePadding: false,
    label: "Modified",
    sortable: true,
  },
]

const ElementPromptGrid = (props) => {
  const { order, orderBy, onRequestSort } = props

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property)
  }

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {headCell.sortable ? (
              <TableSortLabel
                active={headCell.sortable && orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                <Typography sx={{ fontWeight: "bold" }}>
                  {headCell.label}
                </Typography>
              </TableSortLabel>
            ) : (
              <Typography sx={{ fontWeight: "bold" }}>
                {headCell.label}
              </Typography>
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

ElementPromptGrid.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
}

const styles = {
  root: {
    width: "100%",
    marginTop: spacing(2),
  },
  paper: {
    width: "100%",
    marginBottom: spacing(2),
  },
  table: {
    minWidth: 750,
  },
  navButtons: {
    display: "flex",
    flexDirection: "row",
    gap: 1,
  },
  gridContainer: {
    paddingLeft: "5px",
    paddingRight: "5px",
  },
  filterGrid: {
    paddingLeft: "15px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  tagsCell: {
    display: "flex",
    flexDirection: "row",
    gap: 1,
    flexWrap: "wrap",
  },
}

export default function EnhancedTable() {
  // 'next', or 'prev'. Used to inform pagination logic
  const [direction, setDirection] = useState("")

  const [dense, setDense] = React.useState(true)

  const pag = useSelector(selectElementGridPagination)
  const [pagination, setPagination] = useState(pag)

  const dispatch = useDispatch()

  const [rowsPerPage, setRowsPerPage] = React.useState(10)

  const [elements, setElements] = useState([])

  const [user, setUser] = useState()

  const [accountId, setAccountId] = useState()

  const history = useHistory()

  const [tagsFilter, setTagsFilter] = useState()

  useEffect(() => {
    const unsub = firebase.auth().onAuthStateChanged((user) => {
      console.log("### user changed", user)
      setUser(user)

      if (user !== null) {
        user.getIdTokenResult(false).then((token) => {
          console.log("### setting account id", token.claims.account_id)
          setAccountId(token.claims.account_id)
        })
      }
    })

    return unsub
  }, [])

  const pgDocs = useSelector(selectElementGridPageDocs)
  const [pageDocs, setPageDocs] = useState(pgDocs)

  const COLLECTION_NAME = "elements"

  // Listen for changes

  useEffect(() => {
    console.log("loading elements")
    if (user === undefined || accountId === undefined) {
      return
    }

    const whereClauseLog = []

    let query = db
      .collection(COLLECTION_NAME)
      .where("modified", ">=", dataServices.localTimestamp())
      .orderBy("modified", "desc")

    whereClauseLog.push(
      "modified >= " +
        dataServices.localTimestamp() +
        " [" +
        dataServices.localTimestamp().toDate() +
        "]"
    )

    let unsubscribe

    firebase
      .auth()
      .currentUser.getIdTokenResult()
      .then((token) => {
        switch (token.claims.account_type) {
          case "client":
            query = query.where("account_id", "==", token.claims.account_id)
            whereClauseLog.push("account_id == " + token.claims.account_id)
            break

          default:
            throw new Error("Unknown account type " + token.claims.account_type)
        }

        unsubscribe = query.onSnapshot((querySnapshot) => {
          querySnapshot.docChanges().forEach((change) => {
            switch (change.type) {
              case "added":
                setElements((curr) => [
                  { id: change.doc.id, ...change.doc.data() },
                  ...curr.filter((prompt) => prompt.id !== change.doc.id),
                ])
                break

              case "modified":
                setElements((curr) => [
                  { id: change.doc.id, ...change.doc.data() },
                  ...curr.filter((prompt) => prompt.id !== change.doc.id),
                ])
                break

              case "removed":
                setElements((curr) => {
                  const newPrompts = [...curr]
                  const index = newPrompts.findIndex(
                    (prompt) => prompt.id === change.doc.id
                  )
                  newPrompts.splice(index, 1)
                  return newPrompts
                })
                break

              default:
                throw new Error("Unknown change type " + change.type)
            }
          })
        })
      })

    return unsubscribe // gets called on unmount
  }, [user, accountId])

  const updatePageDocs = useCallback(() => {
    if (elements.length > 0 && direction !== "prev") {
      const newPageDocs = [...pageDocs]

      const newPageDoc = {
        first: elements[0].doc,
        last: elements[elements.length - 1].doc,
      }

      newPageDocs[pagination.page] = newPageDoc
      setPageDocs(newPageDocs)
      dispatch(setElementGridPageDocs(newPageDocs))
    }
  }, [elements, pagination.page, direction])

  useEffect(() => {
    updatePageDocs(elements)
  }, [elements, updatePageDocs])

  const handleNewElement = (event) => {
    history.push("/ElementPrompt")
  }

  // Load elements

  useEffect(() => {
    if (user === undefined || accountId === undefined) {
      return
    }

    console.log("(1) load elements", { accountId, user, pagination })

    const queryMods = []

    let query = db.collection(COLLECTION_NAME)

    if (!firebase.auth().currentUser) {
      return
    }

    firebase
      .auth()
      .currentUser.getIdTokenResult()
      .then((token) => {
        switch (token.claims.account_type) {
          case "client":
            query = query.where("account_id", "==", token.claims.account_id)
            queryMods.push("where account_id == " + token.claims.account_id)
            break

          default:
            throw new Error("Unknown account type " + token.claims.account_type)
        }

        query = query.orderBy(pagination.orderBy, pagination.order)
        queryMods.push(
          "order by " + pagination.orderBy + " " + pagination.order
        )
        query = query.orderBy(
          firebase.firestore.FieldPath.documentId(),
          pagination.order
        )
        queryMods.push("order by doc id " + pagination.order)

        if (pagination.page > 0 && direction !== "prev") {
          // Use pageDocs if available, i.e. if we've gone forward, then back, then forward again through collection.
          // But if not found, it just means this is the first time we've clicked Next through the collection
          if (pageDocs[pagination.page - 1]) {
            const lastDoc = pageDocs[pagination.page - 1].last
            //console.log('startAfter', lastDoc.id)
            query = query.startAfter(lastDoc)
            queryMods.push(
              "start after last doc on previous page " + lastDoc.id
            )
          }
        } else if (direction === "prev") {
          if (!pageDocs[pagination.page]) {
            console.error("Cant find pagedocs for page", pagination.page)
          }
          query = query.startAt(pageDocs[pagination.page].first)
          queryMods.push(
            "start at 1st doc on page " + pageDocs[pagination.page].first.id
          )
        }

        console.log("queryMods", queryMods)
        console.groupEnd()

        query = query.limit(rowsPerPage)

        dataServices
          .loadData("(Load element grid)", query)
          .then((elements) => setElements(elements))
          .then(updatePageDocs())

        console.groupEnd()
      })
  }, [accountId, user, pagination])

  const handleRequestSort = (event, property) => {
    const isAsc = pagination.orderBy === property && pagination.order === "asc"

    const updatedPagination = {
      //tags: [],
      ...pagination,
      page: 0,
      order: isAsc ? "desc" : "asc",
      orderBy: property,
    }

    setPagination(updatedPagination)
    dispatch(setElementGridPagination(updatedPagination))

    setPageDocs([])
    dispatch(setElementGridPageDocs([]))

    setDirection("")
  }

  const handlePageNav = (pageChange) => {
    const newPage = pagination.page + pageChange
    if (newPage >= 0) {
      setDirection(pageChange === 1 ? "next" : "prev")

      const updatedPagination = {
        ...pagination,
        page: newPage,
      }

      setPagination(updatedPagination)
      dispatch(setElementGridPagination(updatedPagination))
    }
  }

  return (
    <>
      <Grid container direction="row" spacing={2} sx={styles.filterGrid}>
        {/* <Grid item>
                    <Controls.TextInput
                        name="tags_filter"
                        label="Filter Tags"
                        helperText="Enter a tag in the form type:label, e.g. Type:Tech Debt"
                        value={tagsFilter}
                        onChange={handleTagChange}
                        onKeyPress={handleTagKeyPress}
                    />
                </Grid> */}
        {/* <Grid item>
                    {pagination &&
                        pagination.tags.map((tag) => (
                            <Chip
                                key={`${tag.type}:${tag.label}`}
                                size="small"
                                label={<TagLabel tag={tag} />}
                                onDelete={() => handleTagDelete(tag)}
                            />
                        ))}
                </Grid> */}
      </Grid>
      <Box sx={styles.root}>
        <Paper sx={styles.paper}>
          <TableContainer>
            <Table
              sx={styles.table}
              aria-labelledby="tableTitle"
              size={dense ? "small" : "medium"}
              aria-label="Projects"
            >
              <ElementPromptGrid
                elements={elements}
                order={pagination.order}
                orderBy={pagination.orderBy}
                onRequestSort={handleRequestSort}
              />
              <TableBody>
                {elements.map((row, index) => {
                  const labelId = `enhanced-table-checkbox-${index}`

                  return (
                    <TableRow
                      hover
                      // onClick={(event) =>
                      //     handleClick(event, row.name, row.id)
                      // }
                      role="checkbox"
                      tabIndex={-1}
                      key={row.id}
                    >
                      <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        padding="none"
                      >
                        <StyledLink to={"/ElementPrompt/" + row.id}>
                          <Tooltip title={row.description}>
                            <Typography
                              noWrap={true}
                              variant="body2"
                              component={"span"}
                            >
                              {row.name}
                            </Typography>
                          </Tooltip>
                        </StyledLink>
                      </TableCell>

                      <TableCell component="th" padding="none">
                        <Typography
                          noWrap={true}
                          variant="body2"
                          component={"span"}
                        >
                          {row.type}
                        </Typography>
                      </TableCell>

                      <TableCell align="left">
                        <Typography
                          variant="body2"
                          noWrap={true}
                          component={"span"}
                        >
                          {format(row.modified.toDate(), "dd-M-yy")}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
        <Box sx={styles.navButtons}>
          <Controls.Button
            size="small"
            disabled={pagination.page === 0}
            onClick={() => handlePageNav(-1)}
            text="Prev"
          />

          <Controls.Button
            size="small"
            disabled={elements.length < rowsPerPage}
            onClick={() => handlePageNav(1)}
            text="Next"
          />

          <Controls.Button
            size="small"
            onClick={(event) => handleNewElement(event)}
            text="New Element"
            variant="contained"
            tooltip="Create a new element"
          />
        </Box>
        <PageNo pageNo={pagination.page + 1} />
      </Box>
    </>
  )
}
