import {
  createThread,
  deleteThread,
  listMessages,
  submitToolOutputs,
} from "./cloudFunctions"
import {
  getModel,
  getAIUsageParam,
  addMessages,
  runAssistant,
  retrieveRunForStatus,
  getViewSummary,
  FUNCTION_GET_QUESTIONS_COMMON,
  FUNCTION_GET_MODEL_QUESTIONS,
  FUNCTION_GET_EXTENDED_QUESTIONS,
  MAX_MODEL_QUESTIONS,
  MAX_OPEN_QUESTIONS,
  GPT_4o_MINI_LATEST,
  getObjectiveAndContextMessages,
} from "./chatGenerationServices"
import * as palette from "../../components/symbols/palette"
import { v4 as uuidv4 } from "uuid"

// Use GPT threads to create the next best views
const generateQuestions = async ({
  assistants,
  viewSet,
  views,
  setMessage,
  roles,
}) => {
  const assistant = assistants.find((a) => a.name === "AIM Goal Seeker")

  const aimGoalSeekerAssistantId = assistant.id

  console.log('%cRetrieved assistant "AIM Goal Seeker"', "color:lightgreen", {
    assistant,
  })

  const suggestViewsFunction = assistant.tools.find(
    (t) => t.type === "function" && t.function.name === "suggest_views"
  )

  const getModelQuestionsFunction = assistant.tools.find(
    (t) => t.type === "function" && t.function.name === "get_questions"
  )

  const getExtendedQuestionsFunction = assistant.tools.find(
    (t) => t.type === "function" && t.function.name === "get_questions_plain"
  )

  console.log("%cfunctions", "color:lightgreen", {
    suggestViewsFunction,
    getModelQuestionsFunction,
    getExtendedQuestionsFunction,
  })

  const gptModel = getModel({
    roles: roles,
    funcName: FUNCTION_GET_QUESTIONS_COMMON,
  })

  const gptModelGetModelQuestions = getModel({
    roles: roles,
    funcName: FUNCTION_GET_MODEL_QUESTIONS,
  })

  const gptModelGetExtendedQuestions = getModel({
    roles: roles,
    funcName: FUNCTION_GET_EXTENDED_QUESTIONS,
  })

  console.log("%cusing model", "color:orange", { model: roles })

  // number of questions of each type for paid account
  const DEFAULT_NUM_QUESTIONS = 25

  const maxModelQuestions =
    getAIUsageParam({ roles, paramName: MAX_MODEL_QUESTIONS }) ||
    DEFAULT_NUM_QUESTIONS
  const maxOpenQuestions =
    getAIUsageParam({ roles, paramName: MAX_OPEN_QUESTIONS }) ||
    DEFAULT_NUM_QUESTIONS

  // element questions are those that have a layer attribute, vs open questions which aren't tied to a specific layer
  const pinnedElementQuestions =
    viewSet.view_questions?.filter((q) => q.layer && q.pinned) || []
  const elementQuestionsToCreate =
    maxModelQuestions - pinnedElementQuestions.length

  // Questions that do not have a 'layer' attribute, and have no 'pinned' attribute, or 'pinned' is false
  const pinnedOpenQuestions =
    viewSet.view_questions?.filter((q) => q.layer === undefined && q.pinned) ||
    []

  const openQuestionsToCreate = maxOpenQuestions - pinnedOpenQuestions.length

  console.log("%cquestions to create", "color:lightGreen", {
    elementQuestionsToCreate,
    openQuestionsToCreate,
    pinnedOpenQuestions,
    questions: viewSet.view_questions,
  })

  const vectorStoreIds = assistant.tool_resources.file_search.vector_store_ids

  //console.log("vector store ids", vectorStoreIds)

  //console.log(`%cUnderstand context`, "color:pink", viewSet.overview)

  const threadResult = await createThread({
    messages: [],
  })

  //console.log("thread result", { threadResult })

  const threadId = threadResult.data?.response?.id

  let viewContext

  const initialMessages = getObjectiveAndContextMessages({ viewSet })

  setMessage(
    "Load existing context in which views will be created...(step 1 of 5)"
  )

  await addMessages({ threadId, messages: initialMessages })

  const inputContextRunResult = await runAssistant({
    threadId,
    assistantId: aimGoalSeekerAssistantId,
    usage: "input existing context",
    expectedStatus: "completed",
    modelName: GPT_4o_MINI_LATEST,
  })

  console.log("%cinput context run result", "color:orange", {
    inputContextRunResult,
  })

  viewContext = viewSet.view_context

  const step2Messages = [
    "Given the context and objective, what important questions might you want to answer about this topic? List all of the questions that you think are important to know about this topic, and considering the context. Make sure you have questions that cover all of the ArchiMate element layers, i.e. a good broad selection of questions. We will later use these questions to create views that will help answer them.",
    `Create at ${elementQuestionsToCreate} questions and make sure that questions are relevant, and not just filler questions, and that they are unique.`,
    "Make sure that the questions cover all of the aspects in the researched context.",
    "Do not just create a question per ArchiMate element -- think of the best questions to ask and then map them to the ArchiMate elements.",
    "It is typically important to at least have a few Strategy layer questions, covering Capabilities required, strategic courses of action, and Resources required to achieve the objective.",
  ]

  if (elementQuestionsToCreate > 0) {
    step2Messages.push(
      `There are also some existing questions. Do not create a questions already inthis list:`,
      ...pinnedElementQuestions.map((q, index) => `- ${q.question})`)
    )
  }

  await addMessages({ threadId, messages: step2Messages })

  setMessage("Identify questions to support the objective... (step 2 of 5)")

  const step2RunResult = await runAssistant({
    threadId,
    assistantId: aimGoalSeekerAssistantId,
    usage: "get model-based questions",
    expectedStatus: "requires_action",
    functionToUse: getModelQuestionsFunction,
    modelName: gptModelGetModelQuestions,
    tools: [{ type: "file_search" }],
  })

  console.log("step 2 run result", { step2RunResult })

  if (step2RunResult.success === false) {
    console.error(
      "Error in step 2 run result",
      step2RunResult.result.data.error
    )
    return
  }

  let layerBasedQuestions
  if (
    step2RunResult.result.data.response?.required_action.submit_tool_outputs
  ) {
    const rawJson =
      step2RunResult.result.data.response.required_action.submit_tool_outputs
        .tool_calls[0].function.arguments

    console.log("%cattempting to parse JSON", "color:pink", { rawJson })

    let questionsJson
    try {
      questionsJson = JSON.parse(rawJson)
    } catch (e) {
      console.error("Error parsing JSON", e)
      return
    }

    layerBasedQuestions = questionsJson.questions.map((q) => ({
      ...q,
      question_id: `question-${uuidv4()}`,
      // Use the layer of the first element in element_types, since this seems more accurate than the layer element returned by the model
      layer:
        q.element_types.length > 0
          ? palette.getElementType(q.element_types[0])?.layer.name
          : q.layer,
    }))

    console.log("%clayer based questions json", "color:yellow", {
      layerBasedQuestions,
    })

    const runId = step2RunResult.result.data.response.id
    const toolCallId =
      step2RunResult.result.data.response.required_action.submit_tool_outputs
        .tool_calls[0].id

    const submitToolOutputsResult = await submitToolOutputs({
      threadId,
      runId,
      toolCallId,
      toolOutput: JSON.stringify(layerBasedQuestions),
    })

    console.log("%csubmit tool outputs result: get_questions", "color:pink", {
      submitToolOutputsResult,
    })

    const submittedToolOutputRunResult = await retrieveRunForStatus({
      threadId: threadId,
      runId: runId,
      expectedStatus: "completed",
    })

    console.log("submitted tool output run result", {
      submittedToolOutputRunResult,
    })
  }

  setMessage("Analyse existing views... (step 3 of 5)")

  if (views.length > 0) {
    const viewSummary = views.map((v) => getViewSummary(v))
    const viewsFormatted = viewSummary.map(
      (v, index) => `${index + 1}. Name: ${v.name}, Element types: (${v.types})`
    )
    const step3aMessages = [
      `This is the existing set of views that exist, including their title and the prompts that were used to create them: ${viewsFormatted}`,
      `You do not need to respond to this. I am providing this background information for the next step.`,
    ]

    await addMessages({ threadId, messages: step3aMessages })

    const step3aRunResult = await runAssistant({
      threadId,
      assistantId: aimGoalSeekerAssistantId,
      usage: "input existing questions",
      additionalInstructions:
        "This is just further background information you will need for the next step.",
      gptModel: GPT_4o_MINI_LATEST,
    })
    console.log("step 3a run", { step3aRunResult })

    if (step3aRunResult.success === false) {
      console.error(
        "Error in step 3a run result",
        step3aRunResult.result.data.error
      )
      return
    }
  } else {
    console.log("No existing views")
  }

  const additionalQuestionMessages = [
    `Now generate ${
      maxOpenQuestions - pinnedOpenQuestions.length
    } additional questions. Think about how to achieve the objective and what questions you would need to answer to achieve that. These questions should be more real-world based and at a higher level of abstraction than model-based questions.`,
  ]

  if (openQuestionsToCreate > 0) {
    const allPinnedQuestions =
      pinnedElementQuestions.concat(pinnedOpenQuestions)

    additionalQuestionMessages.push(
      `There are also some existing open questions. You MUST NOT create any duplicate questions -- either with the exact same question text, or which is very close in intent to any existing question. Do not create a question if it is in this list:`,
      ...allPinnedQuestions.map((q, index) => `- ${q.question})`)
    )
  }

  setMessage("Generating additional questions... (step 4 of 5)")

  await addMessages({ threadId, messages: additionalQuestionMessages })

  const additionalQuestionsRunResult = await runAssistant({
    threadId,
    assistantId: aimGoalSeekerAssistantId,
    usage: "get extended questions",
    expectedStatus: "requires_action",
    functionToUse: getExtendedQuestionsFunction,
    modelName: gptModelGetExtendedQuestions,
    tools: [{ type: "file_search" }],
  })

  console.log("additional questions run result", {
    additionalQuestionsRunResult,
  })

  if (additionalQuestionsRunResult.success === false) {
    console.error(
      "Error in additional questions run result",
      additionalQuestionsRunResult.result.data.error
    )
    return
  }

  let additionalQuestions

  if (
    additionalQuestionsRunResult.result.data.response?.required_action
      .submit_tool_outputs
  ) {
    const rawJson =
      additionalQuestionsRunResult.result.data.response.required_action
        .submit_tool_outputs.tool_calls[0].function.arguments

    console.log("%cattempting to parse JSON", "color:pink", { rawJson })

    let questionsJson
    try {
      questionsJson = JSON.parse(rawJson)
    } catch (e) {
      console.error("Error parsing JSON", e)
      return
    }

    additionalQuestions = questionsJson.questions.map((q) => ({
      ...q,
      question_id: `question-${uuidv4()}`,
    }))
    console.log("%cadditional questions", "color:yellow", {
      additionalQuestions,
    })

    const runId = additionalQuestionsRunResult.result.data.response.id
    const toolCallId =
      additionalQuestionsRunResult.result.data.response.required_action
        .submit_tool_outputs.tool_calls[0].id

    const submitToolOutputsResult = await submitToolOutputs({
      threadId,
      runId,
      toolCallId,
      toolOutput: JSON.stringify(additionalQuestions),
    })

    console.log(
      "%csubmit tool outputs result: get_questions_plain",
      "color:pink",
      { submitToolOutputsResult }
    )

    const submittedToolOutputRunResult = await retrieveRunForStatus({
      threadId: threadId,
      runId: runId,
      expectedStatus: "completed",
    })

    console.log("submitted tool output run result", {
      submittedToolOutputRunResult,
    })

    layerBasedQuestions = layerBasedQuestions.concat(additionalQuestions)
  }

  const step3bMessages = [
    `Select the next 3 most appropriate questions to be answered from the list that you generated, and create views for each of those questions.`,
    `Rule: You MUST only use valid ArchiMate element types as per the type enum in the suggest_views function.`,
    `Use the 'suggest_views' function to respond with your answer.`,
  ]

  setMessage("Generate suggested views...(step 5 of 5)")

  await addMessages({ threadId, messages: step3bMessages })

  const step3bRunResult = await runAssistant({
    threadId,
    assistantId: aimGoalSeekerAssistantId,
    usage: "suggest views",
    // Means that JSON function object has been created
    expectedStatus: "requires_action",
    functionToUse: suggestViewsFunction,
    modelName: gptModel,
    tools: [{ type: "file_search" }],
  })

  console.log("%cstep 3b run", "color:orange", { step3bRunResult })

  if (step3bRunResult.success === false) {
    console.error(
      "Error in step 3b run result",
      step3bRunResult.result.data.error
    )
    return
  }

  let cleanedUp
  if (
    step3bRunResult.result.data.response?.required_action.submit_tool_outputs
  ) {
    const rawJson =
      step3bRunResult.result.data.response.required_action.submit_tool_outputs
        .tool_calls[0].function.arguments
    const viewJson = JSON.parse(rawJson)
    console.log("%cviews", "color:yellow", { viewJson })
    cleanedUp = cleanUpSuggestedViewsResult({
      nextBestViews: viewJson.next_best_views,
    })
    console.log("cleaned up", { cleanedUp })
  } else {
    const step3MessageResult = await listMessages({ threadId: threadId })
    console.log("%cstep 3 message result", "color:pink", {
      step3MessageResult,
    })

    cleanedUp = []
  }

  const suggestedViewsWithUUIDsAndQuestions = cleanedUp
    .map((v) => ({
      ...v,
      view_id: `view-${uuidv4()}`,
      question: layerBasedQuestions.find((q) => q.question_id === v.question_id)
        ?.question,
    }))
    .filter((item) => item.question)

  console.log("%csuggested views with UUIDs", "color:yellow", {
    suggestedViewsWithUUIDs: suggestedViewsWithUUIDsAndQuestions,
  })

  if (threadId) {
    const deleteThreadResult = await deleteThread({ threadId: threadId })
    console.log("delete thread result", { deleteThreadResult })
  }

  return {
    suggestedViews: suggestedViewsWithUUIDsAndQuestions,
    viewContext: viewContext,
    viewQuestions: layerBasedQuestions,
  }
}

const cleanUpSuggestedViewsResult = ({ nextBestViews }) => {
  const mergedViews = nextBestViews.map((v) => ({
    ...v,
    // recipe: {
    //   name: v.name,
    //   description: v.description || "",
    //   parent_types: [],
    //   levels: v.element_types,
    // },
  }))

  const filterOutInvalidArchiMateTypes = mergedViews.filter((view) => {
    // Check if invalid ArchiMate types are present

    const invalidTypes = view.element_types.filter((level) => {
      const type = level.type
      const elementType = palette.getElementType(type)
      if (elementType === undefined) {
        console.log("%cReject invalid element type:", "color:orange", type)
        return true
      }
      return false
    })

    if (invalidTypes.length > 0) {
      console.log(
        "%cfiltering out view due to invalid archimate types",
        "color:red",
        {
          view,
        }
      )
      return false
    }

    return true
  })

  const max2ElementTypesPerLevel = filterOutInvalidArchiMateTypes.map((v) => ({
    ...v,
    element_types: v.element_types.slice(0, 2),
    // recipe: {
    //   ...v.recipe,
    //   levels: v.recipe.levels.slice(0, 2),
    // },
  }))

  return max2ElementTypesPerLevel
}

export { generateQuestions }
