import React, { useEffect, useState, useMemo } from "react"
import {
  Typography,
  Box,
  InputAdornment,
  ListItemIcon,
  TextField,
  Menu,
  Tooltip,
  MenuItem,
  IconButton,
  Paper,
  Chip,
  Badge,
  ToggleButton,
  ToggleButtonGroup,
  Divider,
  Stack,
  Tabs,
  Tab,
  Button,
} from "@mui/material"
import Masonry from "@mui/lab/Masonry"
import db from "../Firestore"
import * as colors from "@mui/material/colors"
import * as dataServices from "../pages/services/dataServices"
import MoreVertIcon from "@mui/icons-material/MoreVert"
import FilterAltIcon from "@mui/icons-material/FilterAlt"
import FolderCopyIcon from "@mui/icons-material/FolderCopy"
import EditIcon from "@mui/icons-material/Edit"
import SearchIcon from "@mui/icons-material/Search"
import CloseIcon from "@mui/icons-material/Close"
import FilterListIcon from "@mui/icons-material/FilterList"
import { selectModelState } from "../redux/selectors"
import CheckIcon from "@mui/icons-material/Check"
import DoneIcon from "@mui/icons-material/Done"
import AddIcon from "@mui/icons-material/Add"
import * as Roles from "../pages/services/roleServices"
import { useDispatch, useSelector } from "react-redux"
import Tags from "./Tags"
import firebase from "firebase/compat/app"
import moment from "moment"
import _ from "lodash"
import Controls from "./controls/Controls"
import { useForm } from "./useForm"
import { parseTags } from "../pages/services/tagServices"
import * as urlServices from "../pages/services/urlServices"
import * as modelServices from "../pages/services/modelServices"
import { Link } from "react-router-dom"
import * as palette from "./symbols/palette"
import useAccountStatus from "./useAccountStatus"
import TagSummary from "./TagSummary"
import { spacing } from "../pages/services/styleServices"
import * as cloudFunctions from "../pages/services/cloudFunctions"
import * as mlServices from "../pages/services/mlServices"
import { setModelState } from "../redux/actions"
import ViewCard from "./ViewCard"
import GetAIMAIDialog from "./GetAIMAIDialog"
import { aiIcon } from "../pages/services/colorServices"
import ProgressBackdrop from "./ProgressBackdrop"
import { useSnackbar } from "notistack"

const styles = {
  groups: {
    display: "flex",
    marginTop: "10px",
    flexDirection: "row",
    flexWrap: "wrap",
    "& > *": {
      marginRight: spacing(0.5),
      marginBottom: spacing(0.5),
    },
  },
  chips: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    alignItems: "center",
    gap: spacing(0.5),
  },
  selectFilter: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    alignItems: "center",
    marginTop: spacing(1),
    gap: spacing(1),
  },
  filterGroup: {
    marginBottom: spacing(1),
    padding: spacing(1),
    width: "200px",
  },
  filteringGrouping: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    "& > *": {
      minWidth: 200,
      padding: spacing(0.25),
      margin: spacing(0.25),
    },
  },
  itemSelectArea: {
    "&:hover": {
      backgroundColor: colors.grey[200],
      cursor: "hand",
    },
    backgroundColor: colors.blue[100],
    flex: 1,
    marginLeft: spacing(0.5),
    paddingLeft: spacing(0.5),
    paddingRight: spacing(1),
    paddingTop: spacing(0.5),
    paddingBottom: spacing(0.5),
  },
  itemPanel: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "no-wrap",
  },
  itemEditButtons: {
    display: "flex",
    justifyContent: "flex-end",
    marginRight: "10px",
    marginTop: spacing(1),
  },
  itemMenu: {
    marginTop: spacing(1),
  },
  itemExpandedArea: {
    paddingLeft: spacing(1.5),
    marginLeft: spacing(0.5),
    marginBottom: spacing(0.5),
    maxWidth: "17em",
  },
  stories: {
    marginLeft: spacing(1),
    display: "flex",
    flexDirection: "column",
    marginBottom: spacing(2),
  },
  elements: {
    gap: spacing(0.5),
    marginLeft: "5px",
    display: "flex",
    flexDirection: "column",
  },
  storyLinks: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    gap: spacing(0.5),
    maxWidth: 300,
  },
  tags: {
    marginLeft: spacing(1),
    display: "flex",
    flexDirection: "column",
    marginBottom: spacing(2),
  },
  description: {
    marginLeft: spacing(1),
    display: "flex",
    flexDirection: "column",
    marginBottom: spacing(1),
    marginTop: spacing(1),
  },
  descriptionEditable: {
    marginLeft: spacing(1),
    marginRight: spacing(2),
    marginBottom: spacing(2),
    marginTop: spacing(2),
  },
  searchArea: {
    marginBottom: spacing(1),
    display: "flex",
    flexDirection: "row",
    "& > *": {
      marginRight: spacing(0.5),
    },
  },
  groupLabel: {
    margin: spacing(0.5),
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
  },
  elementSummary: {
    display: "flex",
    flexDirection: "row",
  },
  elementUsage: {
    display: "flex",
    flexDirection: "column",
    gap: spacing(0.5),
    marginTop: spacing(0.5),
    marginBottom: spacing(0.5),
  },
}

const ExplorerForm = (props) => {
  // collectionName is either 'projects' or 'components'
  // settings is the top of page filter settings, that are stored in redux
  // cache is the list of projects or components that is also stored in redux
  const {
    settings,
    collectionName,
    updateSettings,
    cacheSelector,
    cacheUpdater,
    indexSelector,
    indexUpdater,
    handleClickItem,
  } = props

  const dispatch = useDispatch()

  const [accountId, setAccountId] = useState()

  const [elementFilterAnchorEl, setElementFilterAnchorEl] = useState(null)

  const [searchFilter, setSearchFilter] = useState(settings.searchFilter)

  const [semanticSearchFilter, setSemanticSearchFilter] = useState("")

  // Results of applying search filter to the model_index cache
  const [searchFilterResult, setSearchFilterResult] = useState([])

  const cache = useSelector(cacheSelector)

  const { enqueueSnackbar } = useSnackbar()

  const indexCache = useSelector(indexSelector)

  const [aiRole, setAiRole] = useState(false)

  // Options entries for use in the Group By <Select>
  const [groupByOptions, setGroupByOptions] = useState([])

  const [menuItemInfo, setMenuItemInfo] = useState([])

  const [showBillingDialog, setShowBillingDialog] = useState(false)

  const [elementTypesHash, setElementTypesHash] = useState([])

  // element types to filter by
  const [selectedElementTypes, setSelectedElementTypes] = useState(
    settings.selectedElementTypes
  )

  const [selectedElementHeaders, setSelectedElementHeaders] = useState(
    settings.selectedElementHeaders
  )

  const modelCache = useSelector(selectModelState)

  const [tabValue, setTabValue] = useState(0)

  const [searchFilterTimeout, setSearchFilterTimeout] = useState()

  const [semanticMatches, setSemanticMatches] = useState([])

  // Options entries for the 'Value' <Select>
  const [filterByValuesOptions, setFilterByValuesOptions] = useState([])

  const [sortMethod, setSortMethod] = useState(settings.sortMethod)

  const [showProgress, setShowProgress] = useState(false)

  // Tags held by all projects/components
  const [tagValues, setTagValues] = useState()

  const { isActive } = useAccountStatus()

  // Currently selected grouping
  const [grouping, setGrouping] = useState(settings.grouping)

  const [filterIconProps, setFilterIconProps] = useState({})

  const [filter, setFilter] = useState(settings.filter)

  const { values, setValues, handleInputChange } = useForm({
    group_by_type: "",
    filter_by_type: "",
    filter_by_label: "",
  })

  const [itemsForRender, setItemsForRender] = useState()

  useEffect(() => {
    const unsub = firebase.auth().onAuthStateChanged((user) => {
      console.log("### user changed", user)

      if (user) {
        user.getIdTokenResult(false).then((token) => {
          console.log("### setting account id", token.claims.account_id)
          setAccountId(token.claims.account_id)
          setAiRole(token.claims.roles.includes(Roles.AIM_AI))
        })
      }
    })

    return unsub
  }, [])

  useEffect(() => {
    if (selectedElementTypes && selectedElementHeaders) {
      if (
        selectedElementTypes.length > 0 ||
        selectedElementHeaders.length > 0
      ) {
        setFilterIconProps({
          color: "primary",
        })
      } else {
        setFilterIconProps({})
      }
    }
  }, [selectedElementTypes, selectedElementHeaders])

  const [oldSettings, setOldSettings] = useState({})

  useEffect(() => {
    if (!_.isEqual(settings, oldSettings)) {
      console.log("%csettings changes", "color:lightgreen", settings)
      filterModelElements(settings.searchFilter)
      setOldSettings(settings)
    }
  }, [settings])

  useEffect(() => {
    if (values.filter_by_type !== "") {
      const tags = tagValues[values.filter_by_type]
      console.log("tags", tags)
      console.log(
        "tag values",
        tags.map((tag) => {
          return { id: tag.label, title: tag.label }
        })
      )
      setFilterByValuesOptions(
        tags.map((tag) => {
          return { id: tag.label, title: tag.label }
        })
      )
    }
  }, [values.filter_by_type])

  const [renderTrigger, setRenderTrigger] = useState({})

  useEffect(() => {
    if (cache && cache.items.length > 0 && grouping && filter) {
      const newRenderTrigger = { filter, grouping, searchFilterResult }
      if (!_.isEqual(renderTrigger, newRenderTrigger)) {
        prepareForRender({ cache, filter, grouping, searchFilterResult })
        setRenderTrigger(newRenderTrigger)
      }

      updateSettings({
        ...settings,
        filter: filter,
        grouping: grouping,
        searchFilter: searchFilter,
        sortMethod: sortMethod,
      })
    }
  }, [grouping, filter, cache, searchFilterResult])

  useEffect(() => {
    updateSettings({
      ...settings,
      sortMethod: sortMethod,
    })
  }, [sortMethod])

  useEffect(() => {
    console.log("%cfilter changed", "color:yellow", filter)
  }, [filter])

  useEffect(() => {
    if (accountId && cache) {
      console.log("%ccheck if load items required", "color:yellow", {
        cache,

        // The 'loadPerformed' property indicates if we've ever done a full load. The 'lastModified' attribute can
        // get set if we, say, modified 1 project, then open the Explorer -- in this case the full set of projects/components
        // are never loaded.
        load_required: !cache.hasOwnProperty("loadPerformed"),
        lastModified: cache.lastModified,
      })
      if (!cache.hasOwnProperty("loadPerformed")) {
        loadItems(accountId)
      } else {
        //console.log("%cuse items from cache", "color:yellow")
      }
    }
  }, [accountId, cache])

  const buildMenuItems = () => {
    const menuInfo = []

    const elementTypes = palette.getElementTypes()

    Object.keys(elementTypes).forEach((layerKey) => {
      const layer = palette.LAYERS.find((layer) => layer.id === layerKey)
      const name = layer.name

      menuInfo.push({
        type: "header",
        label: name,
        menuProps: { style: { backgroundColor: layer.color } },
      })

      elementTypes[layerKey].forEach((element) => {
        menuInfo.push({
          type: "item",
          label: element.name,
          value: element.name,
          menuProps: {},
        })
      })
    })

    setElementTypesHash(_.flatten(Object.values(elementTypes)))
    setMenuItemInfo(menuInfo)
  }

  useEffect(() => {
    buildMenuItems()
  }, [])

  const [oldRenderParams, setOldRenderParams] = useState({})

  const prepareForRender = ({
    cache,
    filter,
    grouping,
    searchFilterResult,
  }) => {
    const renderParams = { filter, grouping, searchFilterResult, cache }
    if (_.isEqual(oldRenderParams, renderParams)) {
      console.log("%cno render required", "color:yellow")
      return
    } else {
      console.log("%crender required", "color:yellow")
    }
    setOldRenderParams(renderParams)

    console.log("%cprepareForRender", "color:chartreuse", { cache, grouping })

    // List of parent ids that match the search filter
    const searchFilterResultParentIds = searchFilterResult.map(
      (item) => item.parent_id
    )

    // Filter cache items by search filter
    const filteredCache = cache.items.filter((item) => {
      return searchFilterResultParentIds.includes(item.id)
    })

    const filteredItems = searchFilter !== "" ? filteredCache : cache.items

    // Get all tag values, so the user can select from them.
    const allTagValues = _.groupBy(
      _.uniqBy(
        _.flatten(filteredItems.map((item) => item.tags || [])),
        "label"
      ),
      "type"
    )

    if (Object.keys(allTagValues)[values.filter_by_type] === undefined) {
      //setFilterByValuesOptions([])
      const newValues = { ...values, filter_by_label: "" }
      setValues(newValues)
    }

    setTagValues(allTagValues)

    setGroupByOptions(
      Object.keys(allTagValues)
        .sort()
        .map((key) => {
          return { id: key, title: key }
        })
    )

    const grouped = filteredItems.reduce((acc, item) => {
      const groupKey = (item.tags || [])
        .filter((tag) => grouping.includes(tag.type))
        .sort((a, b) => a.type.localeCompare(b.type))

      const groups = _.groupBy(
        (item.tags || []).filter((tag) => grouping.includes(tag.type)),
        "type"
      )

      const groupLabel = Object.keys(groups)
        .sort()
        .map(
          (groupName) =>
            `${groupName} | ${(item.tags || [])
              .filter((tag) => tag.type === groupName)
              .map((tag) => tag.label)
              .join(", ")}`
        )
        .join(", ")

      const groupedFilter = _.groupBy(filter, "type")

      const filterMatches = Object.keys(groupedFilter).every((key) =>
        (item.tags || []).find((tag) =>
          groupedFilter[key].find(
            (filterItem) => filterItem.label === tag.label
          )
        )
      )

      if (filterMatches) {
        if (!acc[groupLabel]) {
          acc[groupLabel] = { key: groupKey, groupLabel, items: [] }
        }
        // Add object to list for given key's value
        acc[groupLabel].items.push({
          ...item,
          description: item.description || "",
          tags: item.tags || [],
        })
      }

      return acc
    }, {})
    setItemsForRender(grouped)
    console.log("%citems for render", "color:chartreuse", grouped)
  }

  const getType = () => {
    return { projects: "project", components: "component" }[collectionName]
  }

  const isFiltered = () => {
    return (
      (selectedElementTypes.length > 0 ||
        selectedElementHeaders.length > 0 ||
        searchFilter.trim() !== "") &&
      elementFilterAnchorEl === null
    )
  }

  // Load items

  const loadItems = async (accountId) => {
    console.log("accountId", accountId)

    let query = db
      .collection(collectionName)
      .where("account_id", "==", accountId)

    if ("projects" === collectionName) {
      query = query.where("archived", "==", false)
    }

    const result = await dataServices.loadData("explorer", query, false)

    const cacheUpdate = {
      items: result.map((item) => {
        delete item.doc
        return item
      }),
      lastModified: Date.now(),

      // Indicate that we've done a full proper load of the cache
      loadPerformed: true,
    }

    console.log("%cUpdate cache", "color:lightgreen", {
      cacheUpdate,
    })
    dispatch(cacheUpdater(cacheUpdate))

    // Load index

    const indexQuery = db
      .collection("model_index")
      .where("type", "==", getType())
      .where("account_id", "==", accountId)

    await dataServices
      .loadData("explorer", indexQuery, true)
      .then((indexResult) => {
        console.log("%cloaded index", "color:yellow", indexResult)

        const indexCacheUpdate = {
          items: indexResult,
          lastModified: Date.now(),
        }
        dispatch(indexUpdater(indexCacheUpdate))
      })
  }

  useEffect(() => {
    if (accountId === undefined) {
      //console.log("%cno account id, returning", "color:orange")
      return
    }

    if (indexCache === undefined) {
      //console.log("%cno index cache, returning", "color:orange")
      return
    }

    let unsub

    let lastModified = indexCache?.lastModified

    console.log("%cindex cache last modified", "color:yellow", {
      lastModified,
      indexCache,
    })

    if (lastModified === undefined) {
      lastModified = moment.now()
    }

    const d = new Date(lastModified)

    const query = db
      .collection("model_index")
      .where("account_id", "==", accountId)
      .where("type", "==", getType())
      .where("modified", ">", dataServices.timestampFromDate(d))

    unsub = query.onSnapshot((querySnapshot) => {
      const updatedItems = []
      const updatedIds = []

      const removedIds = []

      console.log("%clistening for model index changes", "color:orange", {
        after: d,
      })

      let changed = false

      querySnapshot.docChanges().forEach((change) => {
        changed = true

        console.log("%cmodel index modified", "color:orange", {
          label: change.doc.data().name,
          data: change.doc.data(),
          type: change.type,
        })

        // Find model index in model index cache to either update, add, or remove.
        if (change.type === "added" || change.type === "modified") {
          const foundItem = indexCache.items.find(
            (item) => item.id === change.doc.id
          )
          console.log("%cfound model index in cache", "color:orange", {
            indexCache,
            foundItem,
          })

          // Update model index cache

          updatedItems.push({ id: change.doc.id, ...change.doc.data() })
          updatedIds.push(change.doc.id)
        } else if (change.type === "removed") {
          removedIds.push(change.doc.id)
        }
      })

      if (changed) {
        console.log("%cUpdate model index cache", "color:yellow", {
          cache_count: indexCache.items.length,
        })

        const items = [
          ...indexCache.items.filter(
            (item) =>
              !removedIds.includes(item.id) && !updatedIds.includes(item.id)
          ),
          ...updatedItems,
        ]

        const newIndexCache = {
          items: items,

          // Refresh last time we retrieved project/component updated
          lastModified: Date.now(),
        }

        // console.log("%cUpdate model index cache from listener", "color:orange", {
        //     newCache: newIndexCache,
        //     updatedIds,
        //     items,
        //     count: items.length,
        // })

        dispatch(indexUpdater(newIndexCache))

        //console.log("%ccalling prepareForRender", "color:orange", { newIndexCache })
        prepareForRender({ cache, filter, grouping, searchFilterResult })
      }
    })

    return unsub
  }, [accountId, indexCache])

  useEffect(() => {
    if (accountId === undefined) {
      //console.log("%cno account id, returning", "color:orange")
      return
    }

    if (cache === undefined) {
      //console.log("%cno cache, returning", "color:orange")
      return
    }

    let unsub

    let lastModified = cache?.lastModified

    // console.log("%ccache last modified", "color:yellow", {
    //     lastModified,
    //     cache,
    // })

    if (lastModified === undefined) {
      lastModified = moment.now()
    }

    const d = new Date(lastModified)

    const query = db
      .collection(collectionName)
      .where("account_id", "==", accountId)
      .where("modified", ">", dataServices.timestampFromDate(d))

    unsub = query.onSnapshot((querySnapshot) => {
      const updatedItems = []
      const updatedIds = []

      const removedIds = []

      //console.log("%clistening for changes", "color:orange", { after: d })

      let changed = false

      querySnapshot.docChanges().forEach((change) => {
        changed = true

        // console.log("%citem modified", "color:chartreuse", {
        //     label: change.doc.data().name,
        //     data: change.doc.data(),
        //     type: change.type,
        // })

        // Find project in cache to either update, add, or remove.
        if (change.type === "added" || change.type === "modified") {
          const foundItem = cache.items.find(
            (item) => item.id === change.doc.id
          )
          // console.log("%cfound item in cache to replace", "color:orange", {
          //     cache,
          //     old: foundItem,
          //     new: change.doc.data(),
          // })

          // Update cache

          if (change.doc.data().archived) {
            // If a project has been archived, then remove it from the cache
            removedIds.push(change.doc.id)
            // console.log(
            //     "%carchived - removing from cache",
            //     "color:orange",
            //     change.doc.id
            // )
          } else {
            updatedItems.push({ id: change.doc.id, ...change.doc.data() })
            updatedIds.push(change.doc.id)
          }
        } else if (change.type === "removed") {
          removedIds.push(change.doc.id)
        }
      })

      if (changed) {
        const items = [
          ...cache.items.filter(
            (item) =>
              !removedIds.includes(item.id) && !updatedIds.includes(item.id)
          ),
          ...updatedItems,
        ]

        // console.log("%cUpdating cache", "color:yellow", {
        //     cache_count: cache.items.length,
        //     new_items: items,
        // })
        const newCache = {
          items,

          // Refresh last time we retrieved project/component updated
          lastModified: Date.now(),
          loadPerformed: cache.loadPerformed,
        }

        // console.log("%cUpdate cache from listener", "color:orange", {
        //     newCache,
        //     updatedIds,
        //     items,
        //     count: items.length,
        // })

        dispatch(cacheUpdater(newCache))
      }
    })

    return unsub
  }, [accountId, cache])

  // Change the selected 'group by' value, and also clear the child select values
  const handleFilterByChange = (event) => {
    const newValues = {
      ...values,
      filter_by_type: event.target.value,
      filter_by_label: "",
    }
    setValues(newValues)
    setFilterByValuesOptions([])
  }

  const handleDeleteGroup = (groupToDelete) => {
    const newGrouping = grouping.filter((group) => group !== groupToDelete)
    setGrouping(newGrouping)
  }

  const handleAddGroup = () => {
    if (values.group_by_type !== "") {
      if (!grouping.includes(values.group_by_type)) {
        const newGrouping = [...grouping, values.group_by_type]
        //console.log("%cSet new grouping", "color:yellow", { newGrouping, values })
        setGrouping(newGrouping)
      }
    }
  }

  const handleAddFilter = () => {
    if (values.filter_by_label !== "") {
      // See if this filter already exists
      const foundFilter = filter.find(
        (filterItem) =>
          filterItem.type === values.filter_by_type &&
          filterItem.label === values.filter_by_label
      )

      if (foundFilter === undefined) {
        const newFilter = [
          ...filter,
          {
            type: values.filter_by_type,
            label: values.filter_by_label,
          },
        ]
        setFilter(newFilter)
      }
    }
  }

  const handleDeleteFilter = (filterItemToDelete) => {
    const newFilter = filter.filter(
      (filterItem) =>
        !(
          filterItem.type === filterItemToDelete.type &&
          filterItem.label === filterItemToDelete.label
        )
    )
    setFilter(newFilter)
    //console.log("%setting new filter", "color:yellow", newFilter)
  }

  const handleElementTypeFilterChange = (event) => {
    // console.log("%celement type filter changed", "color:yellow", {
    //     event,
    //     selectedElementTypes,
    // })

    setElementFilterAnchorEl(null)

    updateSettings({
      ...settings,
      selectedElementTypes: selectedElementTypes,
      selectedElementHeaders: selectedElementHeaders,
    })
  }

  const handleFilterChange = (event) => {
    setSearchFilter(event.target.value)
  }

  useEffect(() => {
    setSearchFilterTimeout((curr) => {
      // console.log("%cchange search filter", "color:lightgreen", {
      //     curr: curr,
      //     new: searchFilter,
      // })
      const newTimeout = setTimeout(() => {
        filterModelElements(searchFilter)
      }, 1500)

      if (curr) {
        clearTimeout(curr)
      }
      return newTimeout
    })
  }, [searchFilter])

  const handleFilterKeyPress = (event) => {
    if (event.key === "Enter") {
      filterModelElements(searchFilter)
    }
  }

  const handleSemanticFilterKeyPress = (event) => {
    if (event.key === "Enter") {
      //console.log("enter, semantic search")
      handleSemanticSearch()
    }
  }

  // Semantic search
  const handleSemanticSearch = () => {
    if (!aiRole) {
      setShowBillingDialog(true)
      return
    }

    if (semanticSearchFilter.trim() === "") {
      return
    }

    //console.log("perform semantic search")
    // Create embedding for search item
    cloudFunctions
      .createEmbedding(semanticSearchFilter)
      .then(async (embeddingResult) => {
        const embedding = embeddingResult.data.response.data[0].embedding

        // Get all index entries with a view_embeddings property
        const indexEntriesWithEmbeddings = indexCache.items.filter((item) =>
          item.hasOwnProperty("view_embeddings")
        )

        //console.log("caches", { modelCache, indexCache, cache })

        const viewEmbeddings = indexEntriesWithEmbeddings.flatMap((item) =>
          item.view_embeddings.map((viewEmbedding) => ({
            embedding: viewEmbedding.embedding,
            view: viewEmbedding.view,
            model: viewEmbedding.model,
            parent_id: item.parent_id,
            file_name: item.file_name,
            type: item.type,
            model_id: item.model_id,
          }))
        )

        if (viewEmbeddings.length === 0) {
          console.log("%cno view embeddings", "color:yellow")
          enqueueSnackbar(
            "No views have been indexed. Select 'Create Index' against your Architecture model(s)",
            { variant: "info" }
          )
          return
        }

        setShowProgress(true)

        console.log("%cview embeddings", "color:yellow", {
          indexEntriesWithEmbeddings,
          viewEmbeddings,
        })
        const matches = mlServices.findClosestMatch({
          searchItem: { embedding: embedding },
          items: viewEmbeddings,
        })

        const matchesWithKeys = matches.map((sm) => {
          const modelCacheKey = modelServices.createModelCacheKey(
            sm.file_name,
            sm.parent_id,
            sm.type
          )

          return {
            ...sm,
            key: modelCacheKey,
          }
        })

        console.log(
          "%csemantic search matches",
          "color:lightgreen",
          matches.length,
          matches
        )

        // Check if the matches are loaded into modelCache, and if not load them

        // const modelCacheKeys = matches.map((item) =>
        //     modelServices.createModelCacheKey(item.file_name, item.parent_id, item.type)
        // )

        const cacheMisses = matchesWithKeys.filter((match) => {
          return !Object.values(modelCache).find(
            (cacheEntry) =>
              cacheEntry.parent_id === match.key.parentId &&
              cacheEntry.model.file === match.key.fileName
          )
        })

        //console.log("%cmodel cache misses", "color:lightgreen", { cacheMisses })

        const filesToLoad = cacheMisses.map((item) => {
          const filePath = `accounts/${accountId}/${
            item.key.type === "project" ? "projects" : "components"
          }/${item.key.parentId}/`

          const semanticMatchItem = semanticMatches.find(
            (sm) => sm.parent_id === item.parentId && sm.type === item.type
          )
          return { filePath, key: item.key, semanticMatchItem }
        })

        console.log("%cfiles to load", "color:lightgreen", filesToLoad)

        const fileLoadings = filesToLoad.map(async (fileLoadItem) => {
          await modelServices.loadFile(
            fileLoadItem.filePath,
            fileLoadItem.key.fileName,
            (model) => {
              console.log("%cmodel loaded", "color:pink", model, fileLoadItem)

              const modelState = modelServices.createModelCacheItem(
                model,
                fileLoadItem.key.fileName,
                model.name,
                fileLoadItem.key.parentId,
                fileLoadItem.key.type
              )

              console.log("%ccreated model state", "color:orange", modelState)

              dispatch(setModelState(modelState))
            }
          )
        })

        await Promise.all(fileLoadings)

        console.log("%cmatches", "color:lightgreen", matchesWithKeys)

        setSemanticMatches(matchesWithKeys)
        setShowProgress(false)
      })
  }

  const [prevVals, setPrevVals] = useState()

  const filterModelElements = (searchFilter) => {
    const newVals = { searchFilter, selectedElementTypes }
    if (_.isEqual(prevVals, newVals)) {
      return
    }
    console.log("%cfilter model elements", "color:pink", newVals)
    setPrevVals(newVals)

    const MIN_SEARCH_FILTER = 3

    const isSearchFilterTooShort =
      searchFilter.length > 0 && searchFilter.length < MIN_SEARCH_FILTER

    if (isSearchFilterTooShort) {
      //console.log("%csearch filter too short", "color:grey", searchFilter)
      return
    }

    if (
      searchFilter.length < MIN_SEARCH_FILTER &&
      selectedElementTypes.length === 0
    ) {
      //console.log("%csearch filter too short and no element types selected", "color:grey")
      setSearchFilterResult([])
      return
    } else {
      // console.log("%csearch filter", "color:yellow", {
      //     isSearchFilterTooShort,
      //     selectedElementTypes,
      // })
    }

    //console.log("%cselectedElementTypes", "color:yellow", { selectedElementTypes, indexCache })
    const elementTypeIds = palette.getElementTypeIds(selectedElementTypes)

    const parentIds = cache.items.map((cacheItem) => cacheItem.id)

    const matches = indexCache.items
      // Filter out any results that don't belong to an identified parent id. This could be because the cache wasn't removed properly
      .filter((item) => parentIds.includes(item.parent_id))
      .map((item) => ({
        elements: item.elements
          .filter(
            (element) =>
              (elementTypeIds.length === 0 ||
                elementTypeIds.includes(element.type)) &&
              element.name.toUpperCase().includes(searchFilter.toUpperCase())
          )
          .map((element) => {
            const linkUrl = urlServices.createElementViewUrl({
              parentId: item.parent_id,
              fileName: item.file_name,
              elementId: element.id,
            })

            //console.log("%clinkUrl", "color:yellow", linkUrl)

            return {
              id: element.id,
              type: palette.getElementNameByIndex(element.type),
              name: element.name,
              file_name: item.file_name,
              symbol:
                palette.symbols[palette.getElementNameByIndex(element.type)],
              linkInfo: {
                url: linkUrl,
              },
            }
          }),
        parent_id: item.parent_id,
      }))
      .filter((item) => item.elements.length > 0)

    console.log("%cfilter matches", "color:yellow", { matches, cache })

    setSearchFilterResult(matches)
  }

  const handleSaveChanges = (newValues) => {
    const mergeValues = {
      description: newValues.description,
      tags: newValues.tags,
      modified: dataServices.localTimestamp(),
    }

    console.log("%csave changes", "color:yellow", mergeValues)

    db.collection(collectionName)
      .doc(newValues.id)
      .update(mergeValues, { merge: true })
  }

  const handleToggleElementType = (type, label) => {
    console.log("%ctoggle element type", "color:yellow", { type, label })
    if (type === "header") {
      const isHeaderSelected = selectedElementHeaders.includes(label)

      const newHeaders = isHeaderSelected
        ? selectedElementHeaders.filter((header) => header !== label)
        : [...selectedElementHeaders, label]

      if (isHeaderSelected) {
        // deselect all elements of this type
        const elementsOfType = elementTypesHash
          .filter((item) => item.layer.name === label)
          .map((item) => item.name)

        const newElements = selectedElementTypes.filter(
          (element) => element !== label && !elementsOfType.includes(element)
        )

        setSelectedElementTypes(newElements)
      } else {
        // select all elements of this type

        const newElements = _.uniq([
          ...selectedElementTypes,
          ...elementTypesHash
            .filter((item) => item.layer.name === label)
            .map((item) => item.name),
        ])

        setSelectedElementTypes(newElements)
      }

      setSelectedElementHeaders(newHeaders)
    } else if (type === "item") {
      const isItemSelected = selectedElementTypes.includes(label)

      const newItems = isItemSelected
        ? selectedElementTypes.filter((name) => name !== label)
        : [...selectedElementTypes, label]

      setSelectedElementTypes(newItems)

      // If deselecting an item, make sure it's header row is deselected

      const layer = elementTypesHash.find((item) => item.name === label).layer

      const allLayerItems = elementTypesHash
        .filter((item) => item.layer.name === layer.name)
        .map((item) => item.name)

      const isAllLayerItemsSelected = allLayerItems.every((item) =>
        newItems.includes(item)
      )

      const newHeaders = isAllLayerItemsSelected
        ? [...selectedElementHeaders, layer.name]
        : selectedElementHeaders.filter((item) => item !== layer.name)

      setSelectedElementHeaders(newHeaders)
      setSelectedElementTypes(newItems)
      console.log(newItems)
    }
  }

  // Toggle the menu open status of the element type filter
  const handleClickFilterByElement = (event) => {
    setElementFilterAnchorEl(event.currentTarget)
  }

  const a11yProps = (index) => {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    }
  }

  /**
   *
   * @param {*} groupLabel A label in the format 'key | values', where we want 'values' to be bold
   */
  const formatGroupLabel = (groupLabel) => {
    const parts = groupLabel.split(",").map((part) => part.trim())

    //console.log("%cparts", "color:yellow", { groupLabel, parts })

    const formattedParts = parts.map((part, index) => {
      const split = part.split(" | ")

      //console.log("%csplit", "color:yellow", { part, split })

      const key = split[0].trim()
      const values = split[1]?.trim() || []

      //console.log("%cformatGroupLabel", "color:orange", { part, key, values })
      return (
        <>
          {index > 0 && <Typography variant="caption">, </Typography>}
          <Typography variant="caption">{key} | </Typography>
          <Typography variant="caption" style={{ fontWeight: "bold" }}>
            {values}
          </Typography>
        </>
      )
    })

    return formattedParts
  }

  return (
    <>
      {showBillingDialog && (
        <GetAIMAIDialog
          open={showBillingDialog}
          onClose={() => setShowBillingDialog(false)}
        />
      )}

      <ProgressBackdrop open={showProgress} label="Searching..." />

      <Menu
        anchorEl={elementFilterAnchorEl}
        open={elementFilterAnchorEl !== null}
        onClose={handleElementTypeFilterChange}
      >
        {menuItemInfo &&
          menuItemInfo.map((item) => (
            <MenuItem
              key={item.label}
              value={item.label}
              onClick={() => handleToggleElementType(item.type, item.label)}
              {...item.menuProps}
            >
              <ListItemIcon>
                {(selectedElementTypes.includes(item.label) ||
                  selectedElementHeaders.includes(item.label)) && <CheckIcon />}
              </ListItemIcon>
              {item.label.match(/[A-Z][a-z]+|[0-9]+/g).join(" ")}
            </MenuItem>
          ))}
      </Menu>

      <Box>
        <Tabs
          value={tabValue}
          onChange={(e, newVal) => setTabValue(newVal)}
          aria-label="Search"
        >
          <Tab label="Element Search" {...a11yProps(0)} />
          <Tab label="Semantic Search" {...a11yProps(1)} />
        </Tabs>

        <TabPanel value={tabValue} index={0}>
          <Box sx={styles.searchArea}>
            <TextField
              fullWidth
              id="search_filter"
              label="Search for elements by name"
              variant="outlined"
              size="small"
              value={searchFilter}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              type="filter"
              onChange={handleFilterChange}
              onKeyPress={handleFilterKeyPress}
            />
            <IconButton onClick={handleClickFilterByElement} size="large">
              <Tooltip title="Filter by element type">
                <Badge
                  badgeContent={selectedElementTypes.length}
                  color="secondary"
                >
                  <FilterListIcon {...filterIconProps} />
                </Badge>
              </Tooltip>
            </IconButton>
          </Box>
        </TabPanel>

        <TabPanel value={tabValue} index={1}>
          <TextField
            fullWidth
            id="search_filter"
            label="Search for views by meaning"
            variant="outlined"
            size="small"
            value={semanticSearchFilter}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon sx={aiIcon} />
                </InputAdornment>
              ),
            }}
            type="filter"
            onChange={(e) => setSemanticSearchFilter(e.target.value)}
            onKeyPress={handleSemanticFilterKeyPress}
          />
          <Button sx={{ textTransform: "none" }} onClick={handleSemanticSearch}>Search</Button>
        </TabPanel>

        {tabValue === 0 && (
          <>
            <Stack direction="row" spacing={1}>
              <GroupPanel
                grouping={grouping}
                groupByOptions={groupByOptions}
                values={values}
                handleInputChange={handleInputChange}
                handleDeleteGroup={handleDeleteGroup}
                handleAddGroup={handleAddGroup}
              />

              <Divider orientation="vertical" flexItem />

              <FilterPanel
                filter={filter}
                values={values}
                groupByOptions={groupByOptions}
                filterByValuesOptions={filterByValuesOptions}
                handleFilterByChange={handleFilterByChange}
                handleInputChange={handleInputChange}
                handleDeleteFilter={handleDeleteFilter}
                handleAddFilter={handleAddFilter}
              />
            </Stack>

            <Box sx={styles.groups}>
              {itemsForRender &&
                Object.values(itemsForRender)
                  .sort((a, b) => a.groupLabel.localeCompare(b.groupLabel))
                  .map((groupedItems) => (
                    <Box key={groupedItems.groupLabel}>
                      <Chip
                        label={
                          groupedItems.groupLabel
                            ? formatGroupLabel(groupedItems.groupLabel)
                            : "No Group"
                        }
                        size="small"
                        variant="outlined"
                        sx={styles.groupLabel}
                        color="primary"
                      />

                      <Box
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          flexWrap: "wrap",
                        }}
                      >
                        {groupedItems.items
                          .sort((a, b) =>
                            (a?.name || "").localeCompare(b?.name)
                          )
                          .map((item) => (
                            <ItemPanel
                              key={item.id}
                              item={item}
                              handleClickItem={handleClickItem}
                              handleSaveChanges={handleSaveChanges}
                              searchFilterResult={searchFilterResult}
                              modelElementsResult={
                                (searchFilterResult &&
                                  _.flatten(
                                    searchFilterResult
                                      .filter((el) => el.parent_id === item.id)
                                      .map((item) => item.elements)
                                  )) ||
                                []
                              }
                              isFiltered={isFiltered()}
                              isActive={isActive}
                            />
                          ))}
                      </Box>
                      {/* <Box>
                                        <pre>{JSON.stringify(searchFilterResult, null, 2)}</pre>
                                    </Box> */}
                    </Box>
                  ))}
            </Box>

            <Box>
              <SummaryPanel
                searchFilterResult={searchFilterResult}
                itemsForRender={itemsForRender}
                cache={cache}
                sortMethod={sortMethod}
                setSortMethod={setSortMethod}
                filter={filter}
                grouping={grouping}
              />
            </Box>
          </>
        )}

        {tabValue === 1 && semanticMatches && (
          <>
            {/* <Stack direction="column">
                            {semanticMatches.map((sm) => (
                                <Typography key={`${sm.model.name}-${sm.view.name}`}>
                                    {sm.model.name}:{sm.view.name}
                                </Typography>
                            ))}
                        </Stack> */}
            {/* <pre>
                            {JSON.stringify(
                                semanticMatches.map((item) => {
                                    const { embedding, ...rest } = item
                                    return rest
                                }),
                                null,
                                2
                            )}
                        </pre> */}

            {semanticMatches.map((sm) => {
              const model = Object.values(modelCache).find(
                (entry) => entry.model.id === sm.model_id
              )?.model

              if (model) {
                //console.log("%cmodel", "color:yellow", model)

                const view = model.views.find((view) => view.id === sm.view.id)

                //console.log("%cview", "color:yellow", view)
                return (
                  <ViewCard
                    key={`${sm.view.id}-${sm.model.name}`}
                    view={view}
                    modelCacheKey={sm.key}
                    showDiagram={true}
                    showRules={false}
                    showLabels={true}
                    showCreateStoryButton={false}
                    constrainRuleWidth={true}
                    // tags={
                    //     values?.view_tags.find(
                    //         (item) => item.view_id === view.id
                    //     )?.tags || []
                    // }
                  />
                )
              }
            })}
          </>
        )}
      </Box>
    </>
  )
}

const FilterPanel = ({
  filter,
  values,
  groupByOptions,
  filterByValuesOptions,
  handleFilterByChange,
  handleInputChange,
  handleDeleteFilter,
  handleAddFilter,
}) => {
  return (
    <Box sx={styles.filterGroup}>
      <Box sx={{ display: "flex", flexDirection: "row" }}>
        <Typography
          variant="body2"
          sx={{ fontWeight: "bold" }}
          component={"span"}
        >
          Filter
        </Typography>

        <Box sx={{ marginLeft: "auto" }}>
          <FilterAltIcon />
        </Box>
      </Box>

      {filter &&
        filter.map((filterItem, index) => (
          <Chip
            key={`${filterItem.type}-${filterItem.label}`}
            label={`${filterItem.type}|${filterItem.label}`}
            color="primary"
            size="small"
            onDelete={() => handleDeleteFilter(filterItem)}
            variant="outlined"
          />
        ))}

      {(!filter || filter.length === 0) && (
        <Typography variant="body2" color="textSecondary" component={"span"}>
          No current filter
        </Typography>
      )}

      <Box sx={styles.selectFilter}>
        <Controls.Select
          name="filter_by_type"
          label={
            <Typography variant="body2" component={"span"}>
              Select Filter
            </Typography>
          }
          value={values.filter_by_type}
          options={groupByOptions}
          onChange={handleFilterByChange}
        />
        <Controls.Select
          name="filter_by_label"
          label={
            <Typography variant="body2" component={"span"}>
              Select Value
            </Typography>
          }
          value={values.filter_by_label}
          options={filterByValuesOptions}
          onChange={handleInputChange}
        />
        <IconButton onClick={handleAddFilter} size="small">
          <AddIcon />
        </IconButton>
      </Box>
    </Box>
  )
}

const SummaryPanel = (props) => {
  const {
    searchFilterResult,
    itemsForRender,
    cache,
    sortMethod,
    setSortMethod,
    filter,
    grouping,
  } = props

  useEffect(() => {
    console.log("SummaryPanel", {
      searchFilterResult,
      cache,
      sortMethod,
      itemsForRender,
    })
  }, [searchFilterResult, sortMethod, itemsForRender])

  const elements = useMemo(() => {
    const parentsForRender = itemsForRender
      ? _.flatten(Object.values(itemsForRender).map((item) => item.items)).map(
          (item) => item.id
        )
      : []

    const elementTypes = _.flatten(
      searchFilterResult
        .filter((item) => parentsForRender.includes(item.parent_id))
        .map((item) =>
          item.elements.map((element) => ({
            name: element.name,
            type: element.type,
            name_lower: element.name.toLowerCase(),
            symbol: element.symbol,
            link_info: element.linkInfo,
            parent_id: item.parent_id,
            parent_name: cache.items.find(
              (cacheItem) => cacheItem.id === item.parent_id
            )?.name,
          }))
        )
    )

    const elementTypesByType = elementTypes.reduce((acc, value) => {
      const typePadded = ("0" + palette.getIndex(value.type)).slice(-2)
      const key =
        sortMethod === "type"
          ? `${typePadded}:${value.name_lower}`
          : `${value.name_lower}:${typePadded}`
      if (acc[key]) {
        const existing =
          acc[key].items.find((item) => item.parent_id === value.parent_id) ===
          undefined

        //console.log("existing", { existing, acc, key, value })
        if (existing) {
          // Only add link to parent if it doesn't already exist
          acc[key].items.push(value)
        }
      } else {
        acc[key] = {
          name: value.name,
          name_lower: value.name_lower,
          type: value.type,
          items: [value],
          symbol: value.symbol,
        }
      }
      return acc
    }, {})
    //console.log("%celementTypesByType", "color:yellow", { elementTypes, elementTypesByType })
    return elementTypesByType
  }, [searchFilterResult, itemsForRender, sortMethod, filter, grouping])

  return (
    <Box>
      {elements && Object.keys(elements).length > 0 && (
        <>
          <Box sx={{ marginTop: "15px", marginBottom: "10px" }}>
            <Typography variant="body2" sx={{ fontWeight: "bold" }}>
              Summary of element usage across models
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: 1,
              paddingTop: 1,
              paddingBottom: 2,
            }}
          >
            <Typography variant="caption">Sort Order</Typography>
            <ToggleButtonGroup
              orientation="horizontal"
              value={sortMethod}
              exclusive
              onChange={(event, value) => setSortMethod(value)}
              color="primary"
            >
              <ToggleButton
                key="type"
                value="type"
                aria-label="type"
                sx={{ textTransform: "none" }}
              >
                <Typography variant="caption">Type</Typography>
              </ToggleButton>

              <ToggleButton
                key="name"
                value="name"
                aria-label="name"
                sx={{ textTransform: "none" }}
              >
                <Typography variant="caption">Name</Typography>
              </ToggleButton>
            </ToggleButtonGroup>
          </Box>
        </>
      )}
      <Masonry columns={{ xs: 1, sm: 2, md: 3, lg: 4 }} spacing={2}>
        {elements &&
          Object.keys(elements)
            .sort((a, b) => a.localeCompare(b))
            .map((key) => {
              const element = elements[key]
              return (
                <Box key={key} sx={styles.elementSummary}>
                  <Box>
                    <svg width={155} height={70} viewBox="0 0 150 70">
                      <element.symbol
                        label={element.name}
                        showLabel={true}
                        //linkInfo={element.link_info}
                        width={140}
                        height={60}
                        x={1}
                        y={1}
                      />
                    </svg>
                  </Box>
                  <Box sx={styles.elementUsage}>
                    {element.items.map((item) => (
                      <Box key={item.link_info.url}>
                        <Link
                          to={item.link_info.url}
                          style={{ textDecoration: "none" }}
                        >
                          <Typography
                            variant="caption"
                            color="textSecondary"
                            component={"span"}
                          >
                            {item.parent_name}
                          </Typography>
                        </Link>
                      </Box>
                    ))}
                  </Box>
                </Box>
              )
            })}
      </Masonry>
    </Box>
  )
}

const GroupPanel = ({
  grouping,
  groupByOptions,
  values,
  handleInputChange,
  handleDeleteGroup,
  handleAddGroup,
}) => {
  return (
    <Box sx={styles.filterGroup}>
      <Box sx={styles.chips}>
        <Typography
          variant="body2"
          sx={{ fontWeight: "bold" }}
          component={"span"}
        >
          Grouping
        </Typography>

        <Box sx={{ marginLeft: "auto" }}>
          <FolderCopyIcon />
        </Box>
      </Box>
      <Box sx={{ margin: "3px" }}>
        {grouping &&
          grouping.map((group) => (
            <Chip
              key={group}
              label={group}
              size="small"
              onDelete={() => handleDeleteGroup(group)}
              color="primary"
              variant="outlined"
            />
          ))}

        {(!grouping || grouping.length === 0) && (
          <Typography variant="body2" color="textSecondary" component={"span"}>
            No current grouping
          </Typography>
        )}
      </Box>
      <Box
        style={{
          display: "flex",
          alignItems: "center",
          marginTop: spacing(1),
        }}
      >
        <Controls.Select
          name="group_by_type"
          label={
            <Typography variant="body2" component={"span"}>
              Select Group
            </Typography>
          }
          value={values.group_by_type}
          options={groupByOptions}
          onChange={handleInputChange}
        />
        <IconButton onClick={handleAddGroup} size="small">
          <AddIcon />
        </IconButton>
      </Box>
    </Box>
  )
}

const TabPanel = (props) => {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography component={"span"}>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

const ItemPanel = (props) => {
  const {
    item,
    handleClickItem,
    handleSaveChanges,
    isFiltered,
    modelElementsResult,
    isActive,
  } = props

  const [menuId] = useState(`item-${item.id}`)

  const [expanded, setExpanded] = useState(false)

  const [anchorEl, setAnchorEl] = useState(null)

  const { values, setValues, handleInputChange } = useForm()

  const [addTag, setAddTag] = useState("")

  const defaultWidth = { maxWidth: "20em", minWidth: "20em" }

  const [panelProps, setPanelProps] = useState(defaultWidth)

  const hasElementsStyle = {
    minWidth: "20em",
  }

  useEffect(() => {
    //console.log("%cItemPanel useEffect", "color: pink", modelElementsResult)
    if (modelElementsResult && item) {
      // create css style 100% page width

      if (isFiltered && modelElementsResult.length > 0) {
        setPanelProps(hasElementsStyle)
      } else {
        setPanelProps(defaultWidth)
      }
    }
  }, [isFiltered, modelElementsResult, item])

  // Reset values when panel is expanded
  useEffect(() => {
    if (expanded) {
      setValues(item)
    }
  }, [expanded])

  useEffect(() => {
    setValues(item)
  }, [item])

  const handleMenuClose = () => {
    setAnchorEl(null)
  }

  const handleTagChange = (event) => {
    setAddTag(event.target.value)
  }

  const handleTagKeyPress = (event) => {
    if (event.key === "Enter" && !event.shiftKey) {
      console.log("tag key press", addTag)

      const enteredTags = parseTags(addTag)

      console.log("entered tags", enteredTags)

      const newTags = enteredTags.filter((newTag) => {
        const exists =
          values.tags.find(
            (tag) => tag.label === newTag.label && tag.type === newTag.type
          ) !== undefined

        return !exists
      })

      console.log("new tags", newTags)

      if (newTags.length > 0) {
        const updatedTags = [...values.tags, ...newTags]
        const newValues = {
          ...values,
          tags: updatedTags,
        }
        setValues(newValues)
      }
      setAddTag("")
    }
  }

  const handleTagDelete = (tagToDelete) => {
    console.log("%cdelete tag", "color:yellow", tagToDelete)

    const newTags = values.tags.filter(
      (tag) =>
        !(tag.type === tagToDelete.type && tag.label === tagToDelete.label)
    )
    const newValues = {
      ...values,
      tags: newTags,
    }
    setValues(newValues)
  }

  const getStoryLinks = (stories) => {
    return stories.map((story) => {
      return (
        <Link key={story.id} to={`/Story/${story.id}`}>
          <Typography
            variant="caption"
            color="textSecondary"
            component={"span"}
          >
            {story.name}
          </Typography>
        </Link>
      )
    })
  }

  const getSymbols = () => {
    if (!modelElementsResult || modelElementsResult.length === 0) {
      return []
    }

    //console.log("%cmodelElementsResult", "color:yellow", modelElementsResult)
    const symbols = _.flatten(
      modelElementsResult.map((element) => (
        <svg
          key={`${element.id}-${item.parent_id}-${element.file_name}`}
          width={155}
          height={70}
          viewBox="0 0 150 70"
        >
          <element.symbol
            label={element.name}
            showLabel={true}
            linkInfo={element.linkInfo}
            width={140}
            height={60}
            x={1}
            y={1}
          />
        </svg>
      ))
    )

    return symbols
  }

  return (
    <>
      <Menu
        id={menuId}
        anchorEl={anchorEl}
        open={anchorEl !== null}
        onClose={handleMenuClose}
        sx={styles.itemMenu}
      >
        {isActive() && (
          <MenuItem
            key="open-item"
            onClick={() => {
              setExpanded(!expanded)
              handleMenuClose()
            }}
          >
            <ListItemIcon>
              <EditIcon />
            </ListItemIcon>
            <Typography component={"span"}>Quick Edit</Typography>
          </MenuItem>
        )}
      </Menu>
      <Paper sx={{ margin: "3px" }} elevation={expanded ? 3 : 0}>
        <Box sx={styles.itemPanel} style={panelProps}>
          <Box
            sx={styles.itemSelectArea}
            onClick={() => handleClickItem(item.id)}
          >
            <Typography variant="body1" component={"span"}>
              {item.name}
            </Typography>
          </Box>

          <Box>
            <IconButton
              size="small"
              edge="end"
              aria-label="menu"
              aria-controls={menuId}
              aria-haspopup="true"
              onClick={(event) => {
                setAnchorEl(event.target)
              }}
            >
              <MoreVertIcon />
            </IconButton>
          </Box>
        </Box>
        {!expanded && (
          <Box sx={styles.description}>
            <Typography
              variant="caption"
              color="textSecondary"
              component={"span"}
            >
              Description
            </Typography>
            <Typography
              variant="caption"
              style={{ maxWidth: "24em" }}
              component={"span"}
            >
              {item.description || "No description"}
            </Typography>
          </Box>
        )}
        {expanded && (
          <Box sx={styles.descriptionEditable}>
            <Controls.TextInput
              name="description"
              value={values.description}
              multiline
              label="Description"
              onChange={handleInputChange}
            />
          </Box>
        )}

        {modelElementsResult && modelElementsResult.length > 0 && (
          <Box sx={styles.elements}>
            <Typography
              variant="caption"
              color="textSecondary"
              component={"span"}
            >
              Elements
            </Typography>
            <Box>{getSymbols()}</Box>
          </Box>
        )}

        {/* The explorer cache gets out of sync if a story is deleted in the project or component, so comment out for now */}
        {/* {item.stories && (
                    <Box sx={styles.stories}>
                        <Typography variant="caption" color="textSecondary" component={"span"}>
                            Stories
                        </Typography>
                        <Box sx={styles.storyLinks}>{getStoryLinks(item.stories)}</Box>
                    </Box>
                )} */}

        {!expanded && values && values.tags && (
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              maxWidth: 300,
              marginBottom: "10px",
            }}
          >
            <TagSummary tags={values.tags || []} isEditing={false} />
          </Box>
        )}
        {expanded && (
          <Box sx={styles.tags}>
            <Typography
              variant="caption"
              color="textSecondary"
              component={"span"}
            >
              Tags
            </Typography>
            <Tags
              tags={values?.tags || []}
              editTags={expanded}
              handleTagDelete={handleTagDelete}
              maxWidth={300}
            />
            {values?.tags.length === 0 && (
              <Typography variant="caption" component={"span"}>
                No tags defined
              </Typography>
            )}
          </Box>
        )}
        {expanded && (
          <Box sx={styles.itemExpandedArea}>
            <Box>
              <Box>
                <Controls.TextInput
                  name="add_tag"
                  value={addTag}
                  label="Add New Tag"
                  helperText={`Enter tag and press enter. Format: 'label' or 'type:label', e.g. 'Domain: Customer'`}
                  onChange={handleTagChange}
                  onKeyPress={(event) => handleTagKeyPress(event)}
                />
              </Box>
            </Box>
            <Box sx={styles.itemEditButtons}>
              <Tooltip title="Save changes">
                <IconButton
                  onClick={() => {
                    handleSaveChanges(values)
                    setExpanded(false)
                  }}
                  size="large"
                >
                  <DoneIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Cancel">
                <IconButton
                  onClick={() => {
                    setValues(item)
                    setExpanded(!expanded)
                  }}
                  size="large"
                >
                  <CloseIcon />
                </IconButton>
              </Tooltip>
            </Box>
          </Box>
        )}
      </Paper>
    </>
  )
}

export default ExplorerForm
