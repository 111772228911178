import React, { useState, useEffect, useMemo } from "react"
import { useTheme } from "@mui/material/styles"
import { NavLink, Link } from "react-router-dom"
import Typography from "@mui/material/Typography"
import Toolbar from "@mui/material/Toolbar"
import MuiAppBar from "@mui/material/AppBar"
import Menu from "@mui/material/Menu"
import MenuItem from "@mui/material/MenuItem"
import Drawer from "@mui/material/Drawer"
import Divider from "@mui/material/Divider"
import List from "@mui/material/List"
import Collapse from "@mui/material/Collapse"
import ListItemText from "@mui/material/ListItemText"
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft"
import ConfigIcon from "@mui/icons-material/Settings"
import ListItemIcon from "@mui/material/ListItemIcon"
import ChevronRightIcon from "@mui/icons-material/ChevronRight"
import CreateIcon from "@mui/icons-material/Create"
import DashboardIcon from "@mui/icons-material/Dashboard"
import TravelExploreIcon from "@mui/icons-material/TravelExplore"
import ViewModuleIcon from "@mui/icons-material/ViewModule"
import IconButton from "@mui/material/IconButton"
import AccountCircle from "@mui/icons-material/AccountCircle"
import ExpandLess from "@mui/icons-material/ExpandLess"
import ExpandMore from "@mui/icons-material/ExpandMore"
import PaymentIcon from "@mui/icons-material/Payment"
import NotesIcon from "@mui/icons-material/Notes"
import RuleIcon from "@mui/icons-material/Rule"
import MenuIcon from "@mui/icons-material/Menu"
import firebase from "firebase/compat/app"
import * as icons from "../icons"
import { pink } from "@mui/material/colors"
import Logo from "./Logo"
import { styled } from "@mui/material/styles"
import { Box, CssBaseline, ListItemButton } from "@mui/material"
import { spacing } from "../pages/services/styleServices"
import { selectMenuStatus, selectConfigMenuOpen } from "../redux/selectors"
import { setMenuStatus, setConfigMenuOpen } from "../redux/actions"
import { useDispatch, useSelector } from "react-redux"
import _ from "lodash"

const drawerWidth = 240

const styles = {
  root: {
    flexGrow: 1,
    display: "flex",
  },
  accountsIcon: {
    color: pink[600],
  },
  profileButton: {
    marginRight: spacing(1),
    paddingTop: spacing(2),
  },
  toolbarLayout: {
    justifyContent: "space-between",
  },
  menuButton: {
    marginRight: spacing(2),
  },
}

const TitleTypography = styled(Typography)(({ theme }) => ({
  flexGrow: 1,
  display: "none",
  [theme.breakpoints.up("sm")]: {
    display: "block",
  },
}))

const StyledNavLink = styled(NavLink)(({ theme }) => ({
  textDecoration: "none",
  color: theme.palette.text.primary,
}))

const NestedListItemButton = styled(ListItemButton)(({ theme }) => ({
  paddingLeft: theme.spacing(4),
}))

const StyledAnchor = styled("a")(({ theme }) => ({
  textDecoration: "none",
  color: theme.palette.text.primary,
}))

const StyledLink = styled(Link)(({ theme }) => ({
  textDecoration: "none",
  color: theme.palette.text.primary,
}))

function Header(props) {
  const [profileMenuEl, setProfileMenuEl] = useState(null)

  const theme = useTheme()

  const dispatch = useDispatch()

  // Is the menu open? - true/false
  const menuStatus = useSelector(selectMenuStatus)

  const configMenuStatus = useSelector(selectConfigMenuOpen)

  const [open, setOpen] = useState(menuStatus)

  const [configOpen, setConfigOpen] = useState(configMenuStatus)

  const [newOpen, setNewOpen] = useState(false)

  const isProfileMenuOpen = Boolean(profileMenuEl)

  const handleDrawerOpen = () => {
    setOpen(true)
    dispatch(setMenuStatus(true))
  }

  const handleDrawerClose = () => {
    setOpen(false)
    dispatch(setMenuStatus(false))
  }

  const handleToggleConfigMenuOpen = () => {
    console.log("toggle config menu", !configOpen)
    setConfigOpen(!configOpen)
    dispatch(setConfigMenuOpen(!configOpen))
  }

  const toggleNewMenuOpen = () => {
    setNewOpen(!newOpen)
  }

  const handleProfileMenuOpen = (event) => {
    setProfileMenuEl(event.currentTarget)
  }

  const handleProfileMenuClose = () => {
    setProfileMenuEl(null)
  }

  const [myAccountId, setMyAccountId] = useState("")

  const [isSystemRole, setSystemRole] = useState(false)

  const [clicked, setClicked] = useState(0)

  const getMyAccountId = () => myAccountId

  const billingURL = useMemo(() => {
    if (isSystemRole) {
      return `/Billing/${myAccountId}`
    } else {
      return `/Billing`
    }
  }, [myAccountId, isSystemRole])

  const [claims, setClaims] = useState({
    account_type: "",
  })

  useEffect(() => {
    console.log("use effect:config menu status", configMenuStatus)
  }, [configMenuStatus])

  useEffect(() => {
    const unsub = firebase.auth().onAuthStateChanged((user) => {
      if (user !== null) {
        user.getIdTokenResult(false).then((token) => {
          setMyAccountId(token.claims.account_id)

          setSystemRole(token.claims.system_role)
          console.log(
            "%cset account id",
            "color:yellow",
            token.claims.account_id
          )

          const newClaims = {
            roles: token.claims.roles,
            account_id: token.claims.account_id,
            account_type: token.claims.account_type,
            system_role: token.claims.system_role,
          }

          setClaims(newClaims)
        })
      }
    })

    return unsub
  }, [])

  const hasRole = (roleId) => {
    if (claims === undefined || claims.roles === undefined) {
      return false
    }
    return claims.roles.includes(roleId)
  }

  const isProvider = useMemo(() => {
    return (claims && claims.account_type === "provider") || false
  }, [claims])

  const isAdmin = () => hasRole("admin")

  const isProjectRole = () =>
    hasRole("project_user") || hasRole("project_admin")

  const isComponentRole = () => hasRole("comp_user") || hasRole("comp_admin")

  const isSystem = () => {
    const isSystemRole =
      claims.hasOwnProperty("system_role") && claims.system_role === true
    return isSystemRole
  }

  const configItemData = [
    {
      label: "Users",
      uri: "/Users",
      icon: <icons.UserIcon />,
      canAccess: () => isAdmin(),
    },
    {
      label: "My Account",
      uri: () => "/AccountEdit/" + getMyAccountId(),
      icon: <icons.AccountIcon />,
      canAccess: () => true,
    },
    {
      label: "Subscription",
      uri: () => billingURL,
      icon: <PaymentIcon />,
      canAccess: () => isAdmin,
    },
    {
      label: "Invites",
      uri: "/Invites",
      create_uri: "/Invites",
      create_label: "Invite",
      icon: <icons.InviteIcon />,
      canAccess: () => !isProvider && isAdmin(),
      canCreate: () => !isProvider && isAdmin(),
    },
    {
      label: "AI Story Prompts",
      uri: "/storyprompts",
      icon: <NotesIcon />,
      canAccess: () => isProjectRole() || isComponentRole(),
      canCreate: () => (isProjectRole() || isComponentRole()) && !isProvider,
    },
    {
      label: "Element Prompts",
      uri: "/elementprompts",
      icon: <NotesIcon />,
      canAccess: () => isProjectRole() || isComponentRole(),
      canCreate: () => (isProjectRole() || isComponentRole()) && !isProvider,
    },
    // {
    //   label: "AI Designer Prompts",
    //   uri: "/prompts",
    //   icon: <NotesIcon />,
    //   canAccess: () => isProjectRole(),
    //   canCreate: () => isProjectRole() && !isProvider,
    // },
    {
      label: "Rules",
      uri: "/rules",
      create_uri: "/ruleedit",
      create_label: "Rule",
      icon: <RuleIcon />,
      canAccess: () => isProjectRole(),
      canCreate: () => isProjectRole() && !isProvider,
    },
    {
      label: "Accounts",
      uri: "/Accounts",
      icon: <icons.AccountIcon sx={styles.accountsIcon} />,
      canAccess: () => isSystem(),
      canCreate: false,
    },
  ]

  const profileMenuId = "profile-menu"

  const getOpenOption = (item) => {
    return { label: item.label, uri: item.uri, icon: item.icon }
  }

  const getOpenMenuItems = (menuData, indent = false) => {
    return menuData
      .filter((item) => item.canAccess())
      .map((item, index) =>
        "DIVIDER" === item.label ? (
          <Divider key={"menuitems-" + index} />
        ) : (
          createMenuLink(getOpenOption(item), indent)
        )
      )
  }

  const drawItemData = [
    {
      label: "Dashboard",
      uri: "/Dashboard",
      icon: <DashboardIcon />,
      canAccess: () => true,
    },
    {
      label: "DIVIDER",
      canAccess: () => true,
    },
    {
      label: "AI Designer",
      uri: "/ViewSets",
      icon: <ViewModuleIcon />,
      canAccess: () => true,
      canCreate: false,
    },

    {
      label: "DIVIDER",
      canAccess: () => true,
    },
    {
      label: "Projects",
      uri: "/projects",
      create_uri: "/project",
      create_label: "Project",
      icon: <icons.ProjectIcon />,
      canAccess: () => isProjectRole(),
      canCreate: () => isProjectRole() && !isProvider,
    },
    {
      label: "Components",
      uri: "/components",
      create_uri: "/component",
      create_label: "Component",
      icon: <icons.ComponentIcon />,
      canAccess: () => isComponentRole() && !isProvider,
      canCreate: () => isComponentRole() && !isProvider,
    },
    {
      label: "DIVIDER",
      canAccess: () => true,
    },
    {
      label: "Stories",
      uri: "/stories",
      icon: <icons.StoryIcon />,
      canAccess: () => isProjectRole(),
      canCreate: () => isProjectRole() && !isProvider,
    },

    {
      label: "DIVIDER",
      canAccess: () => true,
    },
    {
      label: "Project Explorer",
      uri: () => "/ProjectExplorer",
      icon: <TravelExploreIcon />,
      canAccess: () => true,
    },
    {
      label: "Component Explorer",
      uri: () => "/ComponentExplorer",
      icon: <TravelExploreIcon />,
      canAccess: () => true,
    },

    // {
    //     label: "Features",
    //     uri: "/JobsToBeDone",
    //     icon: <EmojiObjectsIcon />,
    //     canAccess: () => true,
    //     canCreate: false,
    // },
  ]

  const createMenuLink = (menuItem, nested = false) => {
    const sx = (nested && { paddingLeft: theme.spacing(4) }) || {}

    return (
      <StyledLink to={menuItem.uri} sx={styles.link} key={menuItem.uri}>
        <ListItemButton key={menuItem.label} sx={sx}>
          {menuItem.icon ? <ListItemIcon>{menuItem.icon}</ListItemIcon> : null}
          <ListItemText primary={menuItem.label} />
        </ListItemButton>
      </StyledLink>
    )
  }

  const drawerItems = () => {
    return drawItemData
      .filter((item) => item.canAccess())
      .map((item, index) =>
        "DIVIDER" === item.label ? (
          <Divider key={"menuitems-" + index} />
        ) : (
          <StyledLink to={item.uri} key={item.uri}>
            <ListItemButton key={item.label}>
              {item.icon ? <ListItemIcon>{item.icon}</ListItemIcon> : null}
              <ListItemText primary={item.label} />
            </ListItemButton>
          </StyledLink>
        )
      )
  }

  // Options under the 'Create' menu item
  const createItems = () => {
    return drawItemData
      .filter((item) => item.create_uri)
      .filter((item) => item.canCreate())
      .map((item) => (
        <StyledLink to={item.create_uri} key={item.create_uri}>
          <NestedListItemButton key={item.create_uri + "-item"}>
            <ListItemIcon>
              {item.icon ? <ListItemIcon>{item.icon}</ListItemIcon> : null}
            </ListItemIcon>
            <ListItemText primary={item.create_label} />
          </NestedListItemButton>
        </StyledLink>
      ))
  }

  const profileMenu = (
    <Menu
      anchorEl={profileMenuEl}
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      id={profileMenuId}
      keepMounted
      open={isProfileMenuOpen}
      onClose={handleProfileMenuClose}
    >
      <MenuItem onClick={handleProfileMenuClose}>
        <StyledNavLink
          onClick={() => dispatch(setMenuStatus(false))}
          to="/Signout"
        >
          Signout
        </StyledNavLink>
      </MenuItem>
      <MenuItem onClick={handleProfileMenuClose}>
        <StyledNavLink to="/Profile">Profile</StyledNavLink>
      </MenuItem>
      <MenuItem onClick={handleProfileMenuClose}>
        <StyledNavLink to={billingURL}>Subscription</StyledNavLink>
      </MenuItem>
      <MenuItem onClick={handleProfileMenuClose}>
        <StyledAnchor
          target="_aimHome"
          href="https://www.architectureinmotion.com.au"
        >
          Help
        </StyledAnchor>
      </MenuItem>
    </Menu>
  )

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar position="fixed" open={open}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            size="large"
            sx={{ mr: 2, ...(open && { display: "none" }) }}
          >
            <MenuIcon />
          </IconButton>

          <TitleTypography variant="h5" noWrap component={"span"}>
            {props.title}
          </TitleTypography>

          <MenuItem>
            <IconButton
              sx={styles.profileButton}
              aria-label="account of current user"
              aria-controls="primary-search-account-menu"
              aria-haspopup="true"
              color="inherit"
              onClick={handleProfileMenuOpen}
              size="large"
            >
              <AccountCircle />
            </IconButton>
          </MenuItem>

          <Logo />
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <DrawerHeader>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
            }}
          >
            <IconButton onClick={handleDrawerClose} size="large">
              {theme.direction === "ltr" ? (
                <ChevronLeftIcon />
              ) : (
                <ChevronRightIcon />
              )}
            </IconButton>
          </Box>
        </DrawerHeader>

        {!isProvider && <Divider key="divider" />}

        <List dense>
          {!isProvider && (
            <ListItemButton onClick={toggleNewMenuOpen}>
              <ListItemIcon>
                <CreateIcon />
              </ListItemIcon>
              <ListItemText primary="Create" />
              {newOpen ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
          )}

          <Collapse in={newOpen} timeout="auto" unmountOnExit>
            <List dense disablePadding>
              {createItems()}
            </List>
          </Collapse>

          <Divider />

          {drawerItems()}

          <ListItemButton onClick={handleToggleConfigMenuOpen}>
            <ListItemIcon>
              <ConfigIcon />
            </ListItemIcon>
            <ListItemText primary="Config" />
            {configOpen ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>

          <Collapse in={configOpen} timeout="auto" unmountOnExit>
            <List disablePadding dense>
              {[getOpenMenuItems(configItemData, true)]}
            </List>
          </Collapse>
        </List>
      </Drawer>
      {profileMenu}
      <Main open={open}>
        <DrawerHeader />
        {props.children}
      </Main>
    </Box>
  )
}

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(2),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  })
)

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}))

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}))

export default Header
