import * as actions from "../actionTypes"

const initialState = []

export default function elementDefinitions(state = initialState, action) {
  switch (action.type) {
    case actions.SET_ELEMENT_DEFINITIONS: {
      const newState = action.payload.value

      console.log("%c[redux] Setting element definitions", "color:lightgreen", {
        action,
        newState,
      })

      return newState
    }

    default:
      return state
  }
}
