import * as actions from "../actionTypes"

// pageDocs is { first: <doc>, last: <doc> } for that page, and used by prev/next
const initialState = {
    value: {
        page: 0,
        order: "desc",
        orderBy: "modified",
        allocated: "",
    },
}

export default function elementGridPagination(state = initialState, action) {
    switch (action.type) {
        case actions.SET_ELEMENT_GRID_PAGINATION: {
            console.log("[redux] Setting element grid pagination value", action.payload.value)
            return {
                ...state,
                value: action.payload.value,
            }
        }

        default:
            return state
    }
}
